import { navigate } from 'svelte-navigator'

import BuilderIcon from '@/assets/icons/builder.webp'
import DiscoveryIcon from '@/assets/icons/discovery.webp'

const Teletype = () => import('@/components/Onboarding/Teletype.svelte')
const AlphaWarning = () => import('@/components/Onboarding/AlphaWarning.svelte')

const actions = [
  {
    id: 'discovery-app',
    name: 'Discovery',
    icon: DiscoveryIcon,
    searchBreadcrumb: 'Click to open',
    handler() {
      navigate('/discovery')

      return {
        afterClose: teletype => {
          teletype.executeAction(onboardingDoneAction)
        },
      }
    },
  },
  {
    id: 'builder',
    name: 'Builder',
    icon: BuilderIcon,
  },
]

const canvasAction = {
  id: 'canvas',
  name: 'Go to Horizon',
  icon: 'arrow_right',
  section: 'Navigation',
  handler() {
    navigate('/')

    return {
      afterClose: teletype => teletype.showSuccess('Sweet!'),
    }
  },
}

export const onboardingAction = {
  id: 'teletype-onboarding',
  name: `What is Teletype?`,
  hidden: true,
  lazyComponent: Teletype,
  inputHandler: (value: string) => {
    if (!value) return null

    return actions.filter(action =>
      action.name.toLowerCase().startsWith(value.toLowerCase())
    )
  },
}

export const onboardingDoneAction = {
  id: 'teletype-onboarding-done',
  name: `Teletype Onboarding Complete`,
  hidden: true,
  lazyComponent: Teletype,
  componentProps: {
    done: true,
  },
  inputHandler: (value: string) => {
    if (!value) return null

    const actions = [canvasAction]
    return actions.filter(action =>
      action.name.toLowerCase().includes(value.toLowerCase())
    )
  },
}

export const alphaWarningAction = {
  id: 'teletype-helper',
  name: `Space Alpha`,
  view: 'ModalSmall',
  footerText: 'Warning',
  hidden: true,
  lazyComponent: AlphaWarning,
}
