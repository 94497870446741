import type { API } from '@/api'
import { generateID } from '@/utils/id'
import log from '@/utils/log'
import type { InstanceEmbed } from '@deta/types'
import { getContext, setContext } from 'svelte'

type UpdateHander = (embed: InstanceEmbed) => void

type Embeds = Map<
  string,
  {
    isFetching: boolean
    embed: InstanceEmbed | null
    subscribers: Map<string, UpdateHander>
    timeout: ReturnType<typeof setTimeout> | null
  }
>

export class InstanceEmbeds {
  api: API
  embeds: Embeds

  constructor(api: API) {
    this.api = api
    this.embeds = new Map()
    log.debug('[Embeds Service]: initialized')
  }

  subscribe(instanceId: string, onUpdate: UpdateHander) {
    const id = generateID()

    const existing = this.embeds.get(instanceId)
    if (existing) {
      existing.subscribers.set(id, onUpdate)
    } else {
      const map = new Map<string, UpdateHander>()
      map.set(id, onUpdate)
      this.embeds.set(instanceId, {
        isFetching: false,
        embed: null,
        subscribers: map,
        timeout: null,
      })
    }

    log.debug(
      `[Embeds Service]: added subscriber "${id}" to embed "${instanceId}"`
    )

    if (existing) {
      if (existing.isFetching) {
        log.debug(
          `[Embeds Service]: embed "${instanceId}" is already fetching, skipping`
        )
      } else if (existing.embed && existing.timeout) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        const expiresAt = new Date(existing.embed.token.expires_at)
        const expiresIn = expiresAt.getTime() - Date.now()

        if (expiresIn < InstanceEmbeds.TOKEN_EXPIRATION_THRESHOLD) {
          log.debug(
            `[Embeds Service]: embed "${instanceId}" token about to expire, fetching new one`
          )
          void this.getEmbed(instanceId)
        } else {
          log.debug(
            `[Embeds Service]: embed "${instanceId}" token still valid for ${Math.round(
              expiresIn / 1000
            )} sec, reusing it`
          )
          onUpdate(existing.embed)
        }
      } else {
        log.debug(
          `[Embeds Service]: embed "${instanceId}" has no token, fetching new one`
        )
        void this.getEmbed(instanceId)
      }
    } else {
      log.debug(
        `[Embeds Service]: embed "${instanceId}" not found, fetching new one`
      )
      void this.getEmbed(instanceId)
    }

    return () => {
      const existing = this.embeds.get(instanceId)
      if (existing) {
        log.debug(
          `[Embeds Service]: removing subscriber "${id}" from embed "${instanceId}"`
        )
        existing.subscribers.delete(id)
      } else {
        log.debug(
          `[Embeds Service]: tried to remove subscriber "${id}" from embed "${instanceId}" but no embed found`
        )
      }
    }
  }

  async getEmbed(instanceId: string) {
    log.debug(`[Embeds Service]: fetching embed for "${instanceId}"`)

    const existing = this.embeds.get(instanceId)
    if (!existing || existing.subscribers.size === 0) {
      log.debug(
        `[Embeds Service]: no subscribers found for embed "${instanceId}"`
      )
      return
    }

    if (existing.isFetching) {
      log.debug(
        `[Embeds Service]: embed "${instanceId}" is already fetching, skipping`
      )
      return
    }

    this.embeds.set(instanceId, {
      ...existing,
      isFetching: true,
    })

    const res = await this.api.generateInstanceEmbed(instanceId)

    log.debug(
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access
      `[Embeds Service]: new embed for "${instanceId}" valid until ${res.token.expires_at}`
    )

    const existingFresh = this.embeds.get(instanceId)
    if (!existingFresh) {
      log.debug(
        `[Embeds Service]: embed "${instanceId}" was removed while fetching`
      )
      return
    }

    if (existingFresh.timeout) {
      log.debug(`[Embeds Service]: clearing timeout for embed "${instanceId}"`)
      clearTimeout(existingFresh.timeout)
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    const expiresIn = new Date(res.token.expires_at).getTime() - Date.now()
    const tokenRefreshDelay = Math.max(
      expiresIn - InstanceEmbeds.TOKEN_EXPIRATION_HEADSTART_MS,
      60 * 1000 // 1 min
    )

    log.debug(
      `[Embeds Service]: creating new timeout of ${Math.round(
        tokenRefreshDelay / 1000
      )} sec for ${instanceId}`
    )
    const timeout = setTimeout(() => {
      log.debug('[Embeds Service]: token expired')
      void this.getEmbed(instanceId)
    }, tokenRefreshDelay)

    this.embeds.set(instanceId, {
      ...existingFresh,
      isFetching: false,
      embed: res,
      timeout,
    })

    log.debug(
      `[Embeds Service]: notifying subscribers of embed "${instanceId}"`
    )
    existingFresh.subscribers.forEach(handler => {
      handler(res)
    })
  }

  // How many ms before the token expires should we refresh it?
  static TOKEN_EXPIRATION_HEADSTART_MS = 60 * 1000

  // If the token expires in more than this amount of ms, we don't refresh it
  static TOKEN_EXPIRATION_THRESHOLD = 60 * 1000

  static CONTEXT_KEY = 'embedService'

  static provide(api: API) {
    const embedService = new InstanceEmbeds(api)
    setContext(InstanceEmbeds.CONTEXT_KEY, embedService)
    return embedService
  }

  static use(): InstanceEmbeds {
    return getContext(InstanceEmbeds.CONTEXT_KEY)
  }
}

export const provideInstanceEmbeds = (api: API) => {
  return InstanceEmbeds.provide(api)
}
export const useInstanceEmbeds = () => {
  return InstanceEmbeds.use()
}
