import { navigate } from 'svelte-navigator'

import type { Action } from '@deta/teletype'
import type { App } from '@deta/types'

import { RouteNames } from '@/router'
import { getHumanDistanceToNow } from '@/utils/time'
import { useSearchBuildsAction } from './builds'
import { useSearchReleasesAction } from './releases'
import type { CommonActionContext } from '../utils'
import { getErrorMessage } from '@/utils/errors'
import { get } from 'svelte/store'

export const createProjectAction = (
  context: CommonActionContext,
  project: App,
  searchAction = false
) => {
  const action = {
    id: project.id,
    icon: project?.placeholder_icon_config?.css_background,
    name: `${project.name}`,
    description: project.alias,
    tag: `updated ${getHumanDistanceToNow(project.updated_at)}`,
    actionText: 'Open Project',
    handler() {
      const path = context.router.resolve(RouteNames.BuilderApp, {
        appId: project.id,
      })
      window.location.href = path || ''
    },
    actionPanel: () => {
      // const canvasItem = await context.api.getLegacyCanvasItemByItemIDAndType(
      //   project.id,
      //   'project'
      // )

      return [
        {
          icon: 'plus',
          id: 'project-option-publish-create',
          name: 'Publish Release',
          handler: () => {
            const path = context.router.resolve(
              RouteNames.BuilderAppPublishCreate,
              {
                appId: project.id,
              }
            )

            window.location.href = path || ''
          },
        },
        {
          icon: 'terminal',
          id: `search-builds`,
          name: 'Search Builds',
          section: 'Search',
          action: useSearchBuildsAction(context.api, project),
        },
        {
          icon: 'tag',
          id: `search-releases`,
          name: 'Search Releases',
          section: 'Search',
          action: useSearchReleasesAction(context.api, project),
        },
        {
          icon: 'table',
          id: `builder-develop-overview`,
          name: 'View Resources',
          section: 'Navigation',
          handler: () => {
            const path =
              context.router.resolve(RouteNames.BuilderAppDevelop, {
                appId: project.id,
              }) || ''
            window.location.href = path + '?tab=overview'
          },
        },
        ...(true //!canvasItem
          ? [
              {
                icon: 'pin',
                id: `project-options-pin`,
                name: 'Pin to Horizon',
                handler: async (_, teletype) => {
                  try {
                    const board = get(context.horizon.board)
                    let xOffset = 0
                    if (board) {
                      const state = board.state
                      const { x } = get(state).viewOffset
                      xOffset = get(x)
                    }

                    await context.horizon.createProjectCard(
                      {
                        item_id: project.id,
                        item_data: project,
                      },
                      {
                        x: xOffset + window.innerWidth / 2 - 60,
                        y: 30,
                        width: 120,
                        height: 120,
                      }
                    )

                    teletype.showSuccess('Project pinned to Horizon!')
                  } catch (e) {
                    console.error(e)
                    teletype.showError(
                      getErrorMessage(e, 'Error pinning project to horizon')
                    )
                  }
                },
              },
            ]
          : []),
        {
          icon: 'settings',
          id: `builder-develop-config`,
          name: 'View Configuration',
          section: 'Navigation',
          handler: () => {
            const path =
              context.router.resolve(RouteNames.BuilderAppDevelop, {
                appId: project.id,
              }) || ''
            window.location.href = path + '?tab=config'
          },
        },
      ]
    },
  }

  if (searchAction) {
    return {
      ...action,
      description: undefined,
      parent: 'projects',
      section: 'Builder Projects',
      nestedSearch: true,
    }
  }

  return action
}

export const useSearchProjectsAction = (
  context: CommonActionContext
): Action => {
  return {
    id: 'search-projects',
    icon: 'cube',
    name: 'Search Projects',
    breadcrumb: 'Projects',
    keywords: ['project', 'builder'],
    async loadChildActions() {
      const { data: projects } = await context.api.getApps({ per_page: 999 })
      return projects
        .sort(
          (a, b) =>
            new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
        )
        .map(project => createProjectAction(context, project))
    },
  }
}

export const useProjectsAction = (
  context: CommonActionContext,
  apps: App[]
): Action[] => {
  return apps.map(app => createProjectAction(context, app, true))
}
