import { writable } from 'svelte/store'

const showNotFound = writable(false)

export const useNotFound = () => {
  const show = () => showNotFound.set(true)
  const hide = () => showNotFound.set(false)

  return {
    show,
    hide,
    showNotFound,
  }
}
