/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import type { Action, TeletypeCore } from '@deta/teletype'

import type { API } from '@/api'
import { getErrorMessage } from '@/utils/errors'
import type { CommonActionContext } from '../utils'
import { JobStatus } from '@deta/types'

const Update = () => import('@/components/Apps/Update.svelte')

const getTextFromHTML = (text: string) => {
  const span = document.createElement('span')
  span.innerHTML = text
  return span.textContent || span.innerText
}

const handleUpdate = async (
  api: API,
  teletype: TeletypeCore,
  instanceId: string,
  releaseId: string,
  revalidate?: () => Promise<void>
) => {
  try {
    // Omitting release_id means we are updating to the latest release
    await api.createInstallationUpdateRequest(instanceId, releaseId)

    teletype.showSuccess('Update started! This might take a few seconds.')

    if (revalidate) {
      setTimeout(() => {
        void revalidate()
      }, 500)
    }
  } catch (err) {
    teletype.showError(getErrorMessage(err, 'Failed to update app'))
  }
}

export const useUpdateInstancesAction = (
  context: CommonActionContext
): Action => {
  return {
    id: 'update-instances-overview',
    icon: 'reload',
    name: 'Available App Updates',
    breadcrumb: 'App Updates',
    async loadChildActions(teletype) {
      const { data: instances } = await context.api.getAppInstances({
        per_page: 999,
      })
      const updates = instances
        .filter(
          v =>
            v.update.available &&
            ![JobStatus.PENDING, JobStatus.RUNNING].includes(
              v.installation.status
            )
        )
        .sort(
          (a, b) =>
            new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
        )

      return Promise.all(
        updates.map(async instance => {
          const release = await context.api.getDiscoveryReleaseByID(
            instance.update.latest_release_id || ''
          )

          const hasSiblings =
            updates.filter(v => v.app_id === instance.app_id).length > 1

          return {
            id: `update-${instance.id}`,
            name:
              instance.release.app_name +
              (hasSiblings ? ` (${instance.alias})` : ''),
            description: release.notes
              ? getTextFromHTML(release.notes)
              : undefined,
            tag: release.version,
            icon:
              instance.release?.icon_url ||
              instance.release?.placeholder_icon_config?.css_background,
            parent: 'updates',
            keywords: ['app', 'instance', 'update'],
            view: 'Modal',
            footerText: 'App Update',
            actionText: 'Install Update',
            handler: () =>
              handleUpdate(
                context.api,
                teletype,
                instance.id,
                release.id,
                context.canvas.revalidateCanvas
              ),
            actionPanel: [
              {
                icon: 'eye',
                id: 'update-view-details',
                name: 'View Details',
                action: {
                  id: `update-${instance.id}`,
                  name: 'Update Details',
                  view: 'Modal',
                  footerText: 'App Update',
                  lazyComponent: Update,
                  componentProps: {
                    instanceId: instance.id,
                  },
                },
              },
              {
                icon: 'external_link',
                id: 'update-view-release',
                name: 'Open Release',
                handler: () =>
                  window.open(`/discovery/r/${release.id}`, '_blank'),
              },
            ],
          }
        })
      )
    },
  }
}
