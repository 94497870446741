/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import type { Action } from '@deta/teletype'
import type { Instance } from '@deta/types'

import { RouteNames } from '@/router'
import { getHumanDistanceToNow } from '@/utils/time'
import type { CommonActionContext } from '../utils'
import { getErrorMessage } from '@/utils/errors'
import type { ISystemInstanceCard } from '@/components/Canvas/canvas.types'
import { get } from 'svelte/store'

const Settings = () => import('@/components/Apps/Settings.svelte')
const Logs = () => import('@/components/Apps/Logs.svelte')
const Data = () => import('@/components/Apps/Data.svelte')
const Update = () => import('@/components/Apps/Update.svelte')

export const useSettingsAction = (
  instanceId: string,
  tab?: string
): Action => ({
  id: `instance-settings`,
  name: 'Open Settings',
  view: 'Modal',
  icon: 'settings',
  footerText: 'App Settings',
  lazyComponent: Settings,
  componentProps: {
    instanceId: instanceId,
    tab,
  },
})

export const useLogsAction = (instanceId: string): Action => ({
  id: `instance-logs`,
  name: 'Show Logs',
  view: 'Modal',
  icon: 'terminal',
  footerText: 'App Logs',
  lazyComponent: Logs,
  componentProps: {
    instanceId: instanceId,
  },
})

export const useDataAction = (instanceId: string): Action => ({
  id: `instance-data`,
  name: 'View App Data',
  view: 'ModalLarge',
  icon: 'table',
  footerText: 'App Data',
  lazyComponent: Data,
  componentProps: {
    instanceId: instanceId,
  },
})

export const useUpdateAction = (instanceId: string): Action => ({
  id: 'update-app',
  name: 'Update App',
  view: 'Modal',
  footerText: 'App Update',
  icon: 'cube',
  lazyComponent: Update,
  componentProps: {
    instanceId: instanceId,
  },
})

export const createInstanceAction = (
  context: CommonActionContext,
  instance: Instance,
  hasSiblings = false,
  searchAction = false
) => {
  const version =
    instance.release.channel === 'development'
      ? 'dev'
      : instance.release.latest
      ? 'latest'
      : instance.release.version

  let action = {
    id: instance.id,
    icon:
      instance.release?.icon_url ||
      instance.release?.placeholder_icon_config?.css_background,
    name:
      instance.release.app_name + (hasSiblings ? ` (${instance.alias})` : ''),
    description: version,
    tag: `updated ${getHumanDistanceToNow(instance.updated_at)}`,
    actionText: version === 'dev' ? 'Open Instance' : 'Open App',
    handler() {
      window.open(instance.url, '_blank')
    },
    actionPanel: async () => {
      // const canvasItem = await context.api.getLegacyCanvasItemByItemIDAndType(
      //   instance.id,
      //   'instance'
      // )
      return [
        ...(version === 'dev'
          ? [
              {
                icon: 'cube',
                id: `builder-project`,
                name: 'Open Project',
                handler: () => {
                  const path = context.router.resolve(RouteNames.BuilderApp, {
                    appId: instance.app_id,
                  })

                  window.location.href = path || ''
                },
              },
            ]
          : []),
        {
          icon: 'table',
          id: `instance-options-data`,
          name: 'App Data',
          action: useDataAction(instance.id),
        },
        {
          icon: 'terminal',
          id: `instance-options-logs`,
          name: 'View Logs',
          action: useLogsAction(instance.id),
        },
        ...(true //!canvasItem
          ? [
              {
                icon: 'pin',
                id: `instance-options-pin`,
                name: 'Pin to Horizon',
                handler: async (_, teletype) => {
                  try {
                    const board = get(context.horizon.board)
                    let xOffset = 0
                    if (board) {
                      const state = board.state
                      const { x } = get(state).viewOffset
                      xOffset = get(x)
                    }

                    await context.horizon.createInstanceCard(
                      {
                        item_id: instance.id,
                        item_data: instance,
                      },
                      {
                        x: xOffset + window.innerWidth / 2 - 60,
                        y: 30,
                        width: 120,
                        height: 120,
                      }
                    )

                    teletype.showSuccess('App pinned to Horizon!')
                  } catch (e) {
                    teletype.showError(
                      getErrorMessage(e, 'Error pinning app to horizon')
                    )
                  }
                },
              },
            ]
          : []),
        {
          icon: 'settings',
          id: `instance-options-settings`,
          name: 'Open Settings',
          action: useSettingsAction(instance.id),
        },
      ]
    },
  }

  if (searchAction) {
    action = {
      ...action,
      parent: 'instances',
      section: 'Installed Apps',
      description:
        instance.release.channel === 'development' ? 'Builder Instance' : '',
      nestedSearch: true,
      keywords: ['app', 'instance'],
    }
  }

  return action
}

export const useSearchAppInstancesAction = (
  context: CommonActionContext
): Action => {
  return {
    id: 'search-apps',
    icon: 'grid',
    name: 'Search Installed Apps',
    breadcrumb: 'Installed Apps',
    async loadChildActions() {
      const { data: instances } = await context.api.getAppInstances({
        per_page: 999,
      })
      return instances
        .sort(
          (a, b) =>
            new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
        )
        .map(instance => createInstanceAction(context, instance))
    },
  }
}

export const useAppInstancesAction = (
  context: CommonActionContext,
  instances: Instance[]
): Action[] => {
  const actions = instances.map(instance => {
    const hasSiblings =
      instances.filter(
        v => v.app_id === instance.app_id && v.release.channel !== 'development'
      ).length > 1

    return createInstanceAction(context, instance, hasSiblings, true)
  })

  return actions
}

export const useUpdateInstancesActions = (instances: Instance[]) => {
  return instances
    .filter(instance => instance.update.available)
    .map(instance => {
      return {
        id: `update-${instance.id}`,
        name: `${instance.release.app_name} (${instance.release.version})`,
        icon:
          instance.release?.icon_url ||
          instance.release?.placeholder_icon_config?.css_background,
        parent: 'updates',
        keywords: ['app', 'instance', 'update'],
        view: 'Modal',
        footerText: 'App Update',
        actionText: 'View Update',
        lazyComponent: Update,
        componentProps: {
          instanceId: instance.id,
        },
      }
    })
}
