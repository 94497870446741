<svg viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
  <path
    fill="currentColor"
    d="M12 16.14h-.87a8.67 8.67 0 0 0-6.43 2.52l-.24.28v8.28h4.08v-4.7l.55-.62l.25-.29a11 11 0 0 1 4.71-2.86A6.59 6.59 0 0 1 12 16.14Z"
    class="clr-i-solid clr-i-solid-path-1"
  />
  <path
    fill="currentColor"
    d="M31.34 18.63a8.67 8.67 0 0 0-6.43-2.52a10.47 10.47 0 0 0-1.09.06a6.59 6.59 0 0 1-2 2.45a10.91 10.91 0 0 1 5 3l.25.28l.54.62v4.71h3.94v-8.32Z"
    class="clr-i-solid clr-i-solid-path-2"
  />
  <path
    fill="currentColor"
    d="M11.1 14.19h.31a6.45 6.45 0 0 1 3.11-6.29a4.09 4.09 0 1 0-3.42 6.33Z"
    class="clr-i-solid clr-i-solid-path-3"
  />
  <path
    fill="currentColor"
    d="M24.43 13.44a6.54 6.54 0 0 1 0 .69a4.09 4.09 0 0 0 .58.05h.19A4.09 4.09 0 1 0 21.47 8a6.53 6.53 0 0 1 2.96 5.44Z"
    class="clr-i-solid clr-i-solid-path-4"
  />
  <circle
    cx="17.87"
    cy="13.45"
    r="4.47"
    fill="currentColor"
    class="clr-i-solid clr-i-solid-path-5"
  />
  <path
    fill="currentColor"
    d="M18.11 20.3A9.69 9.69 0 0 0 11 23l-.25.28v6.33a1.57 1.57 0 0 0 1.6 1.54h11.49a1.57 1.57 0 0 0 1.6-1.54V23.3l-.24-.3a9.58 9.58 0 0 0-7.09-2.7Z"
    class="clr-i-solid clr-i-solid-path-6"
  />
  <path fill="none" d="M0 0h36v36H0z" />
</svg>
