import type { API } from '@/api'

const Settings = () => import('@/components/Settings.svelte')
const DevModeSettings = () => import('@/components/DevModeSettings.svelte')

export const settingsAction = {
  id: 'settings',
  name: 'Settings',
  keywords: ['access tokens', 'account'],
  icon: 'settings',
  view: 'Modal',
  footerText: 'Settings',
  lazyComponent: Settings,
}

export const devModeSettingsAction = {
  id: 'dev-mode-settings',
  name: 'Developer Mode',
  icon: 'settings',
  view: 'Modal',
  footerText: 'Developer Mode',
  lazyComponent: DevModeSettings,
}

export const useLogoutAction = (api: API) => ({
  id: 'logout',
  name: 'Logout',
  icon: 'logout',
  view: 'ModalSmall',
  actionText: 'Confirm',
  childActions: [
    {
      id: 'logout-confirm',
      name: 'Confirm',
      handler: async () => {
        await api.logout()

        return {
          afterClose(teletype: TeletypeSystem) {
            teletype.showSuccess('Logged out!')
          },
        }
      },
    },
    {
      id: 'logout-cancel',
      name: 'Cancel',
      handler: () => {
        return {
          afterClose(teletype: TeletypeSystem) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            teletype.showError('Cancelled!')
          },
        }
      },
    },
  ],
})
