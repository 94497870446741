import Home from './Home.svelte'
import Docs from './Docs.svelte'
import Terminal from './Terminal.svelte'
import Close from './Close.svelte'
import Check from './Check.svelte'
import DocSearch from './DocSearch.svelte'
import Support from './Support.svelte'
import Grid from './Grid.svelte'
import DotsH from './DotsH.svelte'
import EyeOff from './EyeOff.svelte'
import Eye from './Eye.svelte'
import Plus from './Plus.svelte'
import Settings from './Settings.svelte'
import Logout from './Logout.svelte'
import Puzzle from './Puzzle.svelte'
import Cube from './Cube.svelte'
import Tag from './Tag.svelte'
import Info from './Info.svelte'
import Sun from './Sun.svelte'
import Moon from './Moon.svelte'
import Half from './Half.svelte'
import Desktop from './Desktop.svelte'
import Deta from './Deta.svelte'
import DetaDev from './DetaDev.svelte'
import ColorSwatch from './ColorSwatch.svelte'
import ShieldCheck from './ShieldCheck.svelte'
import ShieldWarn from './ShieldWarn.svelte'
import ExternalLink from './ExternalLink.svelte'
import Pin from './Pin.svelte'
import Trash from './Trash.svelte'
import ArrowDiagonal from './ArrowDiagonal.svelte'
import Clipboard from './Clipboard.svelte'
import ArrowRight from './ArrowRight.svelte'
import Canvas from './Canvas.svelte'
import Search from './Search.svelte'
import Reload from './Reload.svelte'
import Key from './Key.svelte'
import Discord from './Discord.svelte'
import Beaker from './Beaker.svelte'
import Table from './Table.svelte'
import Flame from './Flame.svelte'
import Trophy from './Trophy.svelte'
import Clock from './Clock.svelte'
import Pinned from './Pinned.svelte'
import ChevronRight from './ChevronRight.svelte'
import Database from './Database.svelte'
import Discovery from './Discovery.svelte'
import DatabaseImport from './DatabaseImport.svelte'
import Article from './Article.svelte'
import Sparkles from './Sparkles.svelte'
import UserPlus from './UserPlus.svelte'
import Bolt from './Bolt.svelte'
import Undo from './Undo.svelte'
import FileDownload from './FileDownload.svelte'
import History from './History.svelte'
import Forms from './Forms.svelte'
import UserCircle from './UserCircle.svelte'
import Activity from './Activity.svelte'
import Calendar from './Calendar.svelte'
import AlertCircle from './AlertCircle.svelte'
import ShieldCheckFilled from './ShieldCheckFilled.svelte'
import AlertCircleFilled from './AlertCircleFilled.svelte'
import Flag from './Flag.svelte'
import PlusCircle from './PlusCircle.svelte'
import Download from './Download.svelte'
import SourceCode from './SourceCode.svelte'
import ArrowUpRight from './ArrowUpRight.svelte'
import UserCircleFilled from './UserCircleFilled.svelte'
import CalendarFilled from './CalendarFilled.svelte'
import Users from './Users.svelte'
import Link from './Link.svelte'
import YouTube from './YouTube.svelte'
import Webcam from './Webcam.svelte'
import ArrowsDiagonalMinimize from './ArrowsDiagonalMinimize.svelte'
import Pencil from './Pencil.svelte'
import ImageUp from './ImageUp.svelte'
import Copy from './Copy.svelte'
import World from './World.svelte'
import Selector from './Selector.svelte'
import GridPlus from './GridPlus.svelte'
import AppWindow from './AppWindow.svelte'
import CPU from './CPU.svelte'
import Lock from './Lock.svelte'

export enum Icons {
  HOME = 'home',
  DOCS = 'docs',
  TERMINAL = 'terminal',
  CLOSE = 'close',
  CHECK = 'check',
  DOC_SEARCH = 'doc_search',
  SUPPORT = 'support',
  GRID = 'grid',
  DOTS_H = 'dots_h',
  EYE_OFF = 'eye_off',
  EYE = 'eye',
  PLUS = 'plus',
  SETTINGS = 'settings',
  LOGOUT = 'logout',
  PUZZLE = 'puzzle',
  CUBE = 'cube',
  TAG = 'tag',
  INFO = 'info',
  SUN = 'sun',
  MOON = 'moon',
  HALF = 'half',
  DESKTOP = 'desktop',
  DETA = 'deta',
  DETADEV = 'detadev',
  COLOR_SWATCH = 'color_swatch',
  SHIELD_CHECK = 'shield_check',
  SHIELD_WARN = 'shield_warn',
  EXTERNAL_LINK = 'external_link',
  PIN = 'pin',
  TRASH = 'trash',
  ARROW_DIAGONAL = 'arrow_diagonal',
  CLIPBOARD = 'clipboard',
  ARROW_RIGHT = 'arrow_right',
  CANVAS = 'canvas',
  SEARCH = 'search',
  RELOAD = 'reload',
  KEY = 'key',
  DISCORD = 'discord',
  BEAKER = 'beaker',
  TABLE = 'table',
  FLAME = 'flame',
  TROPHY = 'trophy',
  CLOCK = 'clock',
  PINNED = 'pinned',
  CHEVRON_RIGHT = 'chevron_right',
  DATABASE = 'database',
  DISCOVERY = 'discovery',
  DATABASE_IMPORT = 'database_import',
  ARTICLE = 'article',
  SPARKLES = 'sparkles',
  USER_PLUS = 'user_plus',
  BOLT = 'bolt',
  UNDO = 'undo',
  FILE_DOWNLOAD = 'file_download',
  HISTORY = 'history',
  FORMS = 'forms',
  USER_CIRCLE = 'user_circle',
  ACTIVITY = 'activity',
  CALENDAR = 'calendar',
  ALERT_CIRCLE = 'alert_circle',
  ALERT_CIRCLE_FILLED = 'alert_circle_filled',
  SHIELD_CHECK_FILLED = 'shield_check_filled',
  FLAG = 'flag',
  PLUS_CIRCLE = 'plus_circle',
  DOWNLOAD = 'download',
  SOURCE_CODE = 'source_code',
  ARROW_UP_RIGHT = 'arrow_up_right',
  USER_CIRCLE_FILLED = 'user_circle_filled',
  CALENDAR_FILLED = 'calendar_filled',
  USERS = 'users',
  LINK = 'link',
  YOUTUBE = 'youtube',
  WEBCAM = 'webcam',
  ARROWS_DIAGONAL_MINIMIZE = 'arrows_diagonal_minimize',
  PENCIL = 'pencil',
  IMAGE_UP = 'image_up',
  COPY = 'copy',
  WORLD = 'world',
  SELECTOR = 'selector',
  GRID_PLUS = 'grid_plus',
  APP_WINDOW = 'app_window',
  CPU = 'cpu',
  LOCK = 'lock',
}

export const components = {
  [Icons.HOME]: Home,
  [Icons.DOCS]: Docs,
  [Icons.TERMINAL]: Terminal,
  [Icons.CLOSE]: Close,
  [Icons.CHECK]: Check,
  [Icons.DOC_SEARCH]: DocSearch,
  [Icons.SUPPORT]: Support,
  [Icons.GRID]: Grid,
  [Icons.DOTS_H]: DotsH,
  [Icons.EYE_OFF]: EyeOff,
  [Icons.EYE]: Eye,
  [Icons.PLUS]: Plus,
  [Icons.SETTINGS]: Settings,
  [Icons.LOGOUT]: Logout,
  [Icons.PUZZLE]: Puzzle,
  [Icons.CUBE]: Cube,
  [Icons.TAG]: Tag,
  [Icons.INFO]: Info,
  [Icons.SUN]: Sun,
  [Icons.MOON]: Moon,
  [Icons.HALF]: Half,
  [Icons.DESKTOP]: Desktop,
  [Icons.DETA]: Deta,
  [Icons.DETADEV]: DetaDev,
  [Icons.COLOR_SWATCH]: ColorSwatch,
  [Icons.SHIELD_CHECK]: ShieldCheck,
  [Icons.SHIELD_WARN]: ShieldWarn,
  [Icons.EXTERNAL_LINK]: ExternalLink,
  [Icons.PIN]: Pin,
  [Icons.TRASH]: Trash,
  [Icons.ARROW_DIAGONAL]: ArrowDiagonal,
  [Icons.CLIPBOARD]: Clipboard,
  [Icons.ARROW_RIGHT]: ArrowRight,
  [Icons.CANVAS]: Canvas,
  [Icons.SEARCH]: Search,
  [Icons.RELOAD]: Reload,
  [Icons.KEY]: Key,
  [Icons.DISCORD]: Discord,
  [Icons.BEAKER]: Beaker,
  [Icons.TABLE]: Table,
  [Icons.FLAME]: Flame,
  [Icons.TROPHY]: Trophy,
  [Icons.CLOCK]: Clock,
  [Icons.PINNED]: Pinned,
  [Icons.CHEVRON_RIGHT]: ChevronRight,
  [Icons.DATABASE]: Database,
  [Icons.DISCOVERY]: Discovery,
  [Icons.DATABASE_IMPORT]: DatabaseImport,
  [Icons.ARTICLE]: Article,
  [Icons.SPARKLES]: Sparkles,
  [Icons.USER_PLUS]: UserPlus,
  [Icons.BOLT]: Bolt,
  [Icons.UNDO]: Undo,
  [Icons.FILE_DOWNLOAD]: FileDownload,
  [Icons.HISTORY]: History,
  [Icons.FORMS]: Forms,
  [Icons.USER_CIRCLE]: UserCircle,
  [Icons.ACTIVITY]: Activity,
  [Icons.CALENDAR]: Calendar,
  [Icons.ALERT_CIRCLE]: AlertCircle,
  [Icons.ALERT_CIRCLE_FILLED]: AlertCircleFilled,
  [Icons.SHIELD_CHECK_FILLED]: ShieldCheckFilled,
  [Icons.FLAG]: Flag,
  [Icons.PLUS_CIRCLE]: PlusCircle,
  [Icons.DOWNLOAD]: Download,
  [Icons.SOURCE_CODE]: SourceCode,
  [Icons.ARROW_UP_RIGHT]: ArrowUpRight,
  [Icons.USER_CIRCLE_FILLED]: UserCircleFilled,
  [Icons.CALENDAR_FILLED]: CalendarFilled,
  [Icons.USERS]: Users,
  [Icons.LINK]: Link,
  [Icons.YOUTUBE]: YouTube,
  [Icons.WEBCAM]: Webcam,
  [Icons.ARROWS_DIAGONAL_MINIMIZE]: ArrowsDiagonalMinimize,
  [Icons.PENCIL]: Pencil,
  [Icons.IMAGE_UP]: ImageUp,
  [Icons.COPY]: Copy,
  [Icons.WORLD]: World,
  [Icons.SELECTOR]: Selector,
  [Icons.GRID_PLUS]: GridPlus,
  [Icons.APP_WINDOW]: AppWindow,
  [Icons.CPU]: CPU,
  [Icons.LOCK]: Lock,
}

export type IconType = keyof typeof Icons

export { default as Icon } from './Icon.svelte'
