/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
const isDev = import.meta.env.DEV

export const log = (...data: any[]) => {
  console.log(...data)
}

export const info = (...data: any[]) => {
  if (isDev) {
    console.log(...data)
  }
}

export const debug = (...data: any[]) => {
  if (isDev) {
    console.log(...data)
  }
}

export const error = (...data: any[]) => {
  if (isDev) {
    console.error(...data)
  }
}

export const warn = (...data: any[]) => {
  if (isDev) {
    console.log(...data)
  }
}

export const json = (data: any) => {
  if (isDev) {
    console.log(JSON.stringify(data))
  }
}

export default {
  log,
  info,
  debug,
  error,
  warn,
  json,
}
