import { getContext, setContext, tick } from 'svelte'
import { get, writable } from 'svelte/store'
import type { Writable } from 'svelte/store'

import type { App, Instance } from '@deta/types'
import type { IBoard } from '@deta/tela'

import HorizonDefaultIcon1 from '@/assets/horizon_icons/default_1.png'
import HorizonDefaultIcon2 from '@/assets/horizon_icons/default_2.png'
import HorizonDefaultIcon3 from '@/assets/horizon_icons/default_3.png'
import HorizonDefaultIcon4 from '@/assets/horizon_icons/default_4.png'

import type { API } from '@/api'
import type {
  IHorizon,
  ICardCustomCode,
  ICardEmbedYouTube,
  ICardImage,
  ICardText,
  ICardThemeEditor,
  ICardWebLink,
  ISystemCard,
  TCard,
  CardOptions,
  CardPosition,
  ICardEmbed,
} from '@/components/Canvas/canvas.types'
import { useStatusIndicator } from '@/store/statusIndicator'
import { HORIZON_ONLY } from '@/utils/env'
import { useEvent } from '@/utils/events'
import { checkIfSpaceApp, getInstanceAlias } from '@/utils/url'
import { activeCard } from '@/store/activeCard'

export class Horizon {
  id: Writable<string>
  name: Writable<string>
  icon: Writable<string>
  theme_css: Writable<string>
  isDefault: Writable<boolean>
  isPublic: Writable<boolean>
  publishingSettings: Writable<IHorizon['publishing']>

  readOnly: boolean
  collection_id: string
  collection_key: string

  isLoading: Writable<boolean>
  isLoadingHorizons: Writable<boolean>
  cards: Writable<Writable<TCard>[]>
  isMobile: Writable<boolean>
  stackingOrder: Writable<string[]>
  board: Writable<IBoard<any, any>>
  statusIndicator: ReturnType<typeof useStatusIndicator>
  horizons: Writable<IHorizon[]>

  maxHoistedCards = 2
  hoistedCards: Writable<string[]> = writable([])

  api: API
  cache: Map<string, Promise<IHorizon>>

  constructor(
    id: string,
    api: API,
    board?: IBoard<any, any>,
    initialLoad = false
  ) {
    this.id = writable(id)
    this.name = writable('')
    this.icon = writable('')
    this.theme_css = writable('')
    this.isDefault = writable(false)
    this.isPublic = writable(false)
    this.publishingSettings = writable(null)
    this.readOnly = HORIZON_ONLY
    this.collection_id = ''
    this.collection_key = ''

    this.isLoading = writable(false)
    this.isLoadingHorizons = writable(false)
    this.board = writable(board)
    this.cards = writable([])
    this.isMobile = writable(false)
    this.stackingOrder = board ? get(board.state).stackingOrder : writable([])
    this.statusIndicator = useStatusIndicator()
    this.horizons = writable([])

    this.api = api
    this.cache = new Map()

    if (initialLoad) {
      void this.loadHorizon()
    }
  }

  getID() {
    return get(this.id)
  }

  async loadHorizons() {
    try {
      this.isLoadingHorizons.set(true)
      const horizons = await this.api.getHorizons()
      this.horizons.set(horizons)

      return horizons
    } catch (e) {
      console.error(e)
    } finally {
      this.isLoadingHorizons.set(false)
    }
  }

  async loadHorizon(slug?: string) {
    try {
      const isMobile = get(this.isMobile)

      const viewportWidth = isMobile ? 7 * 1920 : 2048
      const gridSorting = isMobile

      const idOrSlug = slug || this.getID()

      this.isLoading.set(true)
      let req = this.cache.get(viewportWidth.toString())
      if (!req) {
        req = this.api.getHorizon(idOrSlug, true, viewportWidth, gridSorting, 0)
        this.cache.set(viewportWidth.toString(), req)
      }

      const _canvas = await req
      this.id.set(_canvas.id)
      this.name.set(_canvas.name)
      this.icon.set(_canvas.icon)
      this.theme_css.set(_canvas.theme_css)
      this.isDefault.set(_canvas.default)
      this.isPublic.set(_canvas.public)
      this.publishingSettings.set(_canvas.publishing ?? null)
      this.collection_id = _canvas.collection_id
      this.collection_key = _canvas.collection_key

      this.cache.delete(viewportWidth.toString())

      if (_canvas.cards) {
        const filteredCards = (_canvas.cards ?? []).filter(card => {
          if (card.type === 'system' && card.data.type === 'instance') {
            return card.data.item_data !== null
          }
          return true
        })

        this.cards.set(filteredCards.map(e => writable(e)))
        const sortedCards = gridSorting
          ? filteredCards
          : filteredCards.sort((a, b) => a.stacking_order - b.stacking_order)
        this.stackingOrder.set(sortedCards.map(e => e.id))
      }

      const board = get(this.board)
      if (!this.readOnly && board) {
        // void board.panTo(_canvas.view_offset_x, _canvas.view_offset_y)
        const state = get(board.state)
        state.viewOffset.set({ x: _canvas.view_offset_x, y: 0 })
      }
    } catch (err) {
      console.error(err)
      throw err
    } finally {
      this.isLoading.set(false)
    }
  }

  /*
    The horizon PATCH endpoint is used to store the last view offset for future visits
    and to fetch the next cards in the viewport at the same time.

    For public horizons, we just fetch the next cards in the viewport.
  */
  async loadViewport(
    viewX?: number,
    replace = false,
    stoppedScrolling = false
  ) {
    const viewOffsetX = viewX ?? get(get(get(this.board).state).viewOffset).x

    let abort
    let data: TCard[] | null

    if (this.readOnly) {
      const horizon = await this.api.getHorizon(
        this.getID(),
        true,
        window.innerWidth,
        false,
        viewOffsetX
      )

      abort = () => {
        /* noop */
      }
      data = horizon.cards ?? null
    } else {
      const res = await this.api.updateHorizon(
        this.getID(),
        {
          view_offset_x: viewOffsetX,
        },
        true,
        window.innerWidth,
        stoppedScrolling
      )

      abort = res.abort
      data = res.data.cards ?? null
    }

    const newCards = (data ?? []).filter(card => {
      if (card.type === 'system' && card.data.type === 'instance') {
        return card.data.item_data !== null
      }
      return true
    })

    if (replace) {
      if (newCards) {
        this.cards.set(newCards.map(e => writable(e)))
      }
    } else {
      if (newCards) {
        this.cards.update(_cards => {
          const items = newCards || []
          items.forEach(_newCard => {
            if (_cards.findIndex(e => get(e).id === _newCard.id) === -1) {
              _cards.push(writable(_newCard))
            }
          })
          return _cards
        })

        // TODO: This could be cleaner
        this.stackingOrder.update(v => {
          for (let i = 0; i < newCards.length; i++) {
            const index = v.findIndex(e => e === newCards[i].id)
            if (index === -1) {
              if (newCards[i].stacking_order >= v.length) {
                v.push(newCards[i].id)
              }
              v.splice(newCards[i].stacking_order, 0, newCards[i].id)
            }
          }
          return v
        })
      }
    }

    return { abort, data: newCards }
  }

  revalidate() {
    return this.loadViewport(undefined, true, true)
  }

  async createHorizon(name?: string) {
    const currentHorizonIdx = get(this.horizons)?.length || 1
    const horizonName = name || `Horizon ${currentHorizonIdx + 1}`

    const icons = [
      HorizonDefaultIcon1,
      HorizonDefaultIcon2,
      HorizonDefaultIcon3,
      HorizonDefaultIcon4,
    ]

    const icon = icons[(currentHorizonIdx - 1) % icons.length]

    const blob = await fetch(icon).then(r => r.blob())
    const res = await this.createResource(blob)
    const resourceId = res.id

    const { id } = await this.api.createHorizon({
      name: horizonName,
      icon: resourceId,
    })
    const newHorizon = await this.api.getHorizon(id)

    this.horizons.update(v => [...(v || []), newHorizon])

    return newHorizon
  }

  updateHorizon(
    updates: Partial<IHorizon>,
    populate_cards = false,
    viewport_width = 1920
  ) {
    // TODO: use task api
    return this.api.updateHorizon(
      this.getID(),
      updates,
      populate_cards,
      viewport_width
    )
  }

  deleteHorizon() {
    return this.api.deleteHorizon(this.getID())
  }

  makeCardStackingTop(card: TCard) {
    if (!card.local) return this.api.makeCardStackingTop(card.id, this.getID())
  }

  async updateHorizonIcon(blob: Blob) {
    const res = await this.createResource(blob)
    const resourceId = res.id

    this.icon.set(resourceId)

    await this.updateHorizon(
      {
        icon: resourceId,
      },
      false
    )

    this.horizons.update(horizons => {
      const index = horizons.findIndex(v => v.id === this.getID())
      if (index === -1) return horizons
      horizons[index].icon = resourceId
      return horizons
    })

    return resourceId
  }

  async deleteCard(id: string) {
    try {
      await this.api.deleteCard(id, this.getID())

      this.cards.update(_cards => {
        const index = _cards.findIndex(e => get(e).id === id)
        if (index !== -1) {
          _cards.splice(index, 1)
        }
        return _cards
      })

      this.stackingOrder.update(_stackingOrder => {
        const index = _stackingOrder.findIndex(e => e === id)
        if (index !== -1) {
          _stackingOrder.splice(index, 1)
        }
        return _stackingOrder
      })
    } catch (e) {
      console.error(e)
    }
  }

  async deleteCards(ids: Set<string>) {
    const task = this.statusIndicator.addProgressTask(
      ids.size,
      (c, t) => `Deleting ${c}/${t} cards`,
      true
    )

    this.cards.update(_cards => {
      ids.forEach(id => {
        // TODO: error & only del if no error
        const index = _cards.findIndex(e => get(e).id === id)
        if (index !== -1) {
          _cards.splice(index, 1)
        }
      })
      return _cards
    })

    this.stackingOrder.update(_stackingOrder => {
      ids.forEach(id => {
        const index = _stackingOrder.findIndex(e => e === id)
        if (index !== -1) {
          _stackingOrder.splice(index, 1)
        }
      })
      return _stackingOrder
    })

    await Promise.all(
      Array.from(ids).map(async id => {
        try {
          await this.api.deleteCard(id, this.getID())
        } catch (_e) {
          // TODO: display error status
        } finally {
          task.increment()
        }
      })
    )
  }

  async unPinCard(id: string, local = false) {
    if (!local) await this.api.deleteCard(id, this.getID())

    this.cards.update(_cards => {
      const index = _cards.findIndex(e => get(e).id === id)
      if (index !== -1) {
        _cards.splice(index, 1)
      }
      return _cards
    })

    this.stackingOrder.update(_stackingOrder => {
      const index = _stackingOrder.findIndex(e => e === id)
      if (index !== -1) {
        _stackingOrder.splice(index, 1)
      }
      return _stackingOrder
    })
  }

  async unPinCardByItemId(itemId: string, local = false) {
    const card = get(this.cards).find(e => {
      const c = get(e)
      if (c.type === 'system') {
        if (c.data.type === 'instance') {
          return c.data.item_data.id === itemId || c.data.item_id === itemId
        } else if (c.data.type === 'project') {
          return c.data.item_data.id === itemId || c.data.item_id === itemId
        }
      }
      return false
    })

    if (!card) return
    if (!local) await this.api.deleteCard(get(card).id, this.getID())

    this.cards.update(_cards => {
      const index = _cards.findIndex(e => get(e).id === get(card).id)
      if (index !== -1) {
        _cards.splice(index, 1)
      }
      return _cards
    })

    this.stackingOrder.update(_stackingOrder => {
      const index = _stackingOrder.findIndex(e => e === get(card).id)
      if (index !== -1) {
        _stackingOrder.splice(index, 1)
      }
      return _stackingOrder
    })
  }

  updateCard(card: TCard) {
    const task = this.statusIndicator.addTask('Updating card', false)
    try {
      // this.cards.update(_cards => {
      //   const index = _cards.findIndex(e => e.id === card.id)
      //   if (index !== -1 && !_cards[index].local) {
      //     _cards[index] = card
      //   }
      //   return _cards
      // })
      this.cards.update(v => v)
      let res
      if (!card.local) {
        res = this.api.updateCard(card, this.getID())
      }
      task.done()
      return res
    } catch (_) {
      task.done()
      // console.error(e)
      // TODO: handle error
    }
  }

  async revalidateCard(cardId: string) {
    const card = await this.api.getCard(cardId, this.getID(), true)
    // TODO: invalid card
    this.cards.update(_cards => {
      const index = _cards.findIndex(e => get(e).id === cardId)
      if (index !== -1 && !get(_cards[index]).local) {
        _cards[index] = writable(card)
      }
      return _cards
    })
  }

  async insertCardCopy(
    sourceHorizon: string,
    cardId: string,
    cut = false,
    position?: Partial<CardPosition>
  ) {
    const newCardId = await this.api.createHorizonCardCopy(
      cardId,
      sourceHorizon,
      this.getID(),
      cut,
      position
    )

    const newCard = await this.api.getCard(newCardId, this.getID(), true)
    this.cards.update(_cards => [
      ...(_cards || writable([])),
      writable(newCard),
    ])

    return newCard
  }

  getCardPositions() {
    return this.api.getHorizonCardPositions(this.getID())
  }

  async createResource(data: Blob) {
    const task = this.statusIndicator.addTask('Uploading resource', false)
    try {
      const resource = await this.api.createResource(data)
      task.done()
      return resource
    } catch (e) {
      task.done()
      throw e
    }
  }

  async updateResource(id: string, data: Blob) {
    const task = this.statusIndicator.addTask('Updating resource', false)
    try {
      const resource = await this.api.updateResource(id, data)
      task.done()
      return resource
    } catch (e) {
      task.done()
      throw e
    }
  }

  async getResource(id: string) {
    return this.api.getResource(id)
  }

  async createCardRaw(card: Omit<TCard, 'id'>) {
    const task = this.statusIndicator.addTask('Creating card', false)
    try {
      let newCard: Writable<TCard>
      if (!card.local) {
        newCard = writable(await this.api.createCard(card, this.getID()))
      } else {
        newCard = writable({ ...(card as TCard), id: crypto.randomUUID() })
      }

      // TODO: Remove for multiple theme cards
      // currently just delete the old one
      // @ts-ignore remove this
      if (get(newCard).old_card_id !== undefined) {
        const oldThemeCard = get(this.cards).find(
          e => get(e).type === 'theme_editor'
        )
        if (oldThemeCard) {
          this.stackingOrder.update(_stackingOrder => {
            const index = _stackingOrder.findIndex(
              e => e === get(oldThemeCard).id
            )
            if (index !== -1) {
              _stackingOrder.splice(index, 1)
            }
            return _stackingOrder
          })
          this.cards.update(_cards => {
            const index = _cards.findIndex(
              e => get(e).id === get(oldThemeCard).id
            )
            if (index !== -1) {
              _cards.splice(index, 1)
            }
            return _cards
          })
        }
      }

      this.cards.update(_cards => {
        _cards.push(newCard)
        return _cards
      })

      this.stackingOrder.update(_stackingOrder => {
        _stackingOrder.push(get(newCard).id)
        return _stackingOrder
      })

      task.done && task.done()

      return newCard
    } catch (_e) {
      task.done && task.done()
      throw _e
    }
  }

  async createCard<T extends TCard>(
    type: T['type'],
    position: CardPosition,
    options: Partial<CardOptions> = {},
    data: T['data']
  ) {
    const opts = Object.assign({}, { local: false, auto_focus: false }, options)
    const newCard = await this.createCardRaw({
      ...position,
      local: opts.local,
      type,
      data,
    } as T)

    if (opts.auto_focus) {
      await tick()
      setTimeout(
        () =>
          /* TODO: Look into using this instead of additional event listeners. Weird text behaviour tho, focusing on the beginning / outside at end if scrollable */
          /*activeCard.set(get(newCard).id)*/
          document.dispatchEvent(
            new CustomEvent('setMemoFocus', { detail: get(newCard).id })
          ),
        100
      )
    }

    return newCard as Writable<T>
  }

  async createTextCard(
    content: string | { [key: string]: unknown },
    position: CardPosition,
    opts?: Partial<CardOptions>
  ) {
    const newCard = await this.createCard<ICardText>('text', position, opts, {
      content: content,
    })

    return newCard
  }

  async createImageCard(
    data: ICardImage['data'],
    position: CardPosition,
    opts?: Partial<CardOptions>
  ) {
    const newCard = await this.createCard<ICardImage>(
      'image',
      position,
      opts,
      data
    )

    return newCard
  }

  async createLinkCard(
    data: ICardWebLink['data'],
    position: CardPosition,
    opts?: Partial<CardOptions>
  ) {
    const newCard = await this.createCard<ICardWebLink>(
      'link',
      position,
      opts,
      data
    )

    return newCard
  }

  async createYoutubeCard(
    data: ICardEmbedYouTube['data'],
    position: CardPosition,
    opts?: Partial<CardOptions>
  ) {
    const newCard = await this.createCard<ICardEmbedYouTube>(
      'youtube_embed',
      position,
      opts,
      data
    )

    return newCard
  }

  async createEmbedCard(
    data: ICardEmbed['data'],
    position: CardPosition,
    opts?: Partial<CardOptions>
  ) {
    const newCard = await this.createCard<ICardEmbed>(
      'embed',
      position,
      opts,
      data
    )

    return newCard
  }

  async createWebcamCard(position: CardPosition, opts?: Partial<CardOptions>) {
    const newCard = await this.createCard<ISystemCard>(
      'system',
      position,
      opts,
      {
        type: 'webcam',
      }
    )

    return newCard
  }

  async createInstanceCard(
    data: { item_id: string; item_data?: Instance },
    position: CardPosition,
    opts?: Partial<CardOptions>
  ) {
    if (!data.item_data) {
      const instance = await this.api.getInstanceByID(data.item_id)
      if (!instance) {
        throw new Error('Instance not found')
      }

      data.item_data = instance
    }

    const newCard = await this.createCard<ISystemCard>(
      'system',
      position,
      opts,
      {
        type: 'instance',
        item_id: data.item_id,
        item_data: data.item_data,
      }
    )

    return newCard
  }

  async createProjectCard(
    data: { item_id: string; item_data?: App },
    position: CardPosition,
    opts?: Partial<CardOptions>
  ) {
    if (!data.item_data) {
      const project = await this.api.getAppByID(data.item_id)
      if (!project) {
        throw new Error('Project not found')
      }

      data.item_data = project
    }

    const newCard = await this.createCard<ISystemCard>(
      'system',
      position,
      opts,
      {
        type: 'project',
        item_id: data.item_id,
        item_data: data.item_data,
      }
    )

    return newCard
  }

  async createCodeCard(
    data: ICardCustomCode['data'],
    position: CardPosition,
    opts?: Partial<CardOptions>
  ) {
    const newCard = await this.createCard<ICardCustomCode>(
      'code',
      position,
      opts,
      data
    )

    return newCard
  }

  async storeCodeAndCreateCard(
    data: { code: string; editorOpen?: boolean; name?: string },
    position: CardPosition,
    opts?: Partial<CardOptions>
  ) {
    const resourceResponse = await this.api.createResource(
      new Blob([data.code || 'Hello Space'], {
        type: 'space/code-card',
      })
    )

    const newCard = await this.createCodeCard(
      {
        resource_id: resourceResponse.id,
        editor_open: data.editorOpen ?? false,
        name: data.name ?? '',
      },
      position,
      opts
    )

    return newCard
  }

  async createThemeEditorCard(
    data: ICardThemeEditor['data'],
    position: CardPosition,
    opts?: Partial<CardOptions>
  ) {
    const newCard = await this.createCard<ICardThemeEditor>(
      'theme_editor',
      position,
      opts,
      data
    )

    return newCard
  }

  async createSystemAppCard(
    type: ISystemCard['data']['type'],
    position: CardPosition,
    opts?: Partial<CardOptions>
  ) {
    const newCard = await this.createCard<ISystemCard>(
      'system',
      position,
      opts,
      {
        type: type,
      } as ISystemCard['data']
    )

    return newCard
  }

  async parseAndCreateEmbedCard(url: URL, position: CardPosition) {
    const location = url.pathname + url.search + url.hash
    const isSpaceApp = checkIfSpaceApp(url)

    if (isSpaceApp) {
      const alias = getInstanceAlias(url)

      const { public: isPublic, instance_id: instanceId } =
        await this.api.checkSpaceAppRoute(alias, url.pathname)

      return this.createEmbedCard(
        {
          embed_type: 'space_app_private',
          embed_data: {
            instance_id: instanceId,
            current_location: location,
            initial_location: location,
            public: isPublic,
          },
        },
        position
      )
    } else {
      return this.createEmbedCard(
        {
          embed_type: 'external',
          embed_data: {
            location: location,
            hostname: url.hostname,
          },
        },
        position
      )
    }
  }

  static provide = (id: string, api: API, board?: IBoard<any, any>) => {
    const horizon = new Horizon(id, api, board)
    setContext(`horizon`, horizon)

    return horizon
  }

  static use = (): Horizon => {
    return getContext(`horizon`)
  }

  static ROOT_HORIZON_SLUG = '__space_root'
  static DEFAULT_HORIZON_STORAGE_KEY = '__space_default_horizon_id'
}

export const ROOT_HORIZON_SLUG = Horizon.ROOT_HORIZON_SLUG
export const DEFAULT_HORIZON_STORAGE_KEY = Horizon.DEFAULT_HORIZON_STORAGE_KEY

export const useHorizon = Horizon.use
export const provideHorizon = Horizon.provide
export const horizonReadOnlyEditEvent = useEvent('horizon_read_only_edit')
