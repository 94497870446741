import type { Action } from '@deta/teletype'

import type { API } from '@/api'
import type { App } from '@deta/types'
import { getHumanDistanceToNow } from '@/utils/time'

export const useSearchReleasesAction = (api: API, app: App): Action => {
  return {
    id: 'search-releases',
    icon: 'tag',
    name: 'Search Releases',
    breadcrumb: `${app.name} Releases`,
    async loadChildActions() {
      const { data: releases } = await api.getReleasesByAppID(app.id, {
        per_page: 999,
      })
      return releases
        .sort(
          (a, b) =>
            new Date(b.released_at).getTime() -
            new Date(a.released_at).getTime()
        )
        .map(release => ({
          id: release.id,
          name:
            (release.version || release.name) +
            (release.latest ? ' (latest)' : ''),
          description: release.listed ? 'Listed' : 'Unlisted',
          tag: `published ${getHumanDistanceToNow(release.released_at)}`,
          tagStatus: release.latest ? 'active' : 'default',
          actionText: 'View Build',
          handler() {
            window.location.href = `/discovery/r/${release.id}`
          },
        }))
    },
  }
}
