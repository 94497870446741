<script lang="ts">
  import { components, Icons } from './'
  import type { IconType } from './'

  export let name: IconType
  export let className = ''
  export let size = '20px'

  const iconName: Icons | undefined = (<any>Icons)[name.toUpperCase()]
  if (!iconName) {
    console.warn(`[Icon]: Icon name "${name}" is not defined.`)
  }
</script>

{#if iconName}
  <div style:width={size} style:height={size} class={className} on:click>
    <svelte:component this={components[iconName]} />
  </div>
{/if}
