import type { Action } from '@deta/teletype'

import type { API } from '@/api'
import { JobStatus, App } from '@deta/types'
import { getHumanDistanceToNow } from '@/utils/time'

const JobStatusToTagStatus = {
  [JobStatus.COMPLETE]: 'success',
  [JobStatus.PENDING]: 'active',
  [JobStatus.RUNNING]: 'active',
  [JobStatus.TIMED_OUT]: 'warning',
  [JobStatus.INTERNAL_ERROR]: 'failed',
  [JobStatus.FAILED]: 'failed',
}

export const useSearchBuildsAction = (api: API, app: App): Action => {
  return {
    id: 'search-builds',
    icon: 'terminal',
    name: 'Search Builds',
    breadcrumb: `${app.name} Builds`,
    async loadChildActions() {
      const { data: builds } = await api.getBuilds(app.id, { per_page: 999 })
      return builds
        .sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        )
        .map(build => ({
          id: build.id,
          name: build.tag || build.id,
          description:
            build.status !== JobStatus.PENDING &&
            build.status !== JobStatus.RUNNING
              ? `finished ${getHumanDistanceToNow(build.updated_at)}`
              : `started ${getHumanDistanceToNow(build.created_at)}`,
          tag: build.status,
          tagStatus: JobStatusToTagStatus[build.status],
          actionText: 'View Build',
          handler() {
            window.location.href = `/builder/${app.id}?event=bld-${build.tag}`
          },
        }))
    },
  }
}
