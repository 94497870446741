import './app.scss'
import '@deta/ui/output.css'

// import * as Sentry from '@sentry/svelte'
// import { BrowserTracing } from '@sentry/tracing'

import App from './App.svelte'
import HorizonApp from './HorizonApp.svelte'
import { HORIZON_ONLY } from '@/utils/env'

// Sentry.init({
//   dsn: 'https://c4afcf0c3db8476eadbf91831b65878f@o371570.ingest.sentry.io/4504713504489472',
//   integrations: [new BrowserTracing()],
//   tracesSampleRate: import.meta.env.DEV ? 1.0 : 0.2,
// })

let app

if (HORIZON_ONLY) {
  app = new HorizonApp({
    target: document.getElementById('app') as Element,
  })
} else {
  app = new App({
    target: document.getElementById('app') as Element,
  })
}

export default app
