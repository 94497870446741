import { get } from 'svelte/store'
import { navigate } from 'svelte-navigator'

import BuilderIcon from '@/assets/icons/builder.webp'
import DiscoveryIcon from '@/assets/icons/discovery.webp'
import DocsIcon from '@/assets/icons/docs.webp'
import CollectionIcon from '@/assets/icons/collections.png'
import type { CommonActionContext } from '../utils'
import { getErrorMessage } from '@/utils/errors'

const createPinToCanvasAction = (
  kind: string,
  context: CommonActionContext
) => ({
  icon: 'pin',
  id: `instance-options-pin`,
  name: 'Pin to Horizon',
  handler: async (_, teletype) => {
    try {
      //await context.legacyCanvas.pinToCanvas(id, 'system_app')
      const baseCard = {
        x: window.innerWidth / 2 - 60,
        y: 30,
        width: 120,
        height: 120,
      }
      // TODO: error
      switch (kind) {
        case 'discovery':
          await context.horizon.createSystemAppCard('discovery', baseCard)
          break
        case 'docs':
          await context.horizon.createSystemAppCard('docs', baseCard)
          break
        case 'builder':
          await context.horizon.createSystemAppCard('builder', baseCard)
          break
        case 'collections':
          await context.horizon.createSystemAppCard('collections', baseCard)
          break
        default:
          console.error(`Unknown system app kind: ${kind}`)
          break
      }
      // context.canvas.createCard({
      //   key: crypto.randomUUID(),
      //   type: 'system',
      //   kind,
      //   posX: 60,
      //   posY: 60,
      //   width: 120,
      //   height: 120,
      // })
      teletype.showSuccess('App pinned to Horizon!')
    } catch (e) {
      teletype.showError(getErrorMessage(e, 'Error pinning app to canvas'))
    }
  },
})

export const useSystemAppsActions = (context: CommonActionContext) => {
  const actions = [
    {
      id: 'discovery-app',
      name: 'Discovery',
      icon: DiscoveryIcon,
      section: 'System Apps',
      parent: 'instances',
      nestedSearch: true,
      actionText: 'Open App',
      handler() {
        window.location.href = '/discovery'
      },
      actionPanel: async () => {
        // const canvasItem = await context.api.getCanvasItemByItemIDAndType(
        //   'discovery',
        //   'system_app'
        // )

        return [
          createPinToCanvasAction('discovery', context),
          {
            id: 'discovery-top',
            icon: 'trophy',
            name: 'View Top',
            handler: () => navigate('/discovery?tab=top'),
          },
          {
            id: 'discovery-recent',
            icon: 'clock',
            name: 'View Recent',
            handler: () => navigate('/discovery?tab=recent'),
          },
          {
            id: 'discovery-hot',
            icon: 'flame',
            name: 'View Hot',
            handler: () => navigate('/discovery?tab=hot'),
          },
        ]
      },
    },
    {
      id: 'docs-app',
      name: 'Docs',
      icon: DocsIcon,
      section: 'System Apps',
      parent: 'instances',
      nestedSearch: true,
      actionText: 'Open App',
      handler() {
        window.location.href = '/docs'
      },
      actionPanel: [
        createPinToCanvasAction('docs', context),
        {
          id: 'docs-learn',
          name: 'Learn',
          icon: 'docs',
          handler: () => navigate('/docs/en/learn'),
        },
        {
          id: 'docs-use',
          name: 'Use',
          icon: 'docs',
          handler: () => navigate('/docs/en/use'),
        },
        {
          id: 'docs-build',
          name: 'Build',
          icon: 'docs',
          handler: () => navigate('/docs/en/build'),
        },
        {
          id: 'docs-publish',
          name: 'Publish',
          icon: 'docs',
          handler: () => navigate('/docs/en/publish'),
        },
      ],
    },
    {
      id: 'collection-app',
      name: 'Collections',
      icon: CollectionIcon,
      section: 'System Apps',
      parent: 'instances',
      nestedSearch: true,
      actionText: 'Open App',
      handler: () => navigate('/collections'),
      actionPanel: async () => {
        // const canvasItem = await context.api.getCanvasItemByItemIDAndType(
        //   'collections',
        //   'system_app'
        // )

        return [createPinToCanvasAction('collections', context)]
      },
    },
  ]

  if (get(context.metadata.isDevMode)) {
    return [
      ...actions,
      {
        id: 'builder',
        name: 'Builder',
        icon: BuilderIcon,
        section: 'System Apps',
        parent: 'instances',
        nestedSearch: true,
        actionText: 'Open App',
        handler() {
          window.location.href = '/builder'
        },
        actionPanel: async () => {
          // const canvasItem = await context.api.getCanvasItemByItemIDAndType(
          //   'builder',
          //   'system_app'
          // )

          return [
            {
              icon: 'plus',
              id: 'builder-new',
              name: 'New Project',
              handler: () => navigate('/builder/new'),
            },
            createPinToCanvasAction('builder', context),
          ]
        },
      },
    ]
  }

  return actions
}
