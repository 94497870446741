export const useEvent = <T = unknown>(key: string, elem?: HTMLElement) => {
  const scope = elem || window

  const listen = (handler: (payload: T) => void) => {
    const handleEvent = (e: Event) => {
      const event = e as CustomEvent<T>
      handler(event.detail)
    }

    scope.addEventListener(key, handleEvent, false)

    const removeListener = () =>
      scope.removeEventListener(key, handleEvent, false)

    return removeListener
  }

  const fire = (payload?: T) => {
    const event = new CustomEvent(key, { detail: payload })

    scope.dispatchEvent(event)
  }

  return { listen, fire, key }
}
