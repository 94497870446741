<script lang="ts">
  import { cn } from '../../utils'

  /**
   * Enable flashing animation
   */
  export let flashing = true

  /**
   * Use dark theme
   */
  export let dark = false

  /**
   * Custom styling
   * @type {string}
   */
  export let className = ''

  let styles = `animation-fill-mode: backwards; animation-delay: ${Math.round(
    Math.random() * 300
  )}ms;`
</script>

<div
  {...$$restProps}
  on:click
  on:focus
  on:mouseover
  on:mouseenter
  on:mouseleave
  class={cn(
    !className && 'h-12 w-40',
    dark ? 'bg-seashell-700' : 'bg-seashell-300 dark:bg-seashell-700',
    'rounded-xl',
    flashing && 'animate-pulse',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    className
  )}
  style={flashing ? styles : ''}
/>
