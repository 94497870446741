<script lang="ts">
  import { Icon } from '@deta/ui'

  export let name: string

  const isUrl = name.startsWith('http') || name.startsWith('/')
  const isCss =
    name.includes('background-color:') || name.includes('background-image:')
</script>

{#if isUrl}
  <img src={name} alt="Icon" class="w-7 h-7 rounded" />
{:else if isCss}
  <div style={name} alt="Gradient" class="w-7 h-7 rounded" />
{:else}
  <Icon {name} />
{/if}
