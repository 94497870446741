import { getContext, setContext, SvelteComponent } from 'svelte'

import { navigate, useResolve } from 'svelte-navigator'
import type { RouteProps } from 'svelte-navigator/types/Route'

export type RouteRecordRaw = RouteProps
export type RouteRecord<T extends string = string> = RouteRecordRaw & {
  name?: T
  layout?: typeof SvelteComponent
  component: () => Promise<typeof SvelteComponent>
}
export type Routes = RouteRecord[]

export type RouteParams = { [key: string]: string }

export class Router {
  routes: Routes

  constructor(routes: Routes) {
    this.routes = routes
  }

  static provide = (routes: Routes) => {
    const router = new Router(routes)
    setContext('router', router)

    return router
  }

  static use = (): Router => {
    return getContext('router')
  }

  /**
   * Navigate to a given route by its name.
   * @param name Route name
   * @param params Route params
   * @returns
   */
  navigate(name: string, params?: RouteParams, resolveRelative = false) {
    const path = this.resolve(name, params, resolveRelative)
    if (path === '*' || !path) {
      navigate('/404') // To Do: Render component directly instead of redirecting
      return
    }

    navigate(path)
  }

  /**
   * Resolve a path by a route name.
   * @param name Route name
   * @param params Route params
   * @param resolveRelative Resolve path relative to current route
   * @returns
   */
  resolve(name: string, params?: RouteParams, resolveRelative = false) {
    const paramRegex = /:(\w*)/g

    const route = this.routes.find(route => route.name === name)
    if (!route || !route.path) return

    let path = route.path
    if (path === '*') {
      return '*'
    }

    // Resolve path relative to current route
    if (resolveRelative) {
      const resolveRelative = useResolve()
      path = resolveRelative(path.slice(1))
    }

    // To Do: typed params
    const matches = [...path.matchAll(paramRegex)]
    matches.forEach(([match, key]) => {
      if (!params || !params[key])
        throw new Error(`[Router]: Missing param "${key}" for route "${name}"`)

      path = path.replace(match, params[key] || '')
    })

    return path
  }
}

export const provideRouter = Router.provide
export const useRouter = Router.use

export { useFocus, useLocation, useMatch, useParams } from 'svelte-navigator'
export { RouteNames } from '@/router/routes'
export { BuilderRouteNames } from '@/router/builder'
