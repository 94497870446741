<script>
  import Planet from '@/assets/planet.png'
  import { useTitle } from '@/utils/mount'
  import { Text } from '@deta/ui'

  useTitle('Not Found - Deta Space')
</script>

<div
  class="h-screen w-screen flex flex-col items-center justify-center space-y-2"
>
  <img src={Planet} alt="Planet" class="w-32" />
  <Text as="h1" size="2xl" weight="medium">Page not found</Text>
  <a href="/" class="text-deta-default">Go back to Space</a>
</div>
