import { API, useAPI } from '@/api'
import { Router, useRouter } from '@/router'
import { useHorizon } from '@/service/horizon'
import type { Horizon } from '@/service/horizon'
import { Metadata, useMetadata } from '@/utils/metadata'
import { hasClassOrParentWithClass } from '@deta/tela'

type Context = {
  api: API
  router: Router
  horizon: Horizon
  metadata: Metadata
}

export const useCommonContext = (context: Partial<Context>) => {
  const api = context.api || useAPI()
  const router = context.router || useRouter()
  const horizon = context.horizon || useHorizon()
  const metadata = context.metadata || useMetadata()

  return {
    api,
    router,
    horizon,
    // legacyCanvas,
    metadata,
  }
}

export type CommonActionContext = ReturnType<typeof useCommonContext>

export const objectToString = (obj: Record<string, unknown>) => {
  return Object.entries(obj)
    .map(
      ([key, value]) =>
        `${key}=${
          typeof value === 'object' && value !== null ? 'object' : value
        }`
    )
    .join(', ')
}

export function selfOrParentWithDataProperty(
  el: HTMLElement,
  property: string
): HTMLElement | null {
  if (el.dataset[property]) {
    return el
  }

  if (el.parentElement) {
    return selfOrParentWithDataProperty(el.parentElement, property)
  }

  return null
}

export function isClickOutsideCard(target: HTMLElement, cardId: string) {
  if (hasClassOrParentWithClass(target, 'card')) {
    const el = selfOrParentWithDataProperty(target, 'id')
    const elId = el?.dataset['id']
    if (elId === cardId) {
      return false
    }
    return true
  }
  return true
}
export function isClickInsideCard(target: HTMLElement, cardId: string) {
  if (hasClassOrParentWithClass(target, 'card')) {
    const el = selfOrParentWithDataProperty(target, 'id')
    const elId = el?.dataset['id']
    if (elId === cardId) {
      return true
    }
    return false
  }
  return false
}

// Returns the cards data-id if the cliked element or its parents have the .card class
export function getClickedCard(target: HTMLElement) {
  if (hasClassOrParentWithClass(target, 'positionable')) {
    const el = selfOrParentWithDataProperty(target, 'id')
    const elId = el?.dataset['id']
    return elId
  }
  return null
}
