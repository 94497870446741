<script context="module" lang="ts">
  export const defaultTheme = {
    css_easy: null,
    css_custom: null,
    styles: {
      easy: {
        core: {
          tint: {
            light: '#faf7fc',
            dark: '#222124', //'#4F4F4F',
          },
          background: {
            light: '#ffffff',
            dark: '#121212',
          },
          backgroundImage: null,
          selection: {
            light: '#10A0F9',
            dark: '#175CA6',
          },
          borderRadius: 8,
          shadow: 'none' as ThemeShadow,
          glass: 10,
        },

        text: {
          color: {
            light: '#000000',
            dark: '#ffffff',
          },
          selection: {
            light: null,
            dark: null,
          },
          font: 'sans' as ThemeFont,
        },

        cards: {
          background: {
            light: '#ffffff',
            dark: '#121212',
          },
          border: {
            light: null,
            dark: null,
          },
          focusBorder: {
            light: '#e8338d',
            dark: '#9905c6',
          },
        },

        galacticIsland: {
          background: {
            light: null,
            dark: null,
          },
        },
      },
      custom: `/* Apply styling to the horizon */
.horizon {
  /* background: lime !important; */
}
/* Apply styling to all cards */
.card {
  /* padding: 12px !important; */
}

/* Style the content of a specific card type */
.card.text .content {
  /* color: green !important; */
}

/* Other card types */
.card.image {}
.card.image .content img {
  /* filter: invert(100%); */
}

.card.link {}
.card.link .details {
  /* backdrop-filter: none !important; */
}

.card.app-instance {}
.card.builder-project {}
.card.code {}
.card.system {}
.card.theme_editor {}`,
    },
  } as IParsedThemeConfig

  // Utils
  function hslToHex(h, s, l) {
    l /= 100
    const a = (s * Math.min(l, 1 - l)) / 100
    const f = n => {
      const k = (n + h / 30) % 12
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
      return Math.round(255 * color)
        .toString(16)
        .padStart(2, '0') // convert to Hex and prefix "0" if needed
    }
    return `${f(0)}${f(8)}${f(4)}`
  }
  function hexToHSL(H) {
    H = H || '#000000'
    // Convert hex to RGB first
    let r = 0,
      g = 0,
      b = 0
    if (H.length == 4) {
      r = '0x' + H[1] + H[1]
      g = '0x' + H[2] + H[2]
      b = '0x' + H[3] + H[3]
    } else if (H.length == 7) {
      r = '0x' + H[1] + H[2]
      g = '0x' + H[3] + H[4]
      b = '0x' + H[5] + H[6]
    }
    // Then to HSL
    r /= 255
    g /= 255
    b /= 255
    let cmin = Math.min(r, g, b),
      cmax = Math.max(r, g, b),
      delta = cmax - cmin,
      h = 0,
      s = 0,
      l = 0

    if (delta == 0) h = 0
    else if (cmax == r) h = ((g - b) / delta) % 6
    else if (cmax == g) h = (b - r) / delta + 2
    else h = (r - g) / delta + 4

    h = Math.round(h * 60)

    if (h < 0) h += 360

    l = (cmax + cmin) / 2
    s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1))
    s = +(s * 100).toFixed(1)
    l = +(l * 100).toFixed(1)

    return [h, s, l]
  }

  function getShadowString(value: number): ThemeShadow {
    if (value === 0) {
      return 'none'
    } else if (value === 25) {
      return 'sm'
    } else if (value === 50) {
      return 'md'
    } else if (value === 75) {
      return 'lg'
    } else if (value === 100) {
      return 'xl'
    } else {
      return 'none'
    }
  }

  function getShadowValue(name: string) {
    const shadowMap = {
      none: 0,
      sm: 25,
      md: 50,
      lg: 75,
      xl: 100,
    } as any

    return shadowMap[name] || 0
  }

  function getShadowCSS(value: ThemeShadow) {
    const shadowMap = {
      none: '',
      sm: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
      md: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
      lg: '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
      xl: '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)',
    }

    return shadowMap[value] || shadowMap.none
  }

  function getFontValue(value: ThemeFont) {
    const fontMap = {
      sans: 'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
      serif: 'ui-serif, Georgia, Cambria, "Times New Roman", Times, serif',
      mono: 'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
    }

    return fontMap[value] || fontMap.sans
  }

  export function buildCustomStyling(custom: string): string {
    let out = custom.replaceAll(/\\n/g, '').replaceAll(/\\t/g, '')
    const { css, errors } = sanitizeHorizonTheme(out)
    // TODO: display errors
    return css
  }

  export function buildEasyStyling(styles: ThemeStylingConfig, api: API, multi = false): string {
    const lightTintHSL = hexToHSL(styles.core.tint.light)
    const darkTintHSL = hexToHSL(styles.core.tint.dark)

    const lightTextHSL = hexToHSL(styles.text.color.light)
    const darkTextHSL = hexToHSL(styles.text.color.dark)

    const shadowValue = getShadowCSS(styles.core.shadow)
    const fontValue = getFontValue(styles.text.font)

    const isLightMode = get(activeSpaceTheme) === 'light'

    const getBackgroundImageUrl = (resourceId: string) => {
      if (import.meta.env.DEV) {
        return api.getResourceUrl(resourceId)
      } else {
        return `/api${api.getRelativeResourceUrl(resourceId)}`
      }
    }

    const light = `
        /* General */
        --theme-tint-hue: ${lightTintHSL[0]};
        --theme-tint-saturation: ${lightTintHSL[1]}%;
        --theme-tint-lightness: ${lightTintHSL[2]}%;

        --theme-background-hue: ${
          (styles.core.background?.light
            ? hexToHSL(styles.core.background?.light)
            : [0, 0, 100])[0]
        };
        --theme-background-saturation: ${
          (styles.core.background?.light
            ? hexToHSL(styles.core.background?.light)
            : [0, 0, 100])[1]
        }%;
        --theme-background-lightness: ${
          (styles.core.background?.light
            ? hexToHSL(styles.core.background?.light)
            : [0, 0, 100])[2]
        }%;
        ${
          styles.core.backgroundImage
            ? `--theme-background-image: url('${getBackgroundImageUrl(
                styles.core.backgroundImage
              )}${styles.core.backgroundImage.endsWith('?') ? '?' : ''}')`
            : ''
        };

        --theme-selection-hue: ${
          (styles.core.selection?.light
            ? hexToHSL(styles.core.selection?.light)
            : lightTintHSL)[0]
        };
        --theme-selection-saturation: ${
          (styles.core.selection?.light
            ? hexToHSL(styles.core.selection?.light)
            : lightTintHSL)[1]
        }%;
        --theme-selection-lightness: ${
          (styles.core.selection?.light
            ? hexToHSL(styles.core.selection?.light)
            : lightTintHSL)[2]
        }%;

        /* Text */
        --theme-text-hue: ${
          (styles.text.color?.light
            ? hexToHSL(styles.text.color?.light)
            : lightTintHSL)[0]
        };
        --theme-text-saturation: ${
          (styles.text.color?.light
            ? hexToHSL(styles.text.color?.light)
            : lightTintHSL)[1]
        }%;
        --theme-text-lightness: ${
          (styles.text.color?.light
            ? hexToHSL(styles.text.color?.light)
            : lightTintHSL)[2]
        }%;

        --theme-text-selection-hue: ${
          (styles.text.selection?.light
            ? hexToHSL(styles.text.selection?.light)
            : lightTintHSL)[0]
        };
        --theme-text-selection-saturation: ${
          (styles.text.selection?.light
            ? hexToHSL(styles.text.selection?.light)
            : lightTintHSL)[1]
        }%;
        --theme-text-selection-lightness: ${
          (styles.text.selection?.light
            ? hexToHSL(styles.text.selection?.light)
            : lightTintHSL)[2]
        }%;


        /* Cards */
        --theme-card-background-hue: ${
          (styles.cards.background?.light
            ? hexToHSL(styles.cards.background?.light)
            : [0, 0, 100])[0]
        };
        --theme-card-background-saturation: ${
          (styles.cards.background?.light
            ? hexToHSL(styles.cards.background?.light)
            : [0, 0, 100])[1]
        }%;
        --theme-card-background-lightness: ${
          (styles.cards.background?.light
            ? hexToHSL(styles.cards.background?.light)
            : [0, 0, 100])[2]
        }%;

        --theme-card-border-hue: ${
          (styles.cards.border?.light
            ? hexToHSL(styles.cards.border?.light)
            : lightTintHSL)[0]
        };
        --theme-card-border-saturation: ${
          (styles.cards.border?.light
            ? hexToHSL(styles.cards.border?.light)
            : lightTintHSL)[1]
        }%;
        --theme-card-border-lightness: ${
          (styles.cards.border?.light
            ? hexToHSL(styles.cards.border?.light)
            : lightTintHSL)[2]
        }%;

        --theme-card-focus-border-hue: ${
          (styles.cards.focusBorder?.light
            ? hexToHSL(styles.cards.focusBorder?.light)
            : lightTintHSL)[0]
        };
        --theme-card-focus-border-saturation: ${
          (styles.cards.focusBorder?.light
            ? hexToHSL(styles.cards.focusBorder?.light)
            : lightTintHSL)[1]
        }%;
        --theme-card-focus-border-lightness: ${
          (styles.cards.focusBorder?.light
            ? hexToHSL(styles.cards.focusBorder?.light)
            : lightTintHSL)[2]
        }%;

        /* Galactic Island */
        --theme-galactic-island-background-hue: ${
          (styles.galacticIsland.background?.light
            ? hexToHSL(styles.galacticIsland.background?.light)
            : lightTintHSL)[0]
        };
        --theme-galactic-island-background-saturation: ${
          (styles.galacticIsland.background?.light
            ? hexToHSL(styles.galacticIsland.background?.light)
            : lightTintHSL)[1]
        }%;
        --theme-galactic-island-background-lightness: ${
          (styles.galacticIsland.background?.light
            ? hexToHSL(styles.galacticIsland.background?.light)
            : lightTintHSL)[2]
        }%;

        /*--color-text-hue: ${lightTextHSL[0]};
        --color-text-saturation: ${lightTextHSL[1]}%;
        --color-text-lightness: ${lightTextHSL[2]}%;*/

        --theme-highlight-hue: ${hexToHSL(styles.core.tint.light)[0]};
        --theme-highlight-saturation: ${hexToHSL(styles.core.tint.light)[1]}%;
        --theme-highlight-lightness: ${hexToHSL(styles.core.tint.light)[2]}%;
    `

    const dark = `
        /* General */
        --theme-tint-hue: ${darkTintHSL[0]};
        --theme-tint-saturation: ${darkTintHSL[1]}%;
        --theme-tint-lightness: ${darkTintHSL[2]}%;

        --theme-background-hue: ${
          (styles.core.background?.dark
            ? hexToHSL(styles.core.background?.dark)
            : [0, 0, 10])[0]
        };
        --theme-background-saturation: ${
          (styles.core.background?.dark
            ? hexToHSL(styles.core.background?.dark)
            : [0, 0, 0])[1]
        }%;
        --theme-background-lightness: ${
          (styles.core.background?.dark
            ? hexToHSL(styles.core.background?.dark)
            : [0, 0, 4])[2]
        }%;
        ${
          styles.core.backgroundImage
            ? `--theme-background-image: url('${getBackgroundImageUrl(
                styles.core.backgroundImage
              )}${styles.core.backgroundImage.endsWith('?') ? '?' : ''}')`
            : ''
        };

        --theme-selection-hue: ${
          (styles.core.selection?.dark
            ? hexToHSL(styles.core.selection?.dark)
            : darkTintHSL)[0]
        };
        --theme-selection-saturation: ${
          (styles.core.selection?.dark
            ? hexToHSL(styles.core.selection?.dark)
            : darkTintHSL)[1]
        }%;
        --theme-selection-lightness: ${
          (styles.core.selection?.dark
            ? hexToHSL(styles.core.selection?.dark)
            : darkTintHSL)[2]
        }%;

        /* Text */
        --theme-text-hue: ${
          (styles.text.color?.dark
            ? hexToHSL(styles.text.color?.dark)
            : darkTintHSL)[0]
        };
        --theme-text-saturation: ${
          (styles.text.color?.dark
            ? hexToHSL(styles.text.color?.dark)
            : darkTintHSL)[1]
        }%;
        --theme-text-lightness: ${
          (styles.text.color?.dark
            ? hexToHSL(styles.text.color?.dark)
            : darkTintHSL)[2]
        }%;

        --theme-text-selection-hue: ${
          (styles.text.selection?.dark
            ? hexToHSL(styles.text.selection?.dark)
            : darkTintHSL)[0]
        };
        --theme-text-selection-saturation: ${
          (styles.text.selection?.dark
            ? hexToHSL(styles.text.selection?.dark)
            : darkTintHSL)[1]
        }%;
        --theme-text-selection-lightness: ${
          (styles.text.selection?.dark
            ? hexToHSL(styles.text.selection?.dark)
            : darkTintHSL)[2]
        }%;


        /* Cards */
        --theme-card-background-hue: ${
          (styles.cards.background?.dark
            ? hexToHSL(styles.cards.background?.dark)
            : [0, 0, 5])[0]
        };
        --theme-card-background-saturation: ${
          (styles.cards.background?.dark
            ? hexToHSL(styles.cards.background?.dark)
            : [0, 0, 5])[1]
        }%;
        --theme-card-background-lightness: ${
          (styles.cards.background?.dark
            ? hexToHSL(styles.cards.background?.dark)
            : [0, 0, 5])[2]
        }%;

        --theme-card-border-hue: ${
          (styles.cards.border?.dark
            ? hexToHSL(styles.cards.border?.dark)
            : darkTintHSL)[0]
        };
        --theme-card-border-saturation: ${
          (styles.cards.border?.dark
            ? hexToHSL(styles.cards.border?.dark)
            : darkTintHSL)[1]
        }%;
        --theme-card-border-lightness: ${
          (styles.cards.border?.dark
            ? hexToHSL(styles.cards.border?.dark)
            : darkTintHSL)[2]
        }%;

        --theme-card-focus-border-hue: ${
          (styles.cards.focusBorder?.dark
            ? hexToHSL(styles.cards.focusBorder?.dark)
            : darkTintHSL)[0]
        };
        --theme-card-focus-border-saturation: ${
          (styles.cards.focusBorder?.dark
            ? hexToHSL(styles.cards.focusBorder?.dark)
            : darkTintHSL)[1]
        }%;
        --theme-card-focus-border-lightness: ${
          (styles.cards.focusBorder?.dark
            ? hexToHSL(styles.cards.focusBorder?.dark)
            : darkTintHSL)[2]
        }%;

        /* Galactic Island */
        --theme-galactic-island-background-hue: ${
          (styles.galacticIsland.background?.dark
            ? hexToHSL(styles.galacticIsland.background?.dark)
            : darkTintHSL)[0]
        };
        --theme-galactic-island-background-saturation: ${
          (styles.galacticIsland.background?.dark
            ? hexToHSL(styles.galacticIsland.background?.dark)
            : darkTintHSL)[1]
        }%;
        --theme-galactic-island-background-lightness: ${
          (styles.galacticIsland.background?.dark
            ? hexToHSL(styles.galacticIsland.background?.dark)
            : darkTintHSL)[2]
        }%;

        /*--color-text-hue: ${darkTextHSL[0]};
        --color-text-saturation: ${darkTextHSL[1]}%;
        --color-text-lightness: ${darkTextHSL[2]}%;*/

        --theme-highlight-hue: ${hexToHSL(styles.core.tint.dark)[0]};
        --theme-highlight-saturation: ${hexToHSL(styles.core.tint.dark)[1]}%;
        --theme-highlight-lightness: ${hexToHSL(styles.core.tint.dark)[2]}%;
    `

    const combined = `
        /* General */
        --theme-tint-hue: ${lightTintHSL[0]};
        --theme-tint-saturation: ${lightTintHSL[1]}%;
        --theme-tint-lightness: ${lightTintHSL[2]}%;

        --theme-background-hue: ${
          (styles.core.background?.light
            ? hexToHSL(styles.core.background?.light)
            : isLightMode ? [0, 0, 100] : [0, 0, 10])[0]
        };
        --theme-background-saturation: ${
          (styles.core.background?.light
            ? hexToHSL(styles.core.background?.light)
            : isLightMode ? [0, 0, 100] : [0, 0, 0])[1]
        }%;
        --theme-background-lightness: ${
          (styles.core.background?.light
            ? hexToHSL(styles.core.background?.light)
            : isLightMode ? [0, 0, 100] : [0, 0, 4])[2]
        }%;
        ${
          styles.core.backgroundImage
            ? `--theme-background-image: url('${getBackgroundImageUrl(
                styles.core.backgroundImage
              )}${styles.core.backgroundImage.endsWith('?') ? '?' : ''}')`
            : ''
        };

        --theme-selection-hue: ${
          (styles.core.selection?.light
            ? hexToHSL(styles.core.selection?.light)
            : lightTintHSL)[0]
        };
        --theme-selection-saturation: ${
          (styles.core.selection?.light
            ? hexToHSL(styles.core.selection?.light)
            : lightTintHSL)[1]
        }%;
        --theme-selection-lightness: ${
          (styles.core.selection?.light
            ? hexToHSL(styles.core.selection?.light)
            : lightTintHSL)[2]
        }%;

        /* Text */
        --theme-text-hue: ${
          (styles.text.color?.light
            ? hexToHSL(styles.text.color?.light)
            : lightTintHSL)[0]
        };
        --theme-text-saturation: ${
          (styles.text.color?.light
            ? hexToHSL(styles.text.color?.light)
            : lightTintHSL)[1]
        }%;
        --theme-text-lightness: ${
          (styles.text.color?.light
            ? hexToHSL(styles.text.color?.light)
            : lightTintHSL)[2]
        }%;

        --theme-text-selection-hue: ${
          (styles.text.selection?.light
            ? hexToHSL(styles.text.selection?.light)
            : lightTintHSL)[0]
        };
        --theme-text-selection-saturation: ${
          (styles.text.selection?.light
            ? hexToHSL(styles.text.selection?.light)
            : lightTintHSL)[1]
        }%;
        --theme-text-selection-lightness: ${
          (styles.text.selection?.light
            ? hexToHSL(styles.text.selection?.light)
            : lightTintHSL)[2]
        }%;


        /* Cards */
        --theme-card-background-hue: ${
          (styles.cards.background?.light
            ? hexToHSL(styles.cards.background?.light)
            : isLightMode ? [0, 0, 100] : [0, 0, 5])[0]
        };
        --theme-card-background-saturation: ${
          (styles.cards.background?.light
            ? hexToHSL(styles.cards.background?.light)
            : isLightMode ? [0, 0, 100] : [0, 0, 5])[1]
        }%;
        --theme-card-background-lightness: ${
          (styles.cards.background?.light
            ? hexToHSL(styles.cards.background?.light)
            : isLightMode ? [0, 0, 100] : [0, 0, 5])[2]
        }%;

        --theme-card-border-hue: ${
          (styles.cards.border?.light
            ? hexToHSL(styles.cards.border?.light)
            : lightTintHSL)[0]
        };
        --theme-card-border-saturation: ${
          (styles.cards.border?.light
            ? hexToHSL(styles.cards.border?.light)
            : lightTintHSL)[1]
        }%;
        --theme-card-border-lightness: ${
          (styles.cards.border?.light
            ? hexToHSL(styles.cards.border?.light)
            : lightTintHSL)[2]
        }%;

        --theme-card-focus-border-hue: ${
          (styles.cards.focusBorder?.light
            ? hexToHSL(styles.cards.focusBorder?.light)
            : lightTintHSL)[0]
        };
        --theme-card-focus-border-saturation: ${
          (styles.cards.focusBorder?.light
            ? hexToHSL(styles.cards.focusBorder?.light)
            : lightTintHSL)[1]
        }%;
        --theme-card-focus-border-lightness: ${
          (styles.cards.focusBorder?.light
            ? hexToHSL(styles.cards.focusBorder?.light)
            : lightTintHSL)[2]
        }%;

        /* Galactic Island */
        --theme-galactic-island-background-hue: ${
          (styles.galacticIsland.background?.light
            ? hexToHSL(styles.galacticIsland.background?.light)
            : lightTintHSL)[0]
        };
        --theme-galactic-island-background-saturation: ${
          (styles.galacticIsland.background?.light
            ? hexToHSL(styles.galacticIsland.background?.light)
            : lightTintHSL)[1]
        }%;
        --theme-galactic-island-background-lightness: ${
          (styles.galacticIsland.background?.light
            ? hexToHSL(styles.galacticIsland.background?.light)
            : lightTintHSL)[2]
        }%;

        /*--color-text-hue: ${lightTextHSL[0]};
        --color-text-saturation: ${lightTextHSL[1]}%;
        --color-text-lightness: ${lightTextHSL[2]}%;*/

        --theme-highlight-hue: ${hexToHSL(styles.core.tint.light)[0]};
        --theme-highlight-saturation: ${hexToHSL(styles.core.tint.light)[1]}%;
        --theme-highlight-lightness: ${hexToHSL(styles.core.tint.light)[2]}%;
    `

    if (multi) {
      return `
        :root {
          --theme-border-radius: ${styles.core.borderRadius}px;
          --theme-glass: ${100 - styles.core.glass}%; /* TODO: REIMPLS */
          --theme-shadow: ${shadowValue};
          --theme-font: ${fontValue};
        }

        .light {
          ${light}
        }
        .dark {
          ${dark}
        }
      `
    } else {
      return `
        :root {
          --theme-border-radius: ${styles.core.borderRadius}px;
          --theme-glass: ${100 - styles.core.glass}%; /* TODO: REIMPLS */
          --theme-shadow: ${shadowValue};
          --theme-font: ${fontValue};

          ${combined}
        }
      `
    }
  }
</script>
<script lang="ts">
  import type {
    ICardThemeEditor,
    IParsedThemeConfig,
    ThemeFont,
    ThemeShadow,
    ThemeStylingConfig,
  } from '../../canvas.types'
  import RawCard from '../RawCard.svelte'
  import RawCardMobile from '../RawCardMobile.svelte'
  import Tabs from '../Content/ThemeEditor/Tabs.svelte'
  import { debounce } from '@deta/tela'
  import { Icon, Link, theme as activeSpaceTheme } from '@deta/ui/src'


  import CssEditor from '../Content/ThemeEditor/CssEditor.svelte'
  import { horizonReadOnlyEditEvent, useHorizon } from '@/service/horizon'
  import StyleOption from '../Content/ThemeEditor/StyleOption.svelte'
  import FontSelector from '../Content/ThemeEditor/FontSelector.svelte'
  import ColorPickerGroup from '../Content/ThemeEditor/ColorPickerGroup.svelte'
  import Slider from '../Content/ThemeEditor/Slider.svelte'
  import FilePicker from '../Content/ThemeEditor/FilePicker.svelte'
  import { API, useAPI } from '@/api'
  import { sanitizeHorizonTheme } from '@/utils/sanitize-css'
  import { Writable, get } from 'svelte/store'
  import { useCancelableDebounce } from '@/utils/debounce'
  import { HORIZON_ONLY } from '@/utils/env'

  export let card: Writable<ICardThemeEditor>

  const api = useAPI()
  const horizon = useHorizon()

  const getDefaultTheme = () => {
    const isDark = $activeSpaceTheme === 'dark'
    if (isDark) {
      const darkTheme = structuredClone(defaultTheme)
      darkTheme.styles.easy.core.tint.light = defaultTheme.styles.easy.core.tint.dark
      darkTheme.styles.easy.core.background.light = defaultTheme.styles.easy.core.background.dark
      darkTheme.styles.easy.core.selection.light = defaultTheme.styles.easy.core.selection.dark
      darkTheme.styles.easy.text.color.light = defaultTheme.styles.easy.text.color.dark
      darkTheme.styles.easy.cards.focusBorder.light = defaultTheme.styles.easy.cards.focusBorder.dark
      darkTheme.styles.easy.cards.background.light = defaultTheme.styles.easy.cards.background.dark

      return darkTheme
    } else {
      return defaultTheme
    }
  }

  let isMobile = horizon.isMobile
  let theme_css = horizon.theme_css

  const parseThemeConfig = (rawThemeString: string) => {
    try {
      const parsed = JSON.parse(rawThemeString)

      if (parsed) {
        return parsed as IParsedThemeConfig
      } else {
        return getDefaultTheme()
      }
    } catch (err) {
      return getDefaultTheme()
    }
  }

  let theme = parseThemeConfig($theme_css)

  let customCSSValue = theme.styles.custom || ''

  let shadowValue = getShadowValue(theme.styles.easy.core.shadow)
  $: {
    theme.styles.easy.core.shadow = getShadowString(shadowValue)
  }

  $: {
    applyStyles(theme.styles.easy, customCSSValue)
  }


  function onBackgroundFileSelect(e: CustomEvent<Blob>) {
    if (horizon.readOnly) return

    if (theme.styles.easy.core.backgroundImage === null) {
      horizon.createResource(e.detail).then(res => {
        if (!res) alert('Could not create file resource!') // TODO: Make pretty error
        theme.styles.easy.core.backgroundImage = res.id
        applyStyles(theme.styles.easy, customCSSValue)
      })
    } else {
      horizon
        .updateResource(theme.styles.easy.core.backgroundImage, e.detail)
        .then(res => {
          if (!res) alert('Could not update file resource!') // TODO: Make pretty error
          // HACK: Force reload of background image
          if (theme.styles.easy.core.backgroundImage?.endsWith('?')) {
            theme.styles.easy.core.backgroundImage =
              theme.styles.easy.core.backgroundImage.slice(0, -1)
          } else {
            theme.styles.easy.core.backgroundImage += '?'
          }
          applyStyles(theme.styles.easy, customCSSValue)
        })
    }
  }

  function onbackgroundFileRemove(e: CustomEvent) {
    theme.styles.easy.core.backgroundImage = null
  }

  const debouncedUpdateHorizon = useCancelableDebounce((theme_css: string) => {
    horizon.updateHorizon({
      theme_css: theme_css,
    })
  }, 500)

  // clear debounce on horizon change to prevent stale updates
  horizon.id.subscribe(() => {
    debouncedUpdateHorizon.cancel()
  })

  function applyStyles(styles: ThemeStylingConfig, custom: string) {
    const outEasy = buildEasyStyling(styles, api)
    const outCustom = buildCustomStyling(custom)
    // $theme_css = out
    theme.styles.custom = customCSSValue // TODO: merge values?
    theme.css_easy = outEasy
    theme.css_custom = outCustom
    // TODO: debounce change

    const themeString = JSON.stringify(theme)
    theme_css.set(themeString)

    if (horizon.readOnly) {
      horizonReadOnlyEditEvent.fire()
    } else {
      debouncedUpdateHorizon.execute(themeString)
    }
  }
</script>

<svelte:component this={$isMobile ? RawCardMobile : RawCard} card={get(card)}>
  <svelte:fragment slot="content">
    <div class="h-full flex flex-col">
      <!-- <div class="w-full px-3 py-2 stripes border-b-4 border-limeno-light">
        <div class="p-1 bg-seashell-700/80 w-fit rounded-lg">
          <Text size="lg" weight="medium" className="text-seashell-100">Theme Editor</Text>
        </div>
      </div> -->

      <div class="h-full overflow-y-hidden p-3 flex flex-col tela-ignore">
        <Tabs tabs={['Easy Mode', 'Custom CSS']} let:active>
          {#if active === 'Easy Mode'}
            <div
              class="simple px-2 space-y-6 overflow-y-auto overflow-x-hidden"
            >
              <!--
                - Horizon
                - Text
                - Cards
                - Galactic Island
              -->
              <!-- General -->

              {#if !HORIZON_ONLY}
                <div
                  class="mt-3 px-3 py-2 rounded-lg flex items-center gap-2"
                  style="background-color: rgba(
                    var(--lightness-muted),
                    var(--lightness-muted),
                    var(--lightness-muted),
                    0.05
                  ); color: var(--color-text);"
                >
                  <p class="text-sm">💅</p>
                  <p
                    class="text-sm"
                  >
                    Creating a cool theme? <Link href="https://team.deta.page/dueling-horizons" target="_blank">Share it and win a prize!</Link>
                  </p>
                </div>
              {/if}

              <div class="flex flex-col space-y-3">
                <div class="flex items-center gap-3 -mb-1.5">
                  <span
                    class="text-sm font-medium"
                    style="color: var(--color-text);"
                  >
                    General
                  </span>
                  <hr class="flex-1 mt-0.5 border-seashell-400" />
                </div>
                <StyleOption label="Background">
                  <div class="flex items-center gap-3">
                    <FilePicker
                      value={theme.styles.easy.core.backgroundImage
                        ? api.getResourceUrl(
                            theme.styles.easy.core.backgroundImage
                          )?.href
                        : null}
                      on:fileSelect={onBackgroundFileSelect}
                      on:fileRemove={onbackgroundFileRemove}
                    />
                    <ColorPickerGroup
                      bind:lightValue={theme.styles.easy.core.background.light}
                      lightFallback={'#ffffff'}
                      bind:darkValue={theme.styles.easy.core.background.dark}
                      darkFallback={'#121212'}
                    />
                  </div>
                </StyleOption>

                <StyleOption label="Tint">
                  <ColorPickerGroup
                    bind:lightValue={theme.styles.easy.core.tint.light}
                    lightFallback={'#F8F0FF'}
                    bind:darkValue={theme.styles.easy.core.tint.dark}
                    darkFallback={'#38323E'}
                  />
                </StyleOption>

                <StyleOption label="Selection">
                  <ColorPickerGroup
                    bind:lightValue={theme.styles.easy.core.selection.light}
                    lightFallback={theme.styles.easy.core.tint.light}
                    bind:darkValue={theme.styles.easy.core.selection.dark}
                    darkFallback={theme.styles.easy.core.tint.dark}
                  />
                </StyleOption>

                <div class="option">
                  <StyleOption label="Border Radius">
                    <p class="font-mono">
                      {theme.styles.easy.core.borderRadius}px
                    </p>
                  </StyleOption>

                  <Slider
                    bind:value={theme.styles.easy.core.borderRadius}
                    max={30}
                  />
                </div>

                <div class="option">
                  <StyleOption label="Glass">
                    <p
                      class="font-mono text-seashell-500 dark:text-seashell-400"
                    >
                      {theme.styles.easy.core.glass}%
                    </p>
                  </StyleOption>

                  <Slider
                    bind:value={theme.styles.easy.core.glass}
                    max={100}
                    step={5}
                  />
                </div>

                <div class="option">
                  <StyleOption label="Shadow">
                    <p
                      class="font-mono text-seashell-500 dark:text-seashell-400"
                    >
                      {theme.styles.easy.core.shadow}
                    </p>
                  </StyleOption>

                  <Slider bind:value={shadowValue} max={100} step={25} />
                </div>
              </div>

              <!-- Text -->
              <div class="flex flex-col space-y-3">
                <div class="flex items-center gap-3 -mb-1.5">
                  <span class="text-sm font-medium" style="color: var(--color-text);">Text</span
                  >
                  <hr class="flex-1 mt-0.5 border-seashell-400" />
                </div>
                <StyleOption label="Color">
                  <ColorPickerGroup
                    bind:lightValue={theme.styles.easy.text.color.light}
                    lightFallback={theme.styles.easy.core.tint.light}
                    bind:darkValue={theme.styles.easy.text.color.dark}
                    darkFallback={theme.styles.easy.core.tint.dark}
                  />
                </StyleOption>

                <StyleOption label="Selection">
                  <ColorPickerGroup
                    bind:lightValue={theme.styles.easy.text.selection.light}
                    lightFallback={theme.styles.easy.core.tint.light}
                    bind:darkValue={theme.styles.easy.text.selection.dark}
                    darkFallback={theme.styles.easy.core.tint.dark}
                  />
                </StyleOption>

                <StyleOption label="Font">
                  <FontSelector bind:selected={theme.styles.easy.text.font} />
                </StyleOption>
              </div>

              <!-- Cards -->
              <div class="flex flex-col space-y-3">
                <div class="flex items-center gap-3 -mb-1.5">
                  <span
                    class="text-sm font-medium"
                    style="color: var(--color-text);"
                  >
                    Cards
                  </span>
                  <hr class="flex-1 mt-0.5 border-seashell-400" />
                </div>
                <StyleOption label="Background">
                  <ColorPickerGroup
                    bind:lightValue={theme.styles.easy.cards.background.light}
                    lightFallback={theme.styles.easy.core.tint.light}
                    bind:darkValue={theme.styles.easy.cards.background.dark}
                    darkFallback={theme.styles.easy.core.tint.dark}
                  />
                </StyleOption>

                <StyleOption label="Border">
                  <ColorPickerGroup
                    bind:lightValue={theme.styles.easy.cards.border.light}
                    lightFallback={theme.styles.easy.core.tint.light}
                    bind:darkValue={theme.styles.easy.cards.border.dark}
                    darkFallback={theme.styles.easy.core.tint.dark}
                  />
                </StyleOption>

                <StyleOption label="Focus Border">
                  <ColorPickerGroup
                    bind:lightValue={theme.styles.easy.cards.focusBorder.light}
                    lightFallback={theme.styles.easy.core.tint.light}
                    bind:darkValue={theme.styles.easy.cards.focusBorder.dark}
                    darkFallback={theme.styles.easy.core.tint.dark}
                  />
                </StyleOption>
              </div>

              <!-- Galactic Island -->
              <div class="flex flex-col space-y-3">
                <div class="flex items-center gap-3 -mb-1.5">
                  <span
                    class="text-sm font-medium"
                    style="color: var(--color-text);"
                  >
                    Galactic Island
                  </span>
                  <hr class="flex-1 mt-0.5 border-seashell-400" />
                </div>
                <StyleOption label="Background">
                  <ColorPickerGroup
                    bind:lightValue={theme.styles.easy.galacticIsland.background
                      .light}
                    lightFallback={theme.styles.easy.core.tint.light}
                    bind:darkValue={theme.styles.easy.galacticIsland.background
                      .dark}
                    darkFallback={theme.styles.easy.core.tint.dark}
                  />
                </StyleOption>
              </div>
            </div>
          {:else if active === 'Custom CSS'}
            <CssEditor
              bind:code={customCSSValue}
              on:change={() => applyStyles(theme.styles.easy, customCSSValue)}
            />
          {/if}
        </Tabs>
      </div>
      <!-- <div class="cssEditor" bind:this={editorEl} /> -->
    </div>
  </svelte:fragment>
</svelte:component>

<style>
  /* .stripes {
        background: repeating-linear-gradient(
            45deg,
            theme('colors.seashell.700'),
            theme('colors.seashell.700') 20px,
            theme('colors.limeno.light') 20px,
            theme('colors.limeno.light') 30px
        );
    } */

  .option p {
    color: var(--color-text-muted);
  }
</style>
