<svg viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M17.5001 0C27.165 0 35 7.83502 35 17.5C35 27.165 27.165 35 17.5001 35C7.83502 35 0 27.165 0 17.5C0 7.83502 7.83502 0 17.5001 0Z"
    fill="#F73B95"
  />
  <path
    d="M17.5417 3.41797C25.3657 3.41797 31.7083 9.7606 31.7083 17.5847C31.7083 25.4087 25.3657 31.7513 17.5417 31.7513C9.71767 31.7513 3.375 25.4087 3.375 17.5847C3.375 9.7606 9.71767 3.41797 17.5417 3.41797Z"
    fill="#BD399C"
  />
  <path
    d="M17.5416 7.16797C23.2945 7.16797 27.9582 11.8316 27.9582 17.5847C27.9582 23.3377 23.2945 28.0013 17.5416 28.0013C11.7886 28.0013 7.125 23.3377 7.125 17.5847C7.125 11.8316 11.7886 7.16797 17.5416 7.16797Z"
    fill="#93388E"
  />
  <path
    d="M17.4583 10.332C21.3702 10.332 24.5416 13.5033 24.5416 17.4153C24.5416 21.3272 21.3702 24.4986 17.4583 24.4986C13.5464 24.4986 10.375 21.3272 10.375 17.4153C10.375 13.5033 13.5464 10.332 17.4583 10.332Z"
    fill="#6030A2"
  />
</svg>
