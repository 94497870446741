const Index = () => import('@/pages/index.svelte')
const BuilderIndex = () => import('@/pages/builder/index.svelte')
const BuilderNew = () => import('@/pages/builder/new.svelte')
const CollectionIndex = () => import('@/pages/collections/index.svelte')
const Collection = () => import('@/pages/collections/collection.svelte')
const Discovery = () => import('@/pages/discovery/index.svelte')
const DiscoveryRelease = () => import('@/pages/discovery/release.svelte')
const Credits = () => import('@/pages/legal/credits.svelte')
const Success = () => import('@/pages/common/success.svelte')
const Invocation = () => import('@/pages/invocations/invocation.svelte')
const ExperimentalSearch = () => import('@/pages/experimental/search.svelte')
import PageLayout from '@/layouts/page.svelte'
import CollectionLayout from '@/layouts/collection.svelte'

import type { Routes } from '@/router'

export enum RouteNames {
  Index = 'Index',
  Builder = 'Builder',
  BuilderNew = 'BuilderNew',
  BuilderApp = 'BuilderApp',
  BuilderAppDevelop = 'BuilderAppDevelop',
  BuilderAppPublish = 'BuilderAppPublish',
  BuilderAppPublishCreate = 'BuilderAppPublishCreate',
  CollectionsApp = 'CollectionsApp',
  CollectionsItem = 'CollectionsItem',
  Discovery = 'Discovery',
  DiscoveryApp = 'DiscoveryApp',
  DiscoveryRelease = 'DiscoveryRelease',
  DiscoveryReleaseCanonical = 'DiscoveryReleaseCanonical',
  DiscoveryLegacyRelease = 'DiscoveryLegacyRelease',
  Invocation = 'Invocation',
  Credits = 'Credits',
  Success = 'Success',
  ExperimentalSearch = 'GlobalSearch',
}

export const routes: Routes = [
  {
    name: RouteNames.Index,
    path: '/',
    component: Index,
  },
  {
    name: RouteNames.Builder,
    path: '/builder',
    component: BuilderIndex,
    layout: PageLayout,
  },
  {
    name: RouteNames.BuilderNew,
    path: '/builder/new',
    component: BuilderNew,
    layout: PageLayout,
  },
  {
    name: RouteNames.BuilderApp,
    path: '/builder/:appId',
  },
  {
    name: RouteNames.BuilderAppDevelop,
    path: '/builder/:appId/develop',
  },
  {
    name: RouteNames.BuilderAppPublish,
    path: '/builder/:appId/publish',
  },
  {
    name: RouteNames.BuilderAppPublishCreate,
    path: '/builder/:appId/publish/create',
  },
  {
    name: RouteNames.CollectionsApp,
    path: '/collections',
    component: CollectionIndex,
    layout: PageLayout,
  },
  {
    name: RouteNames.CollectionsItem,
    path: '/collections/:id',
    component: Collection,
    layout: CollectionLayout,
  },
  {
    name: RouteNames.Discovery,
    path: '/discovery',
    component: Discovery,
  },
  {
    name: RouteNames.DiscoveryApp,
    path: '/discovery/:scope/:alias',
    component: DiscoveryRelease,
  },
  {
    name: RouteNames.DiscoveryRelease,
    path: '/discovery/:scope/:alias/:version',
    component: DiscoveryRelease,
  },
  {
    name: RouteNames.DiscoveryLegacyRelease,
    path: '/discovery/:releaseId',
    component: DiscoveryRelease,
  },
  {
    name: RouteNames.DiscoveryReleaseCanonical,
    path: '/discovery/r/:releaseId',
    component: DiscoveryRelease,
  },
  {
    name: RouteNames.Invocation,
    path: '/invocations/:id',
    component: Invocation,
  },
  {
    name: RouteNames.Credits,
    path: '/credits',
    component: Credits,
    layout: PageLayout,
  },
  {
    name: RouteNames.Success,
    path: '/success',
    component: Success,
    layout: PageLayout,
  },
  {
    name: RouteNames.ExperimentalSearch,
    path: '/exp/search',
    component: ExperimentalSearch,
  },
]
