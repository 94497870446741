import { getContext, setContext } from 'svelte'
import { Writable, writable } from 'svelte/store'

import type { API } from '@/api'
import { HORIZON_ONLY } from './env'

export class Metadata {
  api: API
  isAuthenticated: Writable<boolean>
  username: Writable<string | null>
  appOnboarded: Writable<boolean>
  ttyOnboarded: Writable<boolean>
  isDevMode: Writable<boolean>
  isInsiderMode: Writable<boolean>
  horizonLimit: Writable<number>
  isLoading: Writable<boolean>

  constructor(api: API) {
    this.api = api
    this.isAuthenticated = writable(false)
    this.username = writable(null)
    this.appOnboarded = writable(false)
    this.ttyOnboarded = writable(false)
    this.isDevMode = writable(false)
    this.isInsiderMode = writable(false)
    this.horizonLimit = writable(3)
    this.isLoading = writable(true)

    // if not manually bound "this" is undefined inside the methods (because JavaScript?...)
    this.setAppOnboarded = this.setAppOnboarded.bind(this)
    this.setTTYOnboarded = this.setTTYOnboarded.bind(this)
    this.setDevMode = this.setDevMode.bind(this)
    this.revalidate = this.revalidate.bind(this)

    void this.revalidate()
  }

  async revalidate() {
    try {
      const metadata = await this.api.getMetadata()
      if (metadata.onboarded) {
        this.appOnboarded.set(true)
      }

      if (metadata.onboarded_tt) {
        this.ttyOnboarded.set(true)
      }

      if (metadata.dev_mode) {
        this.isDevMode.set(true)
      }

      if (metadata.insider_mode) {
        this.isInsiderMode.set(true)
      }

      if (typeof metadata.horizon_limit === 'number') {
        this.horizonLimit.set(metadata.horizon_limit as number)
      }

      if (metadata.username) {
        this.username.set(metadata.username)
      }

      this.isAuthenticated.set(true)
    } catch (_err) {
      this.isAuthenticated.set(false)
    } finally {
      // For discovery routes always assume onboarded
      const pathname = window.location.pathname
      if (pathname.startsWith('/discovery') || HORIZON_ONLY) {
        this.appOnboarded.set(true)
        this.ttyOnboarded.set(true)
      }

      this.isLoading.set(false)
    }
  }

  async setAppOnboarded() {
    await this.api.setMetadata({ onboarded: true })
    this.appOnboarded.set(true)
  }

  async setTTYOnboarded() {
    await this.api.setMetadata({ onboarded_tt: true })
    this.ttyOnboarded.set(true)
  }

  async setDevMode() {
    await this.api.setMetadata({ dev_mode: true })
    this.isDevMode.set(true)
  }

  static provide = (api: API) => {
    const metadata = new Metadata(api)
    setContext('metadata', metadata)

    return metadata
  }

  static use = (): Metadata => {
    return getContext('metadata')
  }
}

export const provideMetadata = Metadata.provide
export const useMetadata = Metadata.use
