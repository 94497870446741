import { get } from 'svelte/store'

import { themePreference, setTheme } from '@deta/ui/src'
import type { TeletypeSystem } from '@deta/teletype'

export const switchToLightThemeAction = (active: boolean) => ({
  id: 'theme-light',
  name: 'Light',
  icon: 'SUN',
  ...(active ? { tag: 'current', tagStatus: 'active' } : {}),
  handler: () => {
    setTheme('light')
    return {
      afterClose(teletype: TeletypeSystem) {
        teletype.showSuccess('Theme changed')
      },
    }
  },
})

export const switchToDarkThemeAction = (active: boolean) => ({
  id: 'theme-dark',
  name: 'Dark',
  icon: 'MOON',
  ...(active ? { tag: 'current', tagStatus: 'active' } : {}),
  handler: () => {
    setTheme('dark')
    return {
      afterClose(teletype: TeletypeSystem) {
        teletype.showSuccess('Theme changed')
      },
    }
  },
})

export const switchToSystemThemeAction = (active: boolean) => ({
  id: 'theme-system',
  name: 'Automatic (System)',
  icon: 'DESKTOP',
  ...(active ? { tag: 'current', tagStatus: 'active' } : {}),
  handler: () => {
    setTheme('system')
    return {
      afterClose(teletype: TeletypeSystem) {
        teletype.showSuccess('Theme changed')
      },
    }
  },
})

export const changeThemeAction = {
  id: 'theme',
  name: 'General Appearance',
  icon: 'COLOR_SWATCH',
  nestedSearch: true,
  keywords: ['theme', 'appearance', 'dark', 'light', 'system'],
  actionText: 'Select theme',
  actionPanel: [
    switchToLightThemeAction(false),
    switchToDarkThemeAction(false),
    switchToSystemThemeAction(false),
  ],
  async loadChildActions() {
    const theme = get(themePreference)
    return [
      switchToLightThemeAction(theme === 'light'),
      switchToDarkThemeAction(theme === 'dark'),
      switchToSystemThemeAction(theme === 'system'),
    ]
  },
}
