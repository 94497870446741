<script lang="ts">
  import { TeletypeProvider, Teletype } from '@deta/teletype'
  import { ThemeProvider } from '@deta/ui'
  import Icon from '@/teletype/Icon.svelte'

  import { provideAPI } from '@/api'
  const api = provideAPI(import.meta.env.VITE_API_ENDPOINT)

  import Index from '@/pages/index.svelte'
  import NotFound from '@/pages/404.svelte'

  import { routes as builderRoutes } from '@/router/builder'
  import { routes } from '@/router/routes'

  import { provideRouter } from '@/router'
  const router = provideRouter([...routes, ...builderRoutes])

  import { provideStore } from '@/store'
  provideStore(api)

  import { provideMetadata } from '@/utils/metadata'
  const metadata = provideMetadata(api)
  const { isAuthenticated, appOnboarded, isLoading } = metadata

  import { useNotFound } from '@/store/error'
  const { showNotFound } = useNotFound()

  import { useDefaultActions } from '@/teletype'
  import { useCommonContext } from './teletype/utils'
  import { onMount } from 'svelte'
  import { Route, Router } from 'svelte-navigator'
  const actionContext = useCommonContext({ api, router, metadata })
  const defaultActions = useDefaultActions(actionContext)

  // when we have the metadata we can refresh the default teletype actions depending on the set flags
  let teletype: any
  $: if (!$isLoading) {
    teletype.defaultActions = teletype.flattenActions(
      useDefaultActions(actionContext)
    )
    teletype.actions.set(teletype.defaultActions)
  }

  // prevent tty from flashing on the desktop canvas/horizon
  onMount(() => {
    document.body.classList.add('hide-tty')
  })
</script>

<svelte:head>
  <script defer data-domain="deta.page" src="https://plausible.io/js/script.js"></script>
</svelte:head>

<ThemeProvider>
  <Router primary={false}>
    <TeletypeProvider
      bind:teletype
      actions={defaultActions}
      options={{ iconComponent: Icon, showHelper: true }}
      let:show={showTeletype}
    >
      <!-- {#if showTeletype}
        <Teletype />
      {/if} -->

      {#if $showNotFound}
        <Route component={NotFound} />
      {:else}
        <Route path="/:slug" component={Index} />
        <Route component={Index} />
      {/if}
    </TeletypeProvider>
  </Router>
</ThemeProvider>
