<script lang="ts">
  import '../canvas.scss'
  const Canvas = () => import('@/components/Canvas/Canvas.svelte')
  const MobileCanvas = () => import('@/components/Canvas/MobileCanvas.svelte')

  import { onDestroy, onMount, tick } from 'svelte'
  import { derived, get, writable } from 'svelte/store'

  import { Skeleton } from '@deta/ui/src'
  import { type IBoard, clamp, createBoard, createSettings, LazyComponent } from '@deta/tela'

  import { setSearchParam, useBodyClass, useDocumentClass, useSearchParams } from '@/utils/mount'
  import { useMetadata } from '@/utils/metadata'
  import { DEFAULT_HORIZON_STORAGE_KEY, ROOT_HORIZON_SLUG, provideHorizon } from '@/service/horizon'
  import { useStore } from '@/store'
  import { useAPI } from '@/api'
  import { useLocation } from 'svelte-navigator'
  import { HORIZON_ONLY } from '@/utils/env'
  import { type TeletypeSystem, useTeletype } from '@deta/teletype/src'
  import { APIError, getErrorMessage } from '@/utils/errors'
  import { useHomeActions } from '@/teletype'
  import { useRouter } from '@/router'
  import { sanitizeHorizonTheme } from '@/utils/sanitize-css'
  import type { App, Instance } from '@deta/types'
  import { generateCardActions, reachedHorizonLimitAction } from '@/components/Canvas/Cards/actions'
  import { buildEasyStyling, defaultTheme } from '@/components/Canvas/Cards/ThemeEditor/ThemeEditorCard.svelte'
  import { useNotFound } from '@/store/error'
  import { setValue } from '@/utils/localstorage'
  import { provideInstanceEmbeds } from '@/service/embeds'

  export let slug: string | undefined = undefined

  useDocumentClass('horizon-page')
  useBodyClass('hide-tty')

  const teletype = useTeletype()
  const api = useAPI()
  const store = useStore()
  const router = useRouter()
  const notFound = useNotFound()
  const metadata = useMetadata()  
  const { isDevMode, isInsiderMode, horizonLimit } = metadata

  let componentRef: HTMLElement
  const location = useLocation();

  $: isRouteUnmountEdgeCase = $location.pathname !== '/' && !HORIZON_ONLY
  $: if (isRouteUnmountEdgeCase) {
    console.warn('[Horizon]: route unmount edge case detected, using workaround')
    componentRef.parentNode?.removeChild(componentRef)
  }

  provideInstanceEmbeds(api)

  const settings = createSettings({
    CAN_PAN: true,
    CAN_DRAW: true,
    CAN_ZOOM: false,
    CAN_SELECT: true,
    PAN_DIRECTION: 'x',
    SNAP_TO_GRID: true,
    GRID_SIZE: 30,
    BOUNDS: {
      minX: 0,
      minY: 0,
      maxX: 1920 * 7,
      maxY: 1080,
      maxZoom: 1,
      minZoom: 1, // todo?: Do we need to make these dynamic?
      limit: "hard"
    },
    CHUNK_WIDTH: 1920 / 4, // Should be divisible by GRID_SIZE
    CHUNK_HEIGHT: 1080 / 3, // Should be divisible by GRID_SIZE
    POSITIONABLE_KEY: 'id'
  })

  const board: IBoard<any, any> = createBoard(
    settings,
    writable([]),
    // {
    //   viewOffset: {
    //     x: 0, //(xQueryParam === null ? undefined : xQueryParam) ||
    //     y: 0, //$canvasRecord.view_offset_y || 0,
    //   },
    //   zoom: clamp(window.innerHeight / 1080, 1, Infinity),
    //   mode: 'draw',
    // },
    // settings
    {}, "idle", {}
  )

  const state = board.state
  const viewOffset = $state.viewOffset;

  let horizonIDParam = ''

  if (!HORIZON_ONLY) {
    useSearchParams<{ horizon: string }>(params => {
      const { horizon: horizonParam } = params
      if (horizonParam) {
        horizonIDParam = horizonParam
      }
    })
  } else {
    horizonIDParam = slug || ''
  }

  const horizon = provideHorizon(horizonIDParam, api, board)

  const horizons = horizon.horizons
  const isMobile = horizon.isMobile
  const isDefault = horizon.isDefault
  const isLoadingHorizons = horizon.isLoadingHorizons
  const activeHorizon = horizon.id
  const horizonName = horizon.name

  const theme_css = horizon.theme_css
  const theme_css_built = derived([theme_css, activeHorizon], ([theme_css, _]) => {
    try {
      if (!theme_css) {
        const theme = { ...defaultTheme }
        return {
          easy: buildEasyStyling(theme.styles.easy, api, true),
          custom: "",
        }
      }

      return {
        easy: JSON.parse(theme_css || '{"css": ""}').css_easy || "",
        custom: sanitizeHorizonTheme(JSON.parse(theme_css || '{"css": ""}').css_custom || "").css,
      }
    } catch (err) {
      return {
        easy: "",
        custom: "",
      }
    }
  })

  let loaded = false

  let instances: Instance[] = []
  let apps: App[] = []

  $: {
    if (!HORIZON_ONLY && apps && instances) {
      teletype.useActions([
        {
          id: 'card-create',
          name: 'Add Card to Horizon',
          icon: 'GRID_PLUS',
          actionText: 'Create Card',
          childActions: generateCardActions(api, horizon, apps, instances, () => ({
            x: $viewOffset.x + window.innerWidth / 2 - 360,
            y: 30,
            width: 360,
            height: 300,
          }), $isDevMode, $isInsiderMode)
        },
        {
          id: 'create-horizon',
          name: 'Create new Horizon',
          icon: 'PLUS',
          handler: async () => {
            if ($horizons.length >= $horizonLimit) {
              return {
                afterClose(teletype) {
                  teletype.showAction(reachedHorizonLimitAction)
                },
              }
            }
            const newHorizon = await horizon.createHorizon()
            horizon.id.set(newHorizon.id)
          }
        },
        {
          id: 'horizon-sharing-settings',
          name: 'Share Horizon',
          icon: 'WORLD',
          footerText: 'Sharing Settings',
          // view: 'ModalSmall',
          /* @ts-ignore */
          lazyComponent: () => import('@/components/Canvas/SharingSettings.svelte'),
          componentProps: {
            horizon,
          }
        },
        ...($isDefault ? [] : [{
          id: 'delete-horizon',
          name: 'Delete Horizon',
          icon: 'trash',
          view: 'ModalSmall',
          actionText: 'Confirm',
          breadcrumb: 'Delete Horizon',
          childActions: [
            {
              id: 'delete-horizon-confirm',
              name: 'Confirm',
              handler: async () => {
                if (get(horizon.isDefault)) {
                  teletype.showError('You cannot delete your default horizon.')
                  return
                }
                await horizon.deleteHorizon()

                horizon.horizons.update(horizons => {
                    const newHorizons = horizons.filter(h => h.id !== horizon.getID())
                    return newHorizons
                })

                const defaultHorizon = get(horizon.horizons).find(h => h.default)
                horizon.id.set(defaultHorizon?.id || '')

                return {
                  afterClose(teletype: TeletypeSystem) {
                    teletype.showSuccess('Horizon deleted!')
                  },
                }
              },
            },
            {
              id: 'delete-horizon-cancel',
              name: 'Cancel',
              handler: () => {
                return {
                  afterClose(teletype: TeletypeSystem) {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    teletype.showError('Cancelled!')
                  },
                }
              },
            },
          ],
        }]),
        ...useHomeActions({ api, router, metadata, horizon }, apps, instances),
      ])
    }
  }

  const handleResize = () => {
    const mediaQuery = window.matchMedia('(hover: hover)')
    const supportsHover = mediaQuery.matches

    // const isTouch =
    //   'ontouchstart' in window ||
    //   navigator.maxTouchPoints > 0
    $isMobile = !supportsHover || window.innerWidth < 600
  }

  $: loading = $isLoadingHorizons || !loaded || !horizon || !horizon.id

  onMount(async () => {
    try {
      handleResize()
      window.addEventListener('resize', handleResize)

      await tick()

      if (HORIZON_ONLY) {
        if (slug) {
          await horizon.loadHorizon(slug)
        } else {
          // await horizon.loadHorizon('root')
          await horizon.loadHorizon(ROOT_HORIZON_SLUG)
        }
      } else {
        const horizons = await horizon.loadHorizons()

        const getDefaultHorizonId = () => {
          const defaultHorizon = horizons?.find(v => v.default) || horizons?.[0]
          if (defaultHorizon) {
            return defaultHorizon.id
          } else {
            return undefined
          }
        }

        let activeHorizonId: string | undefined = undefined
        if (horizonIDParam) {
          const horizonIdx = horizons?.findIndex(v => v.id === horizonIDParam)
          if (horizonIdx === -1) {
            console.warn(`No horizon found with ID "${horizonIDParam}", using default horizon instead`)

            activeHorizonId = getDefaultHorizonId()
          } else {
            activeHorizonId = horizonIDParam
          }
        } else {
          activeHorizonId = getDefaultHorizonId()
        }

        if (!activeHorizonId) {
          console.error('No horizon found')
          teletype.showError('Could not find a Horizon')
          return
        }

        // store default horizon id so we don't need to fetch it on other pages, see App.svelte
        try {
          setValue(DEFAULT_HORIZON_STORAGE_KEY, getDefaultHorizonId() ?? activeHorizonId)
        } catch (_e) {
          //console.error(e)
        }

        horizon.id.set(activeHorizonId)
        await horizon.loadHorizon()

        await tick()

        let horizonId = horizon.getID()
        horizon.id.subscribe((id) => {
          setSearchParam('horizon', id)

          if (id !== horizonId) {
            void horizon.loadHorizon()
            horizonId = id
          }
        })

        const { data: appsData } = await api.getApps()
        const { data: instancesData } = await api.getAppInstances({ per_page: 1000 })
        apps = appsData
        instances = instancesData
      }
    } catch (err) {
      if (err instanceof APIError) {
        if (err.status === 401 && HORIZON_ONLY) {
          if (!slug) {
            window.location.href = 'https://deta.space'
            return
          } else {
            notFound.show()
            return
          }
        }
      }

      teletype.showError(getErrorMessage(err))
    } finally {
      loaded = true
    }
  })

  onDestroy(() => {
    window.removeEventListener('resize', handleResize)
  })

  // $: notOnboarded = !$isLoadingMetadata && $isAuthenticated && !$appOnboarded

  // $: if (!$isLoading && notOnboarded) {
  //   canvas.createCard.text({
  //     x: 100,
  //     y: 100,
  //     width: 200,
  //     height: 200,
  //     data: {
  //       content: 'autogen',
  //     },
  //   })
  // }
</script>

<svelte:head>
  <title>{(loading || !$horizonName) ? 'Deta Space' : `${$horizonName} - Deta Space`}</title>
  {@html `<${''}style data-horizon-theme-style>${$theme_css_built.easy} ${$theme_css_built.custom}</${''}style>`}
</svelte:head>

<main bind:this={componentRef} class:loading={loading} class:horizon-only={HORIZON_ONLY}>
  {#if !loading}
    {#if $isMobile}
    <LazyComponent this={MobileCanvas}>
        <svelte:fragment slot="component" let:Component>
          <Component horizon={horizon} {settings} />
        </svelte:fragment>
      </LazyComponent>
    {:else}
      <LazyComponent this={Canvas}>
        <svelte:fragment slot="component" let:Component>
          <Component horizon={horizon} {board} {settings} {horizons} apps={apps} instances={instances} />
        </svelte:fragment>
      </LazyComponent>
    {/if}
  {:else}
    <Skeleton />
  {/if}
</main>

<style>
  main {
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    max-height: 100vh;
  }
  :global(main.loading > div) {
    width: 100vw;
    height: 100vh;
    border-radius: 0;
  }
</style>
