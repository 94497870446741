import { Unsubscriber, Writable, get, writable } from 'svelte/store'

export interface ITask {
  id: string
  title: Writable<string>
  visible: boolean
  done: () => void
}

export interface IProgressTask {
  task: ITask
  current: number
  total: number
  increment: (n?: number) => void
  done: () => void
}

const pendingTasks = writable<ITask[]>([])
const onTaskChanged = writable<ITask>()
const onNotiyTaskAdded = writable<ITask>()

export const useStatusIndicator = () => {
  const removeTask = (id: string) => {
    pendingTasks.update(n => n.filter(t => t.id !== id))
  }

  const addTask = (title: string, visible = true): ITask => {
    const taskTitle = (() => { // TODO: possible memory optimization possible
      const w = writable(title)
      return {
        subscribe: w.subscribe,
        set: (v: string) => {
          w.set(v)
          pendingTasks.set(get(pendingTasks))
        },
        update: (fn: (v: string) => string) => {
          w.update(fn)
          pendingTasks.set(get(pendingTasks))
        },
      }
    })()
    const id = crypto.randomUUID()
    const task = { id, title: taskTitle, visible, done: () => removeTask(id) }
    pendingTasks.update(n => [...n, task])
    if (visible) onNotiyTaskAdded.set(task)
    return task
  }

  const addProgressTask = (total: number, title: (curr: number, total: number) => string, visible = true): IProgressTask => {
    const t = addTask(title(0, total), visible)
    const p = writable(0)
    let unsubscribe: Unsubscriber
    unsubscribe = p.subscribe(v => {
      t.title.set(title(v, total))
      if (v >= total) {
        unsubscribe()
        removeTask(t.id)
      }
    })
    return {
      task: t,
      current: 0,
      total,
      increment: (n = 1) => p.update(v => v + n),
      done: () => removeTask(t.id)
    }
  }

  return {
    subscribe: pendingTasks.subscribe,
    // pendingTasks, // todo make readonly

    /**
     * Add a task to the status indicator
     * @param title Display title
     * @param notifyChange Whether to notify subscribers of the change to display the new task
     * @returns
     */
    addTask,
    addProgressTask,
    removeTask,

    // Events
    onTaskChanged,
    onNotiyTaskAdded
  }
}
