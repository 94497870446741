import * as csstree from 'css-tree'
import { is } from 'date-fns/locale'

/**
 * We remove the following selectors:
 * - html
 * - body
 * - *
 * - main
 * - .light, .dark
 * - .horizon-page .hide-tty
 * - #app // TODO: issue with main using random class from svelte css -> We need to make this distinct!
 * @param css
 * @returns
 */
export function sanitizeHorizonTheme(css: string) {
  const SELECTOR_BLACKLIST = [
    'root',
    'html',
    'body',
    '*',
    'main',
    '.light',
    '.dark',
    '.horizon-page',
    '.hide-tty',
    '#app',
  ]
  let errors: csstree.SyntaxParseError[] = []

  const ast = csstree.parse(css, {
    onParseError(error) {
      error = {
        name: error.name,
        message: error.message,
        line: error.line,
        column: error.column,
        offset: error.offset,
      }

      errors.push(JSON.stringify(error, null, 2))
    },
  })


  // function isBlacklisted(nodes: csstree.List<csstree.CssNode>) {
  //   //console.log(nodes.data.children.head.data.name)
  //   console.log("Check Node", nodes)
  //   if (nodes.data?.name) {
  //     console.log('Name', nodes.data.name)
  //     found.push(nodes.data.name)
  //   }
  //   if (nodes.data.children) {
  //     console.log('Name', nodes.data.children.head.data.name)
  //     found.push(nodes.data.children.head.data.name)
  //     if (nodes.data.children.head.next) {
  //       isBlacklisted(nodes.data.children.head.next)
  //     }
  //   }
  //     if (nodes.next) {
  //       isBlacklisted(nodes.next)
  //     }
  //   // for (let node in nodes.head) {
  //   //   console.log("node", node)
  //   // }
  // }

  // csstree.walk(ast, {
  //   visit: "Selector",
  //   enter: function (node, item, list) {
  //     console.log("Enter: ", node, item, list)
  //     isBlacklisted(list.head)
  //     console.log("Found: ", found)
  //   }
  // })

  csstree.walk(ast, {
    visit: 'Rule',
    enter: function (node, item, list) {
      if (node.prelude.type !== 'SelectorList') return

      node.prelude.children.forEach(selector => {
        let i = 0;
        selector.children.forEach((children) => {
          // if (
          //   children.type !== 'TypeSelector' &&
          //   children.type !== 'PseudoClassSelector'
          // )
          //   return

          // TODO: Alternative remove all selectors, if first selector !== .horizon
          // TODO: Use if users find too many bypasses

          // Remove top level pseudo & identifiers
          if (
            (i === 0 &&
              (children.type === 'PseudoClassSelector' ||
                children.type === '"AttributeSelector"')) ||
            SELECTOR_BLACKLIST.includes(children.name)
          ) {
            list.remove(item)
          }

          //   console.log("CNAME", children, i)
          // if (SELECTOR_BLACKLIST.includes(children.name)) {
          //   list.remove(item)
          // }
          i++;
        })
      })
    },
  })

  // Only allow resource URLs
  csstree.walk(ast, {
    visit: 'Url',
    enter: function (node) {
      try {
        const url = new URL(node.value)
        const hostname = url.hostname
        const pathname = url.pathname

        const is_valid_hostname =
          ['deta.space', 'staging0.deta.space', 'staging1.deta.space'].includes(
            hostname
          ) || hostname.endsWith('.deta.page')
        const is_valid_pathname = pathname.startsWith(
          '/api/v0/horizons/resource/'
        )

        // TODO: Display warning / error?
        node.value =
          is_valid_hostname && is_valid_pathname ? node.value : ':bonk:'
      } catch {
        node.value = ':bonk:'
      }
    },
  })

  const result = csstree.generate(ast)
  return { css: result, errors: errors }
}