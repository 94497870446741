const Overview = () => import('@/pages/builder/overview/index.svelte')
const Develop = () => import('@/pages/builder/develop/index.svelte')
const Publish = () => import('@/pages/builder/publish/index.svelte')
const PublishCreate = () => import('@/pages/builder/publish/create.svelte')

import type { Routes } from '@/router'

export enum BuilderRouteNames {
  Overview = 'Overview',
  Develop = 'Develop',
  Publish = 'Publish',
  PublishCreate = 'PublishCreate',
}

export const routes: Routes = [
  {
    name: BuilderRouteNames.Overview,
    path: '/',
    component: Overview,
  },
  {
    name: BuilderRouteNames.Develop,
    path: '/develop',
    component: Develop,
  },
  {
    name: BuilderRouteNames.Publish,
    path: '/publish',
    component: Publish,
  },
  {
    name: BuilderRouteNames.PublishCreate,
    path: '/publish/create',
    component: PublishCreate,
  },
]
