export const navigateToTeletypePrototypeAction = {
  id: 'navigate-teletype-prototype',
  name: 'Go to experimental Teletype',
  icon: 'arrow_right',
  section: 'Navigation',
  actionText: 'Navigate',
  handler() {
    window.location.href = '/teletype'
  },
}

export const navigateToDiscoveryAction = {
  id: 'navigate-discovery',
  name: 'Go to Discovery',
  icon: 'arrow_right',
  section: 'Navigation',
  actionText: 'Navigate',
  handler() {
    window.location.href = '/discovery'
  },
}

export const navigateToCanvasAction = {
  id: 'navigate-home',
  name: 'Go to Horizon',
  icon: 'arrow_right',
  keywords: ['home', 'canvas', 'horizon'],
  section: 'Navigation',
  actionText: 'Navigate',
  handler() {
    window.location.href = '/'
  },
}

export const navigateToBuilderAction = {
  id: 'navigate-builder',
  name: 'Go to Builder',
  icon: 'arrow_right',
  section: 'Navigation',
  actionText: 'Navigate',
  handler() {
    window.location.href = '/builder'
  },
}

export const navigateToCollectionsAction = {
  id: 'navigate-collections',
  name: 'Go to Collections',
  icon: 'arrow_right',
  section: 'Navigation',
  actionText: 'Navigate',
  handler() {
    window.location.href = '/collections'
  },
}

export const navigateToDocsAction = {
  id: 'navigate-docs',
  name: 'Go to Docs',
  icon: 'arrow_right',
  section: 'Navigation',
  actionText: 'Navigate',
  handler: () => {
    window.location.href = '/docs'
  },
}

export const navigateToGlobalSearchAction = {
  id: 'navigate-global-search',
  name: 'Go to Search (Experimental)',
  icon: 'arrow_right',
  section: 'Navigation',
  actionText: 'Navigate',
  handler: () => {
    window.location.href = '/exp/search'
  },
}

export const openDocsAction = {
  id: 'open-docs',
  name: 'View Docs',
  section: 'Help',
  icon: 'docs',
  handler: () => {
    window.open('/docs', '_blank')
  },
}

export const contactSupportAction = {
  id: 'contact-support',
  name: 'Contact Support',
  section: 'Help',
  icon: 'support',
  handler: () => {
    window.open('mailto:team@deta.space?subject=Space Alpha', '_blank')
  },
}

export const openChangelogAction = {
  id: 'open-changelog',
  name: 'Whats new?',
  keywords: ['changelog'],
  icon: 'sparkles',
  handler: () => {
    window.open('/changelog', '_blank')
  },
}

export const openBlogAction = {
  id: 'open-blog',
  name: 'Deta Blog',
  icon: 'article',
  handler: () => {
    window.open('/blog', '_blank')
  },
}

export const openJobsPageAction = {
  id: 'open-jobs-page',
  name: 'Work at Deta',
  icon: 'user_plus',
  handler: () => {
    window.open('https://jobs.deta.sh/', '_blank')
  },
}

export const openDiscordAction = {
  id: 'open-discord',
  name: 'Chat on Discord',
  section: 'Help',
  icon: 'discord',
  handler: () => {
    window.open('https://go.deta.dev/discord', '_blank')
  },
}

export const useOpenDevInstanceAction = (url: string) => ({
  id: 'open-dev-instance',
  name: 'Open Builder Instance',
  icon: 'external_link',
  section: 'Current Project',
  handler() {
    window.open(url, '_blank')
  },
})

export const useOpenDevServerAction = (devServer: string) => ({
  id: 'open-local-dev-server',
  name: 'Open Development Server',
  icon: 'terminal',
  section: 'Local Development',
  handler: () => {
    window.open(devServer, '_blank')
  },
})

export const navigateToNewProjectAction = {
  id: 'create-app',
  name: 'Create New Project',
  icon: 'terminal',
  section: 'Your Space',
  handler() {
    window.location.href = '/builder/new'
  },
}
