<script lang="ts">
  import { cn } from '../../utils'
  import type { IText, ITextSize, ITextWeight } from '../types'

  export let as: IText = 'p'

  export let className = ''
  export let font: 'sans' | 'display' | 'mono' = 'sans'
  export let weight: ITextWeight = ''
  export let size: ITextSize = ''

  let textClass = cn(
    font && `font-${font}`,
    weight && `font-${weight}`,
    size && `text-${size}`,
    (as === 'h1' || as === 'h2' || as === 'h3') && !weight && 'font-semibold',
    (as === 'h4' || as === 'h5' || as === 'h6') && !weight && 'font-medium',
    as === 'p' && !size && 'text-base',
    as === 'h1' && !size && 'text-6xl',
    as === 'h2' && !size && 'text-5xl',
    as === 'h3' && !size && 'text-4xl',
    as === 'h4' && !size && 'text-3xl',
    as === 'h5' && !size && 'text-2xl',
    as === 'h6' && !size && 'text-xl',
    as === 'span' && !size && 'inline',
    as === 'small' && !size && 'text-sm',
    as === 'i' && 'italic',
    as === 'mark' && 'bg-yellow-light dark:bg-yellow-dark',
    as === 'b' && 'font-bold',
    as === 'blockquote' && 'text-tertiary',
    !className && 'text-primary',
    className
  )
</script>

<svelte:element this={as} class={textClass}><slot /></svelte:element>
