/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getContext, setContext } from 'svelte'

import type {
  App as IApp,
  Instance as IInstance,
  Installation as IInstallation,
  Release as IRelease,
  Revision as IRevision,
  Promotion as IPromotion,
  PromotionPayload as IPromotionPayload,
  ILegacyCanvas,
  CanvasItem as ICanvasItem,
  CanvasItemType as ICanvasItemType,
  Metadata as IMetadata,
  Token as IToken,
  CreatedToken as ICreatedToken,
  Build as IBuild,
  Space as ISpace,
  Key as IKey,
  Export as IExport,
  CreatedKey as ICreatedKey,
  LogItem as ILogItem,
  Collection as ICollection,
  Base as IBase,
  Drive as IDrive,
  Domain as IDomain,
  LegacyDomain as ILegacyDomain,
  Action as IAction,
  AppAction as IAppAction,
  Invocation as IInvocation,
  ScheduleActionUpdatePayload,
  PromotionDiscoveryData,
  DiscoverySort,
  DiscoveryFilter,
  InstanceEmbed,
} from '@deta/types'

import logger from '@/utils/log'
import { APIError, captureException, NetworkError, parseError } from '@/utils/errors'
import type {
  CardPosition,
  IHorizon,
  TCard,
} from '@/components/Canvas/canvas.types'
import { parse } from 'date-fns'

const HEADER_SPACE_CLIENT_KEY = 'X-Space-Client'

export const ENDPOINTS = {
  apps: '/v0/apps',
  revisions: (appId: string) => `/v0/apps/${appId}/revisions`,
  project_keys: (appId: string) => `/v0/apps/${appId}/keys`,
  promotions: '/v0/promotions',
  releases: '/v0/releases',
  builds: '/v0/builds',
  installations: '/v0/installations',
  instances: '/v0/instances',
  instances_api_keys: (instanceId: string) =>
    `/v0/instances/${instanceId}/api_keys`,
  instances_data_keys: (instanceId: string) =>
    `/v0/instances/${instanceId}/keys`,
  auth: '/v0/auth',
  canvas: '/v0/canvas',
  horizons: '/v0/horizons',
  card: '/v0/horizons/card',
  resource: '/v0/horizons/resource',
  metadata: '/v0/metadata',
  user: '/v0/user',
  tokens: '/v0/tokens',
  discovery: '/v0/discovery',
  space: '/v0/space',
  collections: '/v0/collections',
  actions: '/v0/actions',
  invocations: '/v0/actions/invocations',
  legacy_domains: '/v0/legacy_domains',
  logger: '/logger',
  demo: `/v0/demo`,
}

type PresetChanges = {
  name: string
  presets: {
    env: {
      name: string
      value: string
    }[]
  }
}[]

export interface PaginationRequest {
  // the current page
  page: number
  // the maximum number of items on a page
  per_page: number
}

export interface Pagination extends Partial<PaginationRequest> {
  // the total number of items across all pages
  total?: number
  // wether there is more data to fetch
  end?: boolean
}

export type PaginatedResponse<T> = {
  data: T
  pages: Pagination
}

export type Sorting =
  | 'created_at_asc'
  | 'created_at_desc'
  | 'updated_at_asc'
  | 'updated_at_desc'

export class API {
  baseUrl: string
  captureExceptions: boolean

  constructor(baseUrl?: string, captureExceptions?: boolean) {
    this.baseUrl = baseUrl || `${location.origin}/api`
    this.captureExceptions =
      captureExceptions !== undefined ? captureExceptions : true
  }

  static provide = (endpoint?: string) => {
    const api = new API(endpoint)
    setContext('api', api)

    return api
  }

  static use = (): API => {
    return getContext('api')
  }

  buildUrl(path: string, base?: string) {
    return new URL((base || this.baseUrl) + path)
  }

  timeout(time: number) {
    const controller = new AbortController()
    setTimeout(() => controller.abort(), time)
    return controller
  }

  async request({
    method,
    path,
    base,
    payload,
    headers,
    skipAuthRedirect = false,
    pagination,
    timeout = 15000,
    rawPayload = false,
    options,
  }: {
    method: string
    path: string
    base?: string
    payload?: unknown
    headers?: { [key: string]: string }
    skipAuthRedirect?: boolean
    pagination?: Pagination
    timeout?: number
    rawPayload?: boolean
    options?: RequestInit
  }) {
    const url = this.buildUrl(path, base)

    if (pagination) {
      if (pagination.page)
        url.searchParams.set('page', pagination.page.toString())
      if (pagination.per_page)
        url.searchParams.set('per_page', pagination.per_page.toString())
    }

    return fetch(url, {
      method: method,
      // cache: 'no-cache',
      signal: this.timeout(timeout).signal,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json', // TODO: this might break stuff.?
        [HEADER_SPACE_CLIENT_KEY]: 'bridge',
        ...headers,
      },
      body: rawPayload ? (payload as BodyInit) : JSON.stringify(payload),
      ...options,
    })
      .catch(err => {
        logger.error(err)
        if (err instanceof Error) {
          throw new NetworkError({
            cause: err,
          })
        } else {
          if (this.captureExceptions) captureException(err)
          throw err
        }
      })
      .then(async response => {
        const contentType = response.headers.get('content-type')
        const isJson =
          contentType && contentType.indexOf('application/json') !== -1

        if (!response.ok) {
          logger.error(response)

          if (response.status === 401 && !skipAuthRedirect) {
            logger.debug('Unauthorized!')
            window.location.href = '/login'
          }

          if (isJson) {
            const json = await response.json()

            const err = new APIError({
              status: response.status,
              message: response.statusText,
              detail: json.detail as string,
              body: json,
              response,
            })

            if (this.captureExceptions)
              captureException(err, {
                contexts: {
                  response: {
                    status_code: err.status,
                  },
                },
                extra: {
                  response_detail: err.detail,
                },
              })

            throw err
          }

          const err = new APIError({
            status: response.status,
            message: response.statusText,
            response,
          })

          if (this.captureExceptions)
            captureException(err, {
              contexts: {
                response: {
                  status_code: err.status,
                },
              },
            })

          throw err
        }

        return isJson ? response.json() : response.text()
      })
  }

  async isAuthenticated(): Promise<boolean> {
    try {
      const url = this.buildUrl(ENDPOINTS.space)
      const response = await fetch(url, {
        method: 'GET',
      })

      if (response.ok) {
        return true
      }

      return false
    } catch (_err) {
      return false
    }
  }

  async getSpace(): Promise<ISpace> {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.space,
    })

    return response as ISpace
  }

  async logout(): Promise<boolean> {
    const url = this.buildUrl(ENDPOINTS.auth + '/logout')
    const response = await fetch(url, {
      method: 'GET',
    })

    if (response.ok) {
      if (response.redirected) {
        window.location.href = response.url
      } else {
        window.location.href = '/login'
      }

      return true
    }

    return false
  }

  async deleteAccount(password: string) {
    const url = this.buildUrl(ENDPOINTS.user)
    const response = await fetch(url, {
      method: 'DELETE',
      body: JSON.stringify({ password }),
      redirect: 'manual',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (response.ok) {
      return true
    }

    return false
  }

  async getApps(pagination?: Pagination) {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.apps,
      pagination,
    })

    logger.debug(response)

    return {
      data: response.apps as IApp[],
      pages: response.pages,
    } as PaginatedResponse<IApp[]>
  }

  async getAppByID(id: string, skipAuth = false): Promise<IApp> {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.apps + `/${id}`,
      skipAuthRedirect: skipAuth,
    })

    logger.debug(response)

    return response as IApp
  }

  async createApp(name: string): Promise<IApp> {
    const response = await this.request({
      method: 'POST',
      path: ENDPOINTS.apps,
      payload: { name },
    })

    logger.debug(response)

    return response as IApp
  }

  async deleteApp(id: string): Promise<true> {
    const response = await this.request({
      method: 'DELETE',
      path: ENDPOINTS.apps + `/${id}`,
    })

    logger.debug(response)

    return true
  }

  async wipeDataFromInstance(id: string): Promise<boolean> {
    const response = await this.request({
      method: 'DELETE',
      path: ENDPOINTS.instances + `/${id}/data`,
    })

    logger.debug(response)

    return response.status === 200
  }

  async deleteAppWithRetainData(id: string): Promise<string> {
    const response = await this.request({
      method: 'DELETE',
      path: ENDPOINTS.instances + `/${id}?retain_data=true`,
    })

    logger.debug(response)

    return response.id
  }

  async deleteExportFromInstance(id: string, sid: string): Promise<boolean> {
    const response = await this.request({
      method: 'DELETE',
      path: ENDPOINTS.instances + `/${id}/snapshots/${sid}`,
    })

    logger.debug(response)

    return true
  }

  async exportDataFromInstance(id: string): Promise<boolean> {
    const response = await this.request({
      method: 'POST',
      path: ENDPOINTS.instances + `/${id}/snapshots`,
    })

    logger.debug(response)

    return true
  }

  async toggleAppListing(id: string): Promise<true> {
    const response = await this.request({
      method: 'PATCH',
      path: ENDPOINTS.apps + `/${id}/listed`,
    })

    logger.debug(response)

    return true
  }

  async getAppInstances(pagination?: Pagination) {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.instances,
      pagination,
    })

    logger.debug(response)

    return {
      data: response.instances as IInstance[],
      pages: response.pages,
    } as PaginatedResponse<IInstance[]>
  }

  async getInstancesByAppID(appId: string, pagination?: Pagination) {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.instances + `?app_id=${appId}`,
      pagination,
    })

    logger.debug(response)

    return {
      data: response.instances as IInstance[],
      pages: response.pages,
    } as PaginatedResponse<IInstance[]>
  }

  async getInstanceByID(id: string): Promise<IInstance> {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.instances + `/${id}`,
    })

    logger.debug(response)

    return response as IInstance
  }

  async getDevInstanceByAppID(appId: string): Promise<IInstance> {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.instances + `?channel=development&app_id=${appId}`,
    })

    logger.debug(response)

    return response.instances[0] as IInstance
  }

  async deleteInstance(id: string): Promise<boolean> {
    const response: string = await this.request({
      method: 'DELETE',
      path: ENDPOINTS.instances + `/${id}`,
    })

    logger.debug(response)

    return true
  }

  async updateInstancePresets(
    id: string,
    changes: PresetChanges
  ): Promise<boolean> {
    const response = await this.request({
      method: 'PATCH',
      path: ENDPOINTS.instances + `/${id}`,
      payload: { micros: changes },
    })

    logger.debug(response)

    return true
  }

  async getRevisionsByAppID(appId: string, pagination?: Pagination) {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.revisions(appId),
      pagination,
    })

    logger.debug(response)
    return {
      data: response.revisions as IRevision[],
      pages: response.pages,
    } as PaginatedResponse<IRevision[]>
  }

  async getLatestRevisionByAppID(appId: string): Promise<IRevision> {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.revisions(appId) + `?per_page=1`,
    })

    logger.debug(response)

    return response.revisions[0] as IRevision
  }

  async createReleasePromotion({
    revision_id,
    app_id,
    version,
    discovery_list,
    release_notes,
    channel,
  }: IPromotionPayload): Promise<string> {
    const response = await this.request({
      method: 'POST',
      path: ENDPOINTS.promotions,
      payload: {
        revision_id,
        app_id,
        version,
        discovery_list,
        release_notes,
        channel,
      },
    })

    logger.debug(response)

    return response.id as string
  }

  async storePromotionDiscoveryData(
    promotionId: string,
    data: PromotionDiscoveryData
  ): Promise<string> {
    const response = await this.request({
      method: 'POST',
      path: ENDPOINTS.promotions + `/${promotionId}/discovery`,
      payload: data,
    })

    logger.debug(response)

    return response.id as string
  }

  async getPromotionByID(id: string): Promise<IPromotion> {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.promotions + `/${id}`,
    })

    logger.debug(response)

    return response as IPromotion
  }

  async getPromotionLogs(id: string) {
    const url = this.buildUrl(ENDPOINTS.promotions + `/${id}/logs?follow=true`)

    const response = await fetch(url, {
      method: 'GET',
    })

    if (!response.body) throw new Error('No body')

    const reader = response.body.getReader()

    if (!reader) throw new Error('No reader')
    return reader
  }

  async getReleases(pagination?: Pagination) {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.releases,
      pagination,
    })

    logger.debug(response)
    return {
      data: response.releases as IRelease[],
      pages: response.pages,
    } as PaginatedResponse<IRelease[]>
  }

  async getLatestReleases(pagination?: Pagination) {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.releases + '?latest=true',
      pagination,
    })

    logger.debug(response)

    return {
      data: response.releases as IRelease[],
      pages: response.pages,
    } as PaginatedResponse<IRelease[]>
  }

  async getReleaseByID(id: string): Promise<IRelease> {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.releases + `/${id}`,
    })

    logger.debug(response)

    return response as IRelease
  }

  async getReleasesByAppID(appId: string, pagination?: Pagination) {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.releases + `?app_id=${appId}`,
      pagination,
    })

    logger.debug(response)

    return {
      data: response.releases as IRelease[],
      pages: response.pages,
    } as PaginatedResponse<IRelease[]>
  }

  async getLatestReleaseByAppID(
    appId: string,
    channel = 'experimental'
  ): Promise<IRelease> {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.releases + `/latest?app_id=${appId}&channel=${channel}`,
    })

    logger.debug(response)

    return response as IRelease
  }

  async deleteRelease(id: string): Promise<true> {
    const response = await this.request({
      method: 'DELETE',
      path: ENDPOINTS.releases + `/${id}`,
    })

    logger.debug(response)

    return true
  }

  async unlistRelease(id: string): Promise<true> {
    const response = await this.request({
      method: 'PATCH',
      path: ENDPOINTS.releases + `/${id}/listed`,
    })

    logger.debug(response)

    return true
  }

  async createInstallationRequest(
    appId: string,
    releaseId?: string,
    isUpdate?: boolean,
    instanceId?: string
  ) {
    const response = await this.request({
      method: 'POST',
      path: ENDPOINTS.installations,
      payload: {
        app_id: appId,
        release_id: releaseId,
        is_update: isUpdate,
        instance_id: instanceId,
      },
    })

    logger.debug(response)

    return response as { id: string; instance_id: string }
  }

  async createInstallationUpdateRequest(
    instanceId: string,
    releaseId?: string
  ) {
    const response = await this.request({
      method: 'POST',
      path: ENDPOINTS.installations,
      payload: {
        instance_id: instanceId,
        is_update: true,
        release_id: releaseId,
      },
    })

    logger.debug(response)

    return response as { id: string; instance_id: string }
  }

  async getInstallationByID(id: string): Promise<IInstallation> {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.installations + `/${id}`,
    })

    logger.debug(response)

    return response as IInstallation
  }

  async getInstallationRequests(pagination?: Pagination) {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.installations,
      pagination,
    })

    logger.debug(response)

    return {
      data: response.installations as IInstallation[],
      pages: response.pages,
    } as PaginatedResponse<IInstallation[]>
  }

  async getInstallationLogs(id: string) {
    const url = this.buildUrl(
      ENDPOINTS.installations + `/${id}/logs?follow=true`
    )

    const response = await fetch(url, {
      method: 'GET',
    })

    if (!response.body) throw new Error('No body')

    const reader = response.body.getReader()

    if (!reader) throw new Error('No reader')
    return reader
  }

  async getHorizons() {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.horizons,
    })

    logger.debug(response)

    return response as IHorizon[]

    // const defaultHorizon = await this.getCanvas(false, 1920, false)
    // const mockHorizon = {
    //   ...defaultHorizon,
    //   id: generateID(),
    //   name: 'Mock',
    //   icon: '',
    //   cards: [],
    // }

    // return [defaultHorizon, mockHorizon]
  }

  async getHorizon(
    id: string,
    populate_cards = true,
    viewport_width = 2048,
    grid_sorting = false,
    view_offset_x?: number
  ) {
    const response = await this.request({
      method: 'GET',
      skipAuthRedirect: true,
      path:
        ENDPOINTS.horizons +
        `/${id}?populate_cards=${
          populate_cards as unknown as string
        }&viewport_width=${viewport_width}&grid_sorting=${
          grid_sorting as unknown as string
        }${
          view_offset_x !== undefined ? `&view_offset_x=${view_offset_x}` : ''
        }`,
    })

    logger.debug(response)

    // if (id !== 'Pr39bvhJXH') {
    //   response = {
    //     ...response,
    //     id: generateID(),
    //     name: 'Mock',
    //     icon: '',
    //     cards: [],
    //   }
    // }

    return response as IHorizon
  }

  async createHorizon(data: Partial<IHorizon>) {
    const response = await this.request({
      method: 'POST',
      path: ENDPOINTS.horizons,
      payload: data,
    })

    logger.debug(response)

    return response as IHorizon

    // const defaultHorizon = await this.getCanvas(false, 1920, false)
    // const newHorizon = {
    //   ...defaultHorizon,
    //   id: generateID(),
    //   icon: '',
    // }

    // return newHorizon
  }

  // async getCanvas(
  //   populate_cards?: boolean,
  //   viewport_width?: number,
  //   grid_sorting?: boolean,
  //   id?: string
  // ) {
  //   const response = await this.request({
  //     method: 'GET',
  //     path:
  //       ENDPOINTS.horizons +
  //       `${id ? '/' + id : ''}?populate_cards=${
  //         (populate_cards || true) as unknown as string
  //       }&viewport_width=${viewport_width || 2048}${
  //         grid_sorting ? '&grid_sorting=true' : ''
  //       }`,
  //   })

  //   logger.debug(response)

  //   return response as ICanvas
  // }

  async getHorizonCardPositions(id: string) {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.horizons + `/${id}/card`,
    })

    logger.debug(response)

    // if (id !== 'Pr39bvhJXH') {
    //   response = []
    // }

    return response as Omit<TCard, 'type' | 'local' | 'data'>[]
  }

  async updateHorizon(
    id: string,
    updates: Partial<IHorizon>,
    populate_cards = false,
    viewport_width = 1920,
    stopped_scrolling = false
  ) {
    const controller = new AbortController()
    const signal = controller.signal
    // /${canvas.id}

    const response = await this.request({
      method: 'PATCH',
      path: `${ENDPOINTS.horizons + `/${id}`}?populate_cards=${
        populate_cards as unknown as string
      }&viewport_width=${viewport_width}${
        stopped_scrolling ? '&stopped_scrolling=true' : ''
      }`,
      headers: {
        'Content-Type': 'application/json',
        'ngrok-skip-browser-warning': 'ngrok-skip-browser-warning',
      },
      payload: updates,
      options: {
        signal,
      },
    })

    logger.debug(response)

    // if (id !== 'Pr39bvhJXH') {
    //   data = []
    // }

    return { abort: () => controller.abort(), data: response } as {
      abort: () => void
      data: Partial<IHorizon>
    }
  }

  async deleteHorizon(id: string, deleteData = false) {
    const response = await this.request({
      method: 'DELETE',
      path:
        ENDPOINTS.horizons + `/${id}` + (deleteData ? '?delete_data=true' : ''),
    })

    logger.debug(response)

    return response as IHorizon

    // const defaultHorizon = await this.getCanvas(false, 1920, false)
    // const newHorizon = {
    //   ...defaultHorizon,
    //   id: generateID(),
    //   icon: '',
    // }

    // return newHorizon
  }

  async createCard(card: Omit<TCard, 'id'>, horizonId: string) {
    // BOUNDS
    // TODO: remove hard coded bounds!!
    if (card.x < 0) {
      card.x = 0
    }
    if (card.y < 0) {
      card.y = 0
    }
    if (card.x + card.width > 1920 * 7) {
      card.x = 1920 * 7 - card.width
    }
    if (card.y + card.height > 1080) {
      card.height = 1080 - card.height
    }

    if (card.local)
      return {
        ...card,
        id: '', // TODO: This prob breaks sth right?
      } as TCard
    const response = await this.request({
      method: 'POST',
      path: ENDPOINTS.horizons + `/${horizonId}/card`,
      payload: card,
    })

    logger.debug(response)

    return {
      ...card,
      id: response.id,
      ...response,
    } as TCard
  }

  async getCard(id: string, horizonId: string, populate_cards = true) {
    const response = await this.request({
      method: 'GET',
      path:
        ENDPOINTS.horizons +
        `/${horizonId}/card/${id}${populate_cards ? '?populated=true' : ''}`,
    })

    logger.debug(response)

    return response as TCard
  }

  async updateCard(card: TCard, horizonId: string) {
    if (card.local) return
    const response = await this.request({
      method: 'PATCH',
      path: ENDPOINTS.horizons + `/${horizonId}/card/${card.id}`,
      payload: card,
      skipAuthRedirect: true,
    })

    logger.debug(response)
  }

  async makeCardStackingTop(id: string, horizonId: string) {
    const response = await this.request({
      method: 'PATCH',
      path: ENDPOINTS.horizons + `/${horizonId}/card/${id}/top`,
    })

    logger.debug(response)
  }

  async deleteCard(id: string, horizonId: string) {
    const response = await this.request({
      method: 'DELETE',
      path: ENDPOINTS.horizons + `/${horizonId}/card/${id}`,
      skipAuthRedirect: true,
    })

    logger.debug(response)

    return response
  }

  async createResource(data: Blob) {
    const response = await this.request({
      method: 'POST',
      headers: {
        'Content-Type': data.type,
      },
      path: ENDPOINTS.horizons + `/resource`,
      rawPayload: true,
      payload: data,
    })

    return response as { id: string; mime_type: string; resource_type: string }
  }

  async updateResource(id: string, data: Blob) {
    const response = await this.request({
      method: 'PUT',
      headers: {
        'Content-Type': data.type,
      },
      path: ENDPOINTS.horizons + `/resource/${id}`,
      rawPayload: true,
      payload: data,
    })

    return response as { id: string; mime_type: string; resource_type: string }
  }

  /**
   * Returns the URL to a resource by its id.
   * @param id
   * @returns
   */
  getResourceUrl(id: string) {
    return this.buildUrl(ENDPOINTS.resource + `/${id}`)
  }

  /**
   * Returns the relative URL to a resource by its id.
   * @param id
   * @returns
   */
  getRelativeResourceUrl(id: string) {
    return ENDPOINTS.resource + `/${id}`
  }

  /**
   * Returns the blob data of a resource by its id.
   * @param id
   */
  async getResource(id: string) {
    const url = this.buildUrl(ENDPOINTS.resource + `/${id}`)
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'ngrok-skip-browser-warning': 'ngrok-skip-browser-warning',
      },
    })

    // TODO: response blob
    return response.blob()
  }

  async createHorizonCardCopy(
    id: string,
    sourceHorizon: string,
    targetHorizon: string,
    cut = false,
    position?: Partial<CardPosition>
  ) {
    const response = await this.request({
      method: 'POST',
      path:
        ENDPOINTS.horizons +
        `/${targetHorizon}/send?cut=${cut as unknown as string}`,
      payload: {
        source_horizon_id: sourceHorizon,
        card_id: id,
        ...(position || {}),
      },
    })

    return response.id as string
  }

  async runHorizonAIPrompt(query: string, horizonId: string) {
    const response = await this.request({
      path: ENDPOINTS.horizons + `/${horizonId}/magic`, //'/v0/space/magic',
      method: 'POST',
      timeout: 180000,
      payload: {
        op: 'code_card',
        context: 'horizon',
        query,
      },
    })

    return response
  }

  async trackHorizonOpenAppEvent(appId: string, horizonId: string) {
    const response = await this.request({
      method: 'POST',
      path: ENDPOINTS.horizons + `/${horizonId}/event/app`,
      payload: {
        app_id: appId,
      },
    })

    logger.debug(response)

    return response
  }

  getTrackHorizonOpenAppEvent(horizonId: string) {
    return this.buildUrl(ENDPOINTS.horizons + `/${horizonId}/event/app`)
  }

  async generateInstanceEmbed(alias: string) {
    const response = await this.request({
      method: 'POST',
      path: ENDPOINTS.instances + `/${alias}/embed`,
    })

    logger.debug(response)

    return response as InstanceEmbed
  }

  async checkSpaceAppRoute(alias: string, route: string) {
    const response = await this.request({
      method: 'POST',
      path: ENDPOINTS.instances + `/${alias}/check-route`,
      payload: {
        route: route,
      },
    })

    logger.debug(response)

    return response as { public: boolean; instance_id: string }
  }

  async getLegacyCanvas() {
    // TODO: Switch to lazy loading instead of per_page=999
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.canvas + '?limit=999', // TODO remove limit when pagination is implemented
      pagination: { page: 0, per_page: 999 },
    })

    logger.debug(response)

    return {
      data: response.items as ILegacyCanvas['items'],
      pages: response.pages,
    } as PaginatedResponse<ILegacyCanvas['items']>
  }

  async getLegacyCanvasItemByID(id: string): Promise<ICanvasItem> {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.canvas + `/${id}`,
    })

    logger.debug(response)

    return response as ICanvasItem
  }

  async getLegacyCanvasItemByItemIDAndType(
    item_id: string,
    item_type: string
  ): Promise<ICanvasItem> {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.canvas + `?item_id=${item_id}&item_type=${item_type}`,
    })

    logger.debug(response)

    return response.items[0] as ICanvasItem
  }

  async updateLegacyCanvas(updates: Record<string, number>): Promise<boolean> {
    const response = await this.request({
      method: 'PATCH',
      path: ENDPOINTS.canvas,
      payload: {
        updates: updates,
      },
    })

    logger.debug(response)
    return true
  }

  async addLegacyCanvasItem(
    itemId: string,
    itemType: ICanvasItemType
  ): Promise<ICanvasItem> {
    const response = await this.request({
      method: 'POST',
      path: ENDPOINTS.canvas,
      payload: {
        item_id: itemId,
        item_type: itemType,
      },
    })

    logger.debug(response)
    return response as ICanvasItem
  }

  async deleteLegacyCanvasItem(id: string): Promise<{ id: string }> {
    const response = await this.request({
      method: 'DELETE',
      path: ENDPOINTS.canvas + `/${id}`,
    })

    logger.debug(response)
    return response
  }

  async getMetadata(): Promise<IMetadata> {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.metadata,
      skipAuthRedirect: true,
    })

    logger.debug(response)

    return response as IMetadata
  }

  async setMetadata(metadata: Partial<IMetadata>): Promise<IMetadata> {
    const response = await this.request({
      method: 'PATCH',
      path: ENDPOINTS.metadata,
      payload: metadata,
    })

    logger.debug(response)

    return response.metadata as IMetadata
  }

  async getTokens(pagination?: Pagination) {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.tokens,
      pagination,
    })

    logger.debug(response)

    return {
      data: response.tokens as IToken[],
      pages: response.pages,
    } as PaginatedResponse<IToken[]>
  }

  async createToken(): Promise<ICreatedToken> {
    const response = await this.request({
      method: 'POST',
      path: ENDPOINTS.tokens,
    })

    logger.debug(response)

    return response as ICreatedToken
  }

  async deleteToken(id: string): Promise<boolean> {
    const response = await this.request({
      method: 'DELETE',
      path: ENDPOINTS.tokens + `/${id}`,
    })

    logger.debug(response)

    return true
  }

  async getBuilds(appId: string, pagination?: Pagination) {
    const response = await this.request({
      method: 'GET',
      path: `${ENDPOINTS.builds}?app_id=${appId}`,
      pagination,
    })

    logger.debug(response)

    return {
      data: response.builds as IBuild[],
      pages: response.pages,
    } as PaginatedResponse<IBuild[]>
  }

  async getBuildById(id: string): Promise<IBuild> {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.builds + `/${id}`,
    })

    logger.debug(response)

    return response
  }

  async getDiscoveryReleases(
    sort: DiscoverySort = 'published',
    filter?: DiscoveryFilter,
    since?: number,
    pagination?: Pagination
  ) {
    const response = await this.request({
      method: 'GET',
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      path:
        ENDPOINTS.discovery +
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `/apps?sort=${sort}` +
        (filter ? `&filter=${filter as unknown as string}` : '') +
        (since ? `&published_since=${since}` : ''),
      pagination,
    })

    // logger.debug(response)

    return {
      data: response.releases as IRelease[],
      pages: response.pages,
    } as PaginatedResponse<IRelease[]>
  }

  async getDiscoveryReleasesByAppID(appId: string, pagination?: Pagination) {
    const response = await this.request({
      method: 'GET',
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      path:
        ENDPOINTS.discovery +
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `/app_projects/${appId}/releases`,
      pagination,
    })

    // logger.debug(response)

    return {
      data: response.releases as IRelease[],
      pages: response.pages,
    } as PaginatedResponse<IRelease[]>
  }

  async searchDiscoveryReleases(query: string, pagination?: Pagination) {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.discovery + `/search?query=${query}`,
      pagination,
    })

    logger.debug(response)

    return {
      data: response.releases as IRelease[],
      pages: response.pages,
    } as PaginatedResponse<IRelease[]>
  }

  async getDiscoveryReleaseByID(id: string): Promise<IRelease> {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.discovery + `/apps/${id}`,
    })

    logger.debug(response)

    return response.release as IRelease
  }

  async getDiscoveryReleaseByScopedAlias(
    scope: string,
    alias: string
  ): Promise<IRelease> {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.discovery + `/apps/${scope}/${alias}`,
    })

    logger.debug(response)

    return response.release as IRelease
  }

  async getDiscoveryReleaseByScopedAliasAndVersion(
    scope: string,
    alias: string,
    version: string
  ): Promise<IRelease> {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.discovery + `/apps/${scope}/${alias}/${version}`,
    })

    logger.debug(response)

    return response.release as IRelease
  }

  async getProjectKeys(appId: string, pagination?: Pagination) {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.project_keys(appId),
      pagination,
    })

    logger.debug(response)

    return {
      data: response.keys as IKey[],
      pages: response.pages,
    } as PaginatedResponse<IKey[]>
  }

  async getProjectKeyByName(appId: string, name: string): Promise<IKey> {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.project_keys(appId) + `/${name}`,
    })

    logger.debug(response)

    return response as IKey
  }

  async createProjectKey(appId: string, name?: string): Promise<ICreatedKey> {
    const response = await this.request({
      method: 'POST',
      path: ENDPOINTS.project_keys(appId),
      payload: {
        name,
      },
    })

    logger.debug(response)

    return response as ICreatedKey
  }

  async deleteProjectKey(appId: string, name: string): Promise<true> {
    const response = await this.request({
      method: 'DELETE',
      path: ENDPOINTS.project_keys(appId) + `/${name}`,
    })

    logger.debug(response)

    return true
  }

  async createInstanceAPIKey(
    instanceId: string,
    name?: string
  ): Promise<ICreatedKey> {
    const response = await this.request({
      method: 'POST',
      path: ENDPOINTS.instances_api_keys(instanceId),
      payload: { name },
    })

    logger.debug(response)

    return response as ICreatedKey
  }

  async deleteInstanceAPIKey(instanceId: string, name: string): Promise<true> {
    const response = await this.request({
      method: 'DELETE',
      path: ENDPOINTS.instances_api_keys(instanceId) + `/${name}`,
    })

    logger.debug(response)

    return true
  }

  async createInstanceDataKey(
    instanceId: string,
    name?: string
  ): Promise<ICreatedKey> {
    const response = await this.request({
      method: 'POST',
      path: ENDPOINTS.instances_data_keys(instanceId),
      payload: { name },
    })

    logger.debug(response)

    return response as ICreatedKey
  }

  async deleteInstanceDataKey(instanceId: string, name: string): Promise<true> {
    const response = await this.request({
      method: 'DELETE',
      path: ENDPOINTS.instances_data_keys(instanceId) + `/${name}`,
    })

    logger.debug(response)

    return true
  }

  async assignInstanceDomain(
    instanceId: string,
    domain: string,
    force?: boolean
  ): Promise<IDomain> {
    const response = await this.request({
      method: 'POST',
      path: ENDPOINTS.instances + `/${instanceId}/domains`,
      payload: {
        domain: domain,
        force: force,
      },
    })

    logger.debug(response)

    return response as IDomain
  }

  async getInstanceDomains(instanceId: string, pagination?: Pagination) {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.instances + `/${instanceId}/domains`,
      pagination,
    })

    logger.debug(response)

    return {
      data: response.domains as IDomain[],
      pages: response.pages,
    } as PaginatedResponse<IDomain[]>
  }

  async getInstanceDomainByID(
    instanceId: string,
    domainId: string
  ): Promise<IDomain> {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.instances + `/${instanceId}/domains/${domainId}`,
    })

    logger.debug(response)

    return response as IDomain
  }

  async deleteInstanceDomainByID(
    instanceId: string,
    domainId: string
  ): Promise<true> {
    const response = await this.request({
      method: 'DELETE',
      path: ENDPOINTS.instances + `/${instanceId}/domains/${domainId}`,
    })

    logger.debug(response)

    return true
  }

  async getExportsByInstanceID(id: string, pagination?: Pagination) {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.instances + `/${id}/snapshots`,
      pagination,
    })

    logger.debug(response)

    return {
      data: response.snapshots as IExport[],
      pages: response.pages,
    } as PaginatedResponse<IExport[]>
  }

  async getInstanceLogs(
    instanceId: string,
    micro: string,
    limit = 20,
    offset = 0
  ): Promise<ILogItem[]> {
    const response = await this.request({
      method: 'GET',
      path:
        ENDPOINTS.logger +
        `/instances/${instanceId}/micros/${micro}/logs?limit=${limit}&offset=${offset}`,
    })

    logger.debug(response)

    return response as ILogItem[]
  }

  async getInstanceActions(instanceId: string, pagination?: Pagination) {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.instances + `/${instanceId}/actions`,
      pagination,
    })

    logger.debug(response)

    return {
      data: response.actions as IAction[],
      pages: response.pages,
    } as PaginatedResponse<IAction[]>
  }

  async getInstanceActionByID(
    instanceId: string,
    actionId: string
  ): Promise<IAction> {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.instances + `/${instanceId}/actions/${actionId}`,
    })

    logger.debug(response)

    return response as IAction
  }

  async updateInstanceActionByID(
    instanceId: string,
    actionId: string,
    updates: ScheduleActionUpdatePayload
  ): Promise<true> {
    const response = await this.request({
      method: 'PATCH',
      path: ENDPOINTS.instances + `/${instanceId}/actions/${actionId}`,
      payload: updates,
    })

    logger.debug(response)

    return true
  }

  async getCollections(pagination?: Pagination) {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.collections,
      pagination,
    })

    logger.debug(response)

    return {
      data: response.collections as ICollection[],
      pages: response.pages,
    } as PaginatedResponse<ICollection[]>
  }

  async getLegacyCollections(pagination?: Pagination) {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.collections + '?type=legacy',
      pagination,
    })

    logger.debug(response)

    return {
      data: response.collections as ICollection[],
      pages: response.pages,
    } as PaginatedResponse<ICollection[]>
  }

  async exportDataFromCollection(id: string): Promise<boolean> {
    const response = await this.request({
      method: 'POST',
      path: ENDPOINTS.collections + `/${id}/snapshots`,
    })

    logger.debug(response)

    return true
  }

  async deleteExportFromCollection(id: string, sid: string): Promise<boolean> {
    const response = await this.request({
      method: 'DELETE',
      path: ENDPOINTS.collections + `/${id}/snapshots/${sid}`,
    })

    logger.debug(response)

    return true
  }

  async getExportsByCollectionID(id: string, pagination?: Pagination) {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.collections + `/${id}/snapshots`,
      pagination,
    })

    logger.debug(response)

    return {
      data: response.snapshots as IExport[],
      pages: response.pages,
    } as PaginatedResponse<IExport[]>
  }

  async getCollectionByID(id: string): Promise<ICollection> {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.collections + `/${id}`,
    })

    logger.debug(response)

    return response as ICollection
  }

  async deleteCollectionByID(id: string): Promise<true> {
    const response = await this.request({
      method: 'DELETE',
      path: ENDPOINTS.collections + `/${id}`,
    })

    logger.debug(response)

    return true
  }

  async createCollection(
    name: string,
    legacyProjectID?: string
  ): Promise<ICollection> {
    const response = await this.request({
      method: 'POST',
      path: ENDPOINTS.collections,
      payload: {
        name,
        ...(legacyProjectID ? { legacy_id: legacyProjectID } : {}),
      },
    })

    logger.debug(response)

    return response as ICollection
  }

  async getBasesByCollectionID(id: string, pagination?: Pagination) {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.collections + `/${id}/bases`,
      pagination,
    })

    logger.debug(response)

    return {
      data: response.bases as IBase[],
      pages: response.pages,
    } as PaginatedResponse<IBase[]>
  }

  async getDrivesByCollectionID(id: string, pagination?: Pagination) {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.collections + `/${id}/drives`,
      pagination,
    })

    logger.debug(response)

    return {
      data: response.drives as IDrive[],
      pages: response.pages,
    } as PaginatedResponse<IDrive[]>
  }

  async createBaseForCollectionID(
    collectionId: string,
    name: string
  ): Promise<IBase> {
    const response = await this.request({
      method: 'POST',
      path: ENDPOINTS.collections + `/${collectionId}/bases`,
      payload: { name },
    })

    logger.debug(response)

    return response as IBase
  }

  async createDriveForCollectionID(
    collectionId: string,
    name: string
  ): Promise<IDrive> {
    const response = await this.request({
      method: 'POST',
      path: ENDPOINTS.collections + `/${collectionId}/drives`,
      payload: { name },
    })

    logger.debug(response)

    return response as IDrive
  }

  async deleteBaseByCollectionIDAndName(
    collectionId: string,
    name: string
  ): Promise<true> {
    const response = await this.request({
      method: 'DELETE',
      path: ENDPOINTS.collections + `/${collectionId}/bases/${name}`,
    })

    logger.debug(response)

    return true
  }

  async deleteDriveByCollectionIDAndName(
    collectionId: string,
    name: string
  ): Promise<true> {
    const response = await this.request({
      method: 'DELETE',
      path: ENDPOINTS.collections + `/${collectionId}/drives/${name}`,
    })

    logger.debug(response)

    return true
  }

  async getDataKeysByCollectionID(id: string, pagination?: Pagination) {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.collections + `/${id}/keys`,
      pagination,
    })

    logger.debug(response)

    return {
      data: response.keys as IKey[],
      pages: response.pages,
    } as PaginatedResponse<IKey[]>
  }

  async getDataKeyByCollectionIDAndName(
    collectionId: string,
    name: string
  ): Promise<IKey> {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.collections + `/${collectionId}/keys/${name}`,
    })

    logger.debug(response)

    return response as IKey
  }

  async createCollectionDataKey(
    collectionId: string,
    name?: string
  ): Promise<ICreatedKey> {
    const response = await this.request({
      method: 'POST',
      path: ENDPOINTS.collections + `/${collectionId}/keys`,
      payload: { name },
    })

    logger.debug(response)

    return response as ICreatedKey
  }

  async deleteCollectionDataKey(
    collectionId: string,
    name: string
  ): Promise<true> {
    const response = await this.request({
      method: 'DELETE',
      path: ENDPOINTS.collections + `/${collectionId}/keys/${name}`,
    })

    logger.debug(response)

    return true
  }

  async getAppActions(): Promise<IAppAction[]> {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.actions,
      pagination: { per_page: 1000 },
    })

    logger.debug(response)

    return response.actions as IAppAction[]
  }

  async getLocalAppActions(devServer: string): Promise<IAppAction[]> {
    const response = await this.request({
      method: 'GET',
      path: '/__space/actions',
      base: devServer,
    })

    return response as IAppAction[]
  }

  async invokeAppAction(
    instanceId: string,
    actionName: string,
    payload?: unknown
  ): Promise<unknown> {
    const response = await this.request({
      method: 'POST',
      path: ENDPOINTS.actions + `/${instanceId}/${actionName}`,
      payload,
    })

    logger.debug(response)

    return response
  }

  async invokeLocalAppAction(
    devServer: string,
    actionName: string,
    payload?: unknown
  ): Promise<unknown> {
    const response = await this.request({
      method: 'POST',
      path: `/__space/actions/${actionName}`,
      base: devServer,
      payload,
    })

    logger.debug(response)

    return response
  }

  async createInvocation(
    instanceId: string,
    actionName: string,
    input?: IInvocation['input']
  ): Promise<IInvocation> {
    const response = await this.request({
      method: 'POST',
      path: ENDPOINTS.invocations,
      payload: {
        instance_id: instanceId,
        action_name: actionName,
        input,
      },
    })

    logger.debug(response)

    return response as IInvocation
  }

  async getInvocations(pagination?: Pagination) {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.invocations,
      pagination,
    })

    logger.debug(response)

    return {
      data: (response.invocations || []) as IInvocation[],
      pages: response.pages,
    } as PaginatedResponse<IInvocation[]>
  }

  async getInvocationByID(id: string): Promise<IInvocation> {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.invocations + `/${id}`,
    })

    logger.debug(response)

    return response as IInvocation
  }

  async getLegacyDomains(): Promise<ILegacyDomain[]> {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.legacy_domains,
    })

    logger.debug(response)

    return response as ILegacyDomain[]
  }

  async migrateLegacyDomain(
    domainId: string,
    instanceId: string
  ): Promise<true> {
    const response = await this.request({
      method: 'POST',
      path: ENDPOINTS.legacy_domains + '/migrations',
      payload: {
        id: domainId,
        instance_id: instanceId,
      },
    })

    logger.debug(response)

    return true
  }

  async revertLegacyDomainMigration(domainId: string): Promise<true> {
    const response = await this.request({
      method: 'DELETE',
      path: ENDPOINTS.legacy_domains + `/migrations/${domainId}`,
    })

    logger.debug(response)

    return true
  }

  async globalSearch(query: string) {
    const response = await this.request({
      method: 'GET',
      path: ENDPOINTS.demo + `/search?q=${query}`,
    })
    return response.results
  }
}

export const provideAPI = API.provide
export const useAPI = API.use
