export function getDevicePixelRatio() {
    return window?.devicePixelRatio || 1;
}
const _debounceMap = new Map();
export function debounce(id, ms, callback) {
    if (_debounceMap.has(id))
        clearTimeout(_debounceMap.get(id));
    const timer = setTimeout(callback, ms);
    _debounceMap.set(id, timer);
}
export const lerp = (a, b, t) => a * (1 - t) + b * t;
export const clamp = (a, min = 0, max = 1) => Math.min(max, Math.max(min, a));
export const invlerp = (x, y, a) => clamp((a - x) / (y - x));
export const map = (x1, y1, x2, y2, a) => lerp(x2, y2, invlerp(x1, y1, a));
export function posToAbsolute(x, y, viewX, viewY, viewPort, zoom) {
    // TODO: page scroll?
    // TODO: zoom?
    return {
        x: x / zoom + viewX - viewPort.x,
        y: y / zoom + viewY - viewPort.y
    };
}
/**
   * @param x
   * @param y
   * @param width
   * @param height
   * @param viewX
   * @param viewY
   * @param viewPort
   * @param zoom
   * @param CULL_MARGIN_WIDHT
   * @param CULL_MARGIN_HEIGHT
   */
export function isInsideViewport(x, y, width, height, viewX, viewY, viewPort, zoom, marginWidth = 0, marginHeight = 0) {
    return x + width + marginWidth >= viewX && y + height + marginHeight >= viewY && x - marginWidth <= viewX + viewPort.w / (zoom * 1) && y - marginHeight <= viewY + viewPort.h / (zoom * 1);
}
export function hasClassOrParentWithClass(element, className) {
    if (!element) {
        return false;
    }
    if (element.classList.contains(className)) {
        return true;
    }
    if (element.parentElement)
        return hasClassOrParentWithClass(element.parentElement, className);
    else
        return false;
}
export function isTagOrParentWithTag(element, tagName) {
    if (!element) {
        return false;
    }
    if (element.tagName === tagName) {
        return true;
    }
    if (element.parentElement)
        return isTagOrParentWithTag(element.parentElement, tagName);
    else
        return false;
}
export function isTagsOrParentWithTags(element, tagNames) {
    if (!element) {
        return false;
    }
    if (tagNames.includes(element.tagName)) {
        return true;
    }
    if (element.parentElement)
        return isTagsOrParentWithTags(element.parentElement, tagNames);
    else
        return false;
}
export function isInsidePositionable(e) {
    if (!e) {
        return false;
    }
    if (e.classList.contains("positionable")) {
        return e.dataset.id || false;
    }
    if (e.parentElement)
        return isInsidePositionable(e.parentElement);
    else
        return false;
}
export function snapToGrid(value, snap) {
    return Math.floor(Math.round(value / snap) * snap);
}
export function rectsIntersect(a, b) {
    return a.x < b.x + b.w && a.x + a.w > b.x && a.y < b.y + b.h && a.y + a.h > b.y;
}
export function randomCssColor(alpha = 1) {
    return `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${alpha})`;
}
/**
 * Use raw for loop for performance increase.
 */
export const fastFilter = (fn, arr) => {
    const f = new Array();
    for (let i = 0; i < arr.length; i++) {
        if (fn(arr[i])) {
            f.push(arr[i]);
        }
    }
    return f;
};
export function hoistPositionable(key, el) {
    el.dispatchEvent(new CustomEvent("tela_hoist", { detail: key, bubbles: true }));
}
export function unHoistPositionable(key, el) {
    el.dispatchEvent(new CustomEvent("tela_unhoist", { detail: key, bubbles: true }));
}
