/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import type { Action, TeletypeCore } from '@deta/teletype'
import { objectToString, type CommonActionContext } from '../utils'
import { ViewType, AppAction, View } from '@deta/types'
import { getHumanDistanceToNow } from '@/utils/time'
import { getSearchParams } from '@/utils/mount'

function popupWindow(url: string, windowName: string) {
  const currentWindow = {
    left: window.screenLeft,
    top: window.screenTop,
    width: window.innerWidth,
    height: window.innerHeight,
  }
  const width = 500
  const height = 300
  const left = Math.ceil(
    (currentWindow.left || 0) + ((currentWindow.width || 0) - width) / 2
  )
  const top = Math.floor(
    (currentWindow.top || 0) + ((currentWindow.height || 0) - height) / 2
  )

  window.open(
    url,
    windowName,
    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`
  )
}

export function openCustomCard(action: AppAction, view: View) {
  const { devServer } = getSearchParams<{ devServer: string }>()

  const instanceUrl =
    action.channel == 'local'
      ? `${devServer}`
      : `https://${action.instance_alias}.deta.app`
  const input = encodeURIComponent(JSON.stringify(view.data))
  const cardUrl = view.type.startsWith('/')
    ? `${instanceUrl}${view.type}?input=${input}`
    : `${instanceUrl}/${view.type}?input=${input}`

  popupWindow(cardUrl, action.title)
}

export const useAppActionsAction = (context: CommonActionContext): Action => {
  const { devServer } = getSearchParams<{ devServer: string }>()

  return {
    id: 'app-actions',
    icon: 'bolt',
    name: 'App Actions',
    breadcrumb: 'App Actions',
    async loadChildActions() {
      let actions = await context.api.getAppActions()
      if (devServer) {
        try {
          const url = new URL(devServer)
          if (url.hostname === 'localhost') {
            const localActions = await context.api.getLocalAppActions(devServer)
            actions = [
              ...localActions.map(action => ({ ...action, local: true })),
              ...(actions || []),
            ]
          } else {
            console.warn(
              `Cannot load local actions from ${devServer}, only localhost is supported.`
            )
          }
        } catch (e) {
          console.error(e)
        }
      }

      return (actions || []).map(action => {
        const base = {
          id: `action-${action.name}-${action.instance_id}`,
          name: action.title || action.name,
          description: action.app_name,
          section: devServer
            ? action.local
              ? 'Local Actions'
              : 'Installed Actions'
            : undefined,
          actionText: 'Run Action',
          tag: action.instance_alias,
          icon: action.local
            ? 'terminal'
            : action.icon_url ||
              action?.placeholder_icon_config?.css_background,
        }

        if (action.input && action.input.length > 0) {
          return {
            ...base,
            handler: () => {
              return {
                afterClose: teletype => {
                  teletype.showAction({
                    id: `action-${action.name}-${action.instance_id}`,
                    view: 'ModalSmall',
                    footerText: `${action.app_name} ${
                      action.title || action.name
                    }`,
                    lazyComponent: () =>
                      import('@/teletype/AppActionInputs.svelte'),
                    componentProps: {
                      action: action,
                      devServer: devServer,
                    },
                  })
                },
              }
            },
          }
        }

        return {
          ...base,
          handler: async () => {
            let raw
            if (devServer && action.local) {
              raw = await context.api.invokeLocalAppAction(
                devServer,
                action.name
              )
            } else {
              const newInvocation = await context.api.createInvocation(
                action.instance_id,
                action.name
              )

              raw = {
                type: newInvocation.action.output,
                data: newInvocation.output,
              }
            }

            const res = typeof raw === 'string' ? JSON.parse(raw) : raw

            if (
              typeof res === 'object' &&
              !Object.values(ViewType).includes(res.type as ViewType) &&
              res.type !== ''
            ) {
              openCustomCard(action, res as View)
              return
            }

            return {
              afterClose: teletype =>
                teletype.showAction(
                  appActionOutputAction(res, action, teletype)
                ),
            }
          },
        }
      })
    },
  }
}

export const useActionInvocationsAction = (
  context: CommonActionContext
): Action => {
  return {
    id: 'action-invocations',
    icon: 'history',
    name: 'Action Invocations',
    breadcrumb: 'Invocations',
    async loadChildActions() {
      const { data: invocations } = await context.api.getInvocations({
        per_page: 1000,
      })
      return invocations.map(invocation => {
        let base = {
          id: `invocation-${invocation.id}`,
          name: invocation.action.title || invocation.action.name,
          description: invocation.input
            ? objectToString(invocation.input)
            : '-',
          actionText: 'View Output',
          tag: getHumanDistanceToNow(invocation.created_at),
          icon:
            invocation.action.icon_url ||
            invocation.action?.placeholder_icon_config?.css_background,
        }

        if (invocation.action.input && invocation.action.input.length > 0) {
          base = {
            ...base,
            actionPanel: [
              {
                id: `action-${invocation.action.name}-${invocation.action.instance_id}-rerun-input`,
                name: 'Re-run with new input',
                icon: 'forms',
                action: {
                  id: `action-${invocation.action.name}-${invocation.action.instance_id}-rerun-input`,
                  name: 'Re-run with new input',
                  view: 'ModalSmall',
                  icon: 'forms',
                  footerText: `${invocation.action.app_name} ${
                    invocation.action.title || invocation.action.name
                  }`,
                  lazyComponent: () =>
                    import('@/teletype/AppActionInputs.svelte'),
                  componentProps: {
                    action: invocation.action,
                    values: invocation.input
                      ? Object.values(invocation.input)
                      : [],
                  },
                },
              },
            ],
          }
        }
        base = {
          ...base,
          actionPanel: [
            ...(base.actionPanel || []),
            {
              id: `action-${invocation.action.name}-${invocation.action.instance_id}-rerun`,
              name: 'Re-run Action',
              icon: 'bolt',
              handler: async () => {
                const newInvocation = await context.api.createInvocation(
                  invocation.action.instance_id,
                  invocation.action.name,
                  invocation.input
                )

                const raw = {
                  type: newInvocation.action.output,
                  data: newInvocation.output,
                }

                if (
                  typeof raw === 'object' &&
                  !Object.values(ViewType).includes(raw.type) &&
                  (raw.type as any) !== ''
                ) {
                  openCustomCard(invocation.action, raw as View)
                  return
                }

                return {
                  afterClose: teletype =>
                    teletype.showAction(
                      appActionOutputAction(raw, invocation.action, teletype)
                    ),
                }
              },
            },
          ],
        }

        return {
          ...base,
          handler: () => {
            const raw = {
              type: invocation.action.output,
              data: invocation.output,
            }
            if (
              !Object.values(ViewType).includes(raw.type) &&
              (raw.type as any) !== ''
            ) {
              openCustomCard(invocation.action, raw as View)
              return
            }

            return {
              afterClose: teletype =>
                teletype.showAction(
                  appActionOutputAction(raw, invocation.action, teletype)
                ),
            }
          },
        }
      })
    },
  }
}

export const appActionOutputAction = (
  view: View,
  action: AppAction,
  teletype: TeletypeCore
) => {
  return {
    id: 'app-action-output',
    name: 'Action Output',
    footerText: 'Action Output',
    lazyComponent: () => {
      if (view.type === ViewType.DETAIL) {
        return import('@/components/Views/DetailView.svelte')
      } else if (view.type === ViewType.FILE) {
        return import('@/components/Views/FileView.svelte')
      } else if (view.type === ViewType.LIST) {
        return import('@/components/Views/ListView.svelte')
      } else if (view.type === ViewType.TABLE) {
        return import('@/components/Views/TableView.svelte')
      } else if (view.type === ViewType.RAW) {
        return import('@/components/Views/RawView.svelte')
      } else {
        return import('@/components/Views/ErrorView.svelte')
      }
    },
    componentProps: {
      view: view,
      action: action,
      addToActionPanel: actions => teletype.addToCurrentActionPanel(actions),
    },
    actionText: 'Exit',
    handler(_, teletype) {
      teletype.close()
    },
    showActionPanel: true,
    actionPanel: view?.data?.ref
      ? [
          {
            id: 'open-ref',
            name: 'Open Ref',
            icon: 'external_link',
            handler() {
              window.open(view.data.ref as string, '_blank')
              return {
                preventClose: true,
              }
            },
          },
        ]
      : [],
  }
}
