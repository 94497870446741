<svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M16.0001 0.625C24.4914 0.625 31.375 7.50862 31.375 16C31.375 24.4914 24.4914 31.375 16.0001 31.375C7.50862 31.375 0.625 24.4914 0.625 16C0.625 7.50863 7.50862 0.625 16.0001 0.625Z"
    stroke="#7B7673"
    stroke-width="1.25"
  />
  <path
    d="M16.0383 3.74902C22.8465 3.74902 28.3657 9.26817 28.3657 16.0764C28.3657 22.8846 22.8465 28.4038 16.0383 28.4038C9.23012 28.4038 3.71094 22.8846 3.71094 16.0764C3.71094 9.26818 9.23013 3.74902 16.0383 3.74902Z"
    stroke="#7B7673"
    stroke-width="1.25"
  />
  <path
    d="M16.0394 7.17773C20.954 7.17773 24.9381 11.1618 24.9381 16.0766C24.9381 20.9913 20.954 24.9753 16.0394 24.9753C11.1247 24.9753 7.14062 20.9913 7.14062 16.0766C7.14062 11.1618 11.1247 7.17773 16.0394 7.17773Z"
    stroke="#7B7673"
    stroke-width="1.25"
  />
  <path
    d="M15.9605 10.0723C19.192 10.0723 21.8117 12.6919 21.8117 15.9234C21.8117 19.1548 19.192 21.7746 15.9605 21.7746C12.7291 21.7746 10.1094 19.1548 10.1094 15.9234C10.1094 12.6919 12.7291 10.0723 15.9605 10.0723Z"
    stroke="#7B7673"
    stroke-width="1.25"
  />
</svg>
