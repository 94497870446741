import { derived, get, Readable, Writable, writable } from 'svelte/store'
import type {
  App,
  Installation,
  Instance,
  Promotion,
  Release,
  Revision,
  Canvas,
  Token,
  Build,
  Space,
  Metadata,
  Key,
  CanvasItem,
  Collection,
  Base,
  Drive,
  Action,
  Domain,
  DiscoverySort,
  LegacyDomain,
  Invocation,
  Export,
  DiscoveryFilter,
  InstanceEmbed,
} from '@deta/types'

import type { API, PaginatedResponse, Pagination } from '@/api'
import { getContext, onDestroy, onMount, setContext } from 'svelte'
import type { IHorizon, TCard } from '@/components/Canvas/canvas.types'

type CacheItem = {
  data: Writable<unknown | null>
  isFetching: boolean
  pagination?: Writable<Pagination>
}

export type SWROptions = {
  // number of items per page
  limit?: number
  // fetch data on initial load
  initialFetch?: boolean
  // endpoint uses pagination
  paginated?: boolean
}

export class Store {
  api: API
  cache: Map<string, CacheItem>

  constructor(api: API) {
    this.api = api
    this.cache = new Map()
  }

  static provide = (api: API) => {
    const store = new Store(api)
    setContext('store', store)

    return store
  }

  static use = (): Store => {
    return getContext('store')
  }

  useSWR<T>(
    key: string,
    fetchMethod: (
      pagination?: Pagination
    ) => Promise<T> | Promise<PaginatedResponse<T>>,
    opts?: SWROptions
  ) {
    const options = Object.assign(
      {},
      {
        initialFetch: true,
        paginated: false,
        limit: 100,
      },
      opts
    )

    const error = writable<null | unknown>(null)
    const isLoading = writable(false)

    let data: Writable<null | T>
    let pagination: Writable<Pagination>

    const item = this.cache.get(key)
    if (item && item.data) {
      data = item.data as Writable<T>
    } else {
      data = writable<null | T>(null)
    }

    if (item && item.pagination) {
      pagination = item.pagination
    } else {
      pagination = writable<Pagination>({
        page: 0,
        per_page: options.limit,
        end: false,
      })
    }

    const next = () => {
      if (!options.paginated) return
      if (get(pagination).end) return

      pagination.update(v => {
        if (!v) {
          return {
            page: 0,
            per_page: options.limit,
            end: false,
          }
        }

        return {
          ...v,
          page: (v.page || 0) + 1,
        }
      })

      fetchData(get(pagination))
        .then(result => {
          if (!result) return

          data.update(v => {
            const prev = (v || []) as Iterable<T>
            const nxt = result as unknown as Iterable<T>

            return [...prev, ...nxt] as T
          })

          pagination.update(v => ({
            ...v,
            end: (get(data) as unknown[]).length >= (v.total || 0),
          }))

          this.cache.set(key, {
            data: data,
            isFetching: false,
            pagination: pagination,
          })
          error.set(null)
        })
        .catch((err: unknown) => {
          error.set(err)
          this.cache.set(key, { data: data, isFetching: false })
        })
        .finally(() => {
          isLoading.set(false)
        })
    }

    const revalidate = () => {
      pagination.set({
        page: 0,
        per_page: options.limit,
        end: false,
      })
      const paginationValue = get(pagination)

      fetchData(paginationValue)
        .then(result => {
          data.set(result)

          pagination.update(v => ({
            ...v,
            end: (get(data) as unknown[]).length >= (v.total || 0),
          }))

          this.cache.set(key, {
            data: data,
            isFetching: false,
            pagination: pagination,
          })
          error.set(null)
        })
        .catch((err: unknown) => {
          error.set(err)
          this.cache.set(key, { data: data, isFetching: false })
        })
        .finally(() => {
          isLoading.set(false)
        })
    }

    const fetchData = async (paginationVal: Pagination): Promise<null | T> => {
      isLoading.set(true)
      this.cache.set(key, { data, isFetching: true })

      const result = await fetchMethod(paginationVal)
      if (options.paginated) {
        const res = result as PaginatedResponse<T>
        const itemsLength = (res.data as unknown[]).length

        if (res.pages) {
          pagination.set(res.pages)
        } else {
          pagination.set({
            page: 0,
            per_page: 0,
            total: itemsLength,
            end: true,
          })
        }

        return res.data
      } else {
        return result as T
      }
    }

    const resetPagination = () => {
      pagination.set({
        page: 0,
        per_page: options.limit,
        end: false,
      })
    }

    if ((!item || item.isFetching !== true) && options.initialFetch) {
      revalidate()
    }

    const onVisibilityChange = () => this.onVisibilityChange(revalidate)
    const revalidateOnInterval = (ms: number) =>
      this.revalidateOnInterval(revalidate, ms)

    return {
      data,
      error,
      isLoading,
      revalidate,
      next,
      pagination,
      resetPagination,
      onVisibilityChange,
      revalidateOnInterval,
    }
  }

  onVisibilityChange(handler: () => void) {
    const visibilityChange = () => {
      if (document.visibilityState !== 'hidden') handler()
    }

    onMount(() => {
      document.addEventListener('visibilitychange', visibilityChange)
    })

    onDestroy(() => {
      document.removeEventListener('visibilitychange', visibilityChange)
    })
  }

  revalidateOnInterval = (handler: () => void, ms: number) => {
    let interval: ReturnType<typeof setInterval>

    const start = () => (interval = setInterval(handler, ms))

    const stop = () => {
      if (interval) clearInterval(interval)
    }

    return { stop, start }
  }

  isAuthenticated() {
    const { data, ...res } = this.useSWR<boolean>('authenticated', () =>
      this.api.isAuthenticated()
    )

    return { authenticated: data, ...res }
  }

  getSpace() {
    const { data, ...res } = this.useSWR<Space>('space', () =>
      this.api.getSpace()
    )

    return { space: data, ...res }
  }

  getMetadata() {
    const { data, ...res } = this.useSWR<Metadata>('metadata', () =>
      this.api.getMetadata()
    )

    return { metadata: data, ...res }
  }

  getApps(opts?: SWROptions) {
    const { data, ...res } = this.useSWR<App[]>(
      'apps',
      (pagination?: Pagination) => this.api.getApps(pagination),
      {
        paginated: true,
        ...opts,
      }
    )

    return { apps: data, ...res }
  }

  getInstances(opts?: SWROptions) {
    const { data, ...res } = this.useSWR<Instance[]>(
      'instances',
      (pagination?: Pagination) => this.api.getAppInstances(pagination),
      { paginated: true, ...opts }
    )

    return { instances: data, ...res }
  }

  getReleases(opts?: SWROptions) {
    const { data, ...res } = this.useSWR<Release[]>(
      'releases',
      (pagination?: Pagination) => this.api.getReleases(pagination),
      { paginated: true, ...opts }
    )

    return { releases: data, ...res }
  }

  getLatestReleases(opts?: SWROptions) {
    const { data, ...res } = this.useSWR<Release[]>(
      'latest_releases',
      (pagination?: Pagination) => this.api.getLatestReleases(pagination),
      { paginated: true, ...opts }
    )

    return { releases: data, ...res }
  }

  getInstallations(opts?: SWROptions) {
    const { data, ...res } = this.useSWR<Installation[]>(
      'installations',
      (pagination?: Pagination) => this.api.getInstallationRequests(pagination),
      { paginated: true, ...opts }
    )

    return { installations: data, ...res }
  }

  getTokens(opts?: SWROptions) {
    const { data, ...res } = this.useSWR<Token[]>(
      'tokens',
      (pagination?: Pagination) => this.api.getTokens(pagination),
      { paginated: true, ...opts }
    )

    return { tokens: data, ...res }
  }

  /**
   * "Piggyback" of existing request for larger data set and filter the relevant data
   */
  piggyBack<T>(
    key: string,
    filterFunc: (values: T) => boolean,
    single = false
  ) {
    const item = this.cache.get(key)
    if (item && item.isFetching) {
      const data = item.data as Writable<T[]>

      if (single) {
        return derived(data, values => {
          return values?.find(filterFunc)
        })
      }

      return derived(
        data,
        values => {
          return values?.filter(filterFunc)
        },
        []
      )
    }

    return undefined
  }

  getAppByID(id: string) {
    const { data, ...res } = this.useSWR<App>(`app_${id}`, () =>
      this.api.getAppByID(id)
    )
    return { app: data, ...res }
  }

  getPromotionByID(id: string) {
    const { data, ...res } = this.useSWR<Promotion>(`promotion_${id}`, () =>
      this.api.getPromotionByID(id)
    )

    return { promotion: data, ...res }
  }

  getReleaseByID(id: string) {
    const { data, ...res } = this.useSWR<Release>(`release_${id}`, () =>
      this.api.getReleaseByID(id)
    )

    return { release: data, ...res }
  }

  getInstanceByID(id: string) {
    const { data, ...res } = this.useSWR<Instance>(`instance_${id}`, () =>
      this.api.getInstanceByID(id)
    )

    return { instance: data, ...res }
  }

  getInstallationByID(id: string) {
    const { data, ...res } = this.useSWR<Installation>(
      `installation_${id}`,
      () => this.api.getInstallationByID(id)
    )

    return { installation: data, ...res }
  }

  getLatestRevisionByAppID(id: string) {
    const { data, ...res } = this.useSWR<Revision>(
      `latest_revision_app_${id}`,
      () => this.api.getLatestRevisionByAppID(id)
    )

    return { revision: data, ...res }
  }

  getRevisionsByAppID(id: string, opts?: SWROptions) {
    const { data, ...res } = this.useSWR<Revision[]>(
      `revisions_app_${id}`,
      (pagination?: Pagination) => this.api.getRevisionsByAppID(id, pagination),
      { paginated: true, ...opts }
    )

    return { revisions: data, ...res }
  }

  getReleasesByAppID(id: string, opts?: SWROptions) {
    const { data, ...res } = this.useSWR<Release[]>(
      `releases_app_${id}`,
      (pagination?: Pagination) => this.api.getReleasesByAppID(id, pagination),
      { paginated: true, ...opts }
    )

    return { releases: data, ...res }
  }

  getBuildsByAppID(id: string, opts?: SWROptions) {
    const { data, ...res } = this.useSWR<Build[]>(
      `builds_app_${id}`,
      (pagination?: Pagination) => this.api.getBuilds(id, pagination),
      { paginated: true, ...opts }
    )

    return { builds: data, ...res }
  }

  getInstancesByAppID(id: string, opts?: SWROptions) {
    // Piggyback of existing request if we are already fetching all instances
    const piggyData = this.piggyBack<Instance>(
      'instances',
      instance => instance.app_id === id
    )

    const { data, ...res } = this.useSWR<Instance[]>(
      `instances_app_${id}`,
      (pagination?: Pagination) => this.api.getInstancesByAppID(id, pagination),
      { initialFetch: piggyData === undefined, paginated: true, ...opts }
    )

    const instances = (piggyData !== undefined
      ? piggyData
      : data) as unknown as Readable<Instance[] | undefined>

    return { instances, ...res }
  }

  getLatestReleaseByAppID(id: string) {
    const { data, ...res } = this.useSWR<Release>(
      `latest_release_app_${id}`,
      () => this.api.getLatestReleaseByAppID(id)
    )

    return { release: data, ...res }
  }

  getDevInstanceByAppID(id: string) {
    const { data, ...res } = this.useSWR<Instance>(
      `dev_instance_app_${id}`,
      () => this.api.getDevInstanceByAppID(id)
    )

    return { instance: data, ...res }
  }

  getHorizons(opts?: SWROptions) {
    const { data, ...res } = this.useSWR<IHorizon[]>(
      'horizons',
      () => this.api.getHorizons(),
      { paginated: false, ...opts }
    )

    return { horizons: data, ...res }
  }

  getHorizon(id: string, initialFetch?: boolean, opts?: SWROptions) {
    const { data, ...res } = this.useSWR<IHorizon>(
      'canvas',
      () => this.api.getHorizon(id),
      { initialFetch, paginated: true, ...opts }
    )

    return { horizon: data, ...res }
  }

  getHorizonCardPositions(id: string, opts?: SWROptions) {
    const { data, ...res } = this.useSWR<
      Omit<TCard, 'type' | 'local' | 'data'>[]
    >('canvas_card_positions', () => this.api.getHorizonCardPositions(id), {
      paginated: false,
      ...opts,
    })

    return { cards: data, ...res }
  }

  getCanvasItemByID(id: string) {
    const { data, ...res } = this.useSWR<CanvasItem>(`canvas_item_${id}`, () =>
      this.api.getLegacyCanvasItemByID(id)
    )

    return { item: data, ...res }
  }

  getCanvasItemByItemIDAndType(item_id: string, item_type: string) {
    const { data, ...res } = this.useSWR<CanvasItem>(
      `canvas_item_${item_id}_${item_type}`,
      () => this.api.getLegacyCanvasItemByItemIDAndType(item_id, item_type)
    )

    return { item: data, ...res }
  }

  getDiscoveryReleases(
    sort: DiscoverySort = 'published',
    filter?: DiscoveryFilter,
    since?: number,
    opts?: SWROptions
  ) {
    const { data, ...res } = this.useSWR<Release[]>(
      `discovery_releases_${sort}_${filter || ''}`,
      (pagination?: Pagination) =>
        this.api.getDiscoveryReleases(sort, filter, since, pagination),
      { paginated: true, ...opts }
    )

    return { releases: data, ...res }
  }

  getDiscoveryReleasesByAppID(appId: string, opts?: SWROptions) {
    const { data, ...res } = this.useSWR<Release[]>(
      `discovery_releases_app_${appId}`,
      (pagination?: Pagination) =>
        this.api.getDiscoveryReleasesByAppID(appId, pagination),
      { paginated: true, ...opts }
    )

    return { releases: data, ...res }
  }

  searchDiscoveryReleases(query: string, opts?: SWROptions) {
    const { data, ...res } = this.useSWR<Release[]>(
      `discovery_releases_search_${query}`,
      (pagination?: Pagination) =>
        this.api.searchDiscoveryReleases(query, pagination),
      { paginated: true, ...opts }
    )

    return { releases: data, ...res }
  }

  getDiscoveryReleaseByID(id: string) {
    const { data, ...res } = this.useSWR<Release>(
      `discovery_release_${id}`,
      () => this.api.getDiscoveryReleaseByID(id)
    )

    return { release: data, ...res }
  }

  getDiscoveryReleaseByScopedAlias(scope: string, alias: string) {
    const { data, ...res } = this.useSWR<Release>(
      `discovery_release_${scope}_${alias}`,
      () => this.api.getDiscoveryReleaseByScopedAlias(scope, alias)
    )

    return { release: data, ...res }
  }

  getDiscoveryReleaseByScopedAliasAndVersion(
    scope: string,
    alias: string,
    version: string
  ) {
    const { data, ...res } = this.useSWR<Release>(
      `discovery_release_${scope}_${alias}_${version}`,
      () =>
        this.api.getDiscoveryReleaseByScopedAliasAndVersion(
          scope,
          alias,
          version
        )
    )

    return { release: data, ...res }
  }

  getProjectKeys(appId: string, opts?: SWROptions) {
    const { data, ...res } = this.useSWR<Key[]>(
      `project_keys_${appId}`,
      (pagination?: Pagination) => this.api.getProjectKeys(appId, pagination),
      { paginated: true, ...opts }
    )

    return { keys: data, ...res }
  }

  getProjectKeyByName(appId: string, name: string) {
    const { data, ...res } = this.useSWR<Key>(
      `project_key_${appId}_${name}`,
      () => this.api.getProjectKeyByName(appId, name)
    )

    return { key: data, ...res }
  }

  getInstanceActions(instanceId: string, opts?: SWROptions) {
    const { data, ...res } = this.useSWR<Action[]>(
      `instance_${instanceId}_actions`,
      (pagination?: Pagination) =>
        this.api.getInstanceActions(instanceId, pagination),
      { paginated: true, ...opts }
    )

    return { actions: data, ...res }
  }

  getInstanceActionByID(instanceId: string, actionId: string) {
    const { data, ...res } = this.useSWR<Action>(
      `instace_${instanceId}_action_${actionId}`,
      () => this.api.getInstanceActionByID(instanceId, actionId)
    )

    return { action: data, ...res }
  }

  getInstanceDomains(instanceId: string, opts?: SWROptions) {
    const { data, ...res } = this.useSWR<Domain[]>(
      `instance_${instanceId}_domains`,
      (pagination?: Pagination) =>
        this.api.getInstanceDomains(instanceId, pagination),
      { paginated: true, ...opts }
    )

    return { domains: data, ...res }
  }

  getInstanceDomainByID(instanceId: string, domainId: string) {
    const { data, ...res } = this.useSWR<Domain>(
      `instance_${instanceId}_domain_${domainId}`,
      () => this.api.getInstanceDomainByID(instanceId, domainId)
    )

    return { domain: data, ...res }
  }

  getCollections(opts?: SWROptions) {
    const { data, ...res } = this.useSWR<Collection[]>(
      `collection`,
      (pagination?: Pagination) => this.api.getCollections(pagination),
      { paginated: true, ...opts }
    )

    return { collections: data, ...res }
  }

  getLegacyCollections(opts?: SWROptions) {
    const { data, ...res } = this.useSWR<Collection[]>(
      `collections_legacy`,
      (pagination?: Pagination) => this.api.getLegacyCollections(pagination),
      { paginated: true, ...opts }
    )

    return { legacyCollections: data, ...res }
  }

  getCollectionByID(id: string) {
    const { data, ...res } = this.useSWR<Collection>(`collection_${id}`, () =>
      this.api.getCollectionByID(id)
    )

    return { collection: data, ...res }
  }

  getBasesByCollectionID(id: string, opts?: SWROptions) {
    const { data, ...res } = this.useSWR<Base[]>(
      `collection_${id}_bases`,
      (pagination?: Pagination) =>
        this.api.getBasesByCollectionID(id, pagination),
      { paginated: true, ...opts }
    )

    return { bases: data, ...res }
  }

  getDrivesByCollectionID(id: string, opts?: SWROptions) {
    const { data, ...res } = this.useSWR<Drive[]>(
      `collection_${id}_drives`,
      (pagination?: Pagination) =>
        this.api.getDrivesByCollectionID(id, pagination),
      { paginated: true, ...opts }
    )

    return { drives: data, ...res }
  }

  getDataKeysByCollectionID(id: string, opts?: SWROptions) {
    const { data, ...res } = this.useSWR<Key[]>(
      `collection_${id}_keys`,
      (pagination?: Pagination) =>
        this.api.getDataKeysByCollectionID(id, pagination),
      { paginated: true, ...opts }
    )

    return { keys: data, ...res }
  }

  getExportsByCollectionID(id: string, opts?: SWROptions) {
    const { data, ...res } = this.useSWR<Export[]>(
      `collection_${id}_exports`,
      (pagination?: Pagination) =>
        this.api.getExportsByCollectionID(id, pagination),
      { paginated: true, ...opts }
    )

    return { exports: data, ...res }
  }

  getExportsByInstanceId(id: string, opts?: SWROptions) {
    const { data, ...res } = this.useSWR<Export[]>(
      `instance_${id}_exports`,
      (pagination?: Pagination) =>
        this.api.getExportsByInstanceID(id, pagination),
      { paginated: true, ...opts }
    )

    return { exports: data, ...res }
  }

  getLegacyDomains() {
    const { data, ...res } = this.useSWR<LegacyDomain[]>(`legacy_domains`, () =>
      this.api.getLegacyDomains()
    )

    return { domains: data, ...res }
  }

  getInvocations(opts?: SWROptions) {
    const { data, ...res } = this.useSWR<Invocation[]>(
      `invocations`,
      (pagination?: Pagination) => this.api.getInvocations(pagination),
      { paginated: true, ...opts }
    )

    return { invocations: data, ...res }
  }

  getInvocationByID(id: string) {
    const { data, ...res } = this.useSWR<Invocation>(`invocation_${id}`, () =>
      this.api.getInvocationByID(id)
    )

    return { invocation: data, ...res }
  }

  getInstanceEmbed(alias: string) {
    const { data, ...res } = this.useSWR<InstanceEmbed>(
      `instance_embed_${alias}`,
      () => this.api.generateInstanceEmbed(alias)
    )

    return { embed: data, ...res }
  }
}

export const provideStore = Store.provide
export const useStore = Store.use
