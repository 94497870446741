/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { get } from 'svelte/store'

import type { Action } from '@deta/teletype'
import type { App, Instance } from '@deta/types'

import { useSearchProjectsAction, useProjectsAction } from './actions/projects'
import {
  useSearchAppInstancesAction,
  useAppInstancesAction,
} from './actions/instances'
import { useDocsSearchAction } from './search'
import { alphaWarningAction, onboardingAction } from './onboarding'
import { useSearchCollectionsAction } from './actions/collections'
import { useSearchDiscoveryAction } from './actions/discovery'
import { useUpdateInstancesAction } from './actions/updates'
import {
  contactSupportAction,
  navigateToBuilderAction,
  navigateToCanvasAction,
  navigateToCollectionsAction,
  navigateToDiscoveryAction,
  navigateToDocsAction,
  navigateToNewProjectAction,
  navigateToGlobalSearchAction,
  navigateToTeletypePrototypeAction,
  openBlogAction,
  openChangelogAction,
  openDiscordAction,
  openJobsPageAction,
  useOpenDevInstanceAction,
  useOpenDevServerAction,
} from './actions/navigation'
import { changeThemeAction } from './actions/theme'
import {
  devModeSettingsAction,
  settingsAction,
  useLogoutAction,
} from './actions/settings'
import { useSystemAppsActions } from './actions/systemApps'
import {
  useActionInvocationsAction,
  useAppActionsAction,
} from './actions/appActions'
import type { CommonActionContext } from './utils'
import { getSearchParams } from '@/utils/mount'

export const createCommonSearchActions = (context: CommonActionContext) => {
  // const searchProjectsAction = useSearchProjectsAction(context)
  // const searchInstancesActions = useSearchAppInstancesAction(context)
  // const searchCollections = useSearchCollectionsAction(context)
  // const searchDiscovery = useSearchDiscoveryAction(context)
  const appActions = useAppActionsAction(context)
  const invocations = useActionInvocationsAction(context)

  // const base = {
  //   searchProjectsAction,
  //   searchInstancesActions,
  //   searchCollections,
  //   searchDiscovery,
  // }

  if (get(context.metadata.isInsiderMode)) {
    return {
      appActions,
      invocations,
    }
  } else {
    return {}
  }
}

export const useDefaultActions = (context: CommonActionContext): Action[] => {
  // const isDevModeValue = get(context.metadata.isDevMode) || false

  // const docsSearchAction = useDocsSearchAction()
  const logoutAction = useLogoutAction(context.api)
  const systemApps = useSystemAppsActions(context)

  return [
    // ...(isDevModeValue === true
    //   ? [/*navigateToDocsAction,*/ docsSearchAction]
    //   : []),
    // navigateToTeletypePrototypeAction,
    ...systemApps,
    // contactSupportAction,
    // openChangelogAction,
    // openBlogAction,
    // openDiscordAction,
    // openJobsPageAction,
    onboardingAction,
    alphaWarningAction,
    changeThemeAction,
    settingsAction,
    // isDevModeValue === false && devModeSettingsAction,
    logoutAction,
  ].filter(v => v)
}

export const useHomeActions = (
  context: CommonActionContext,
  apps: App[],
  instances: Instance[]
): Action[] => {
  const searchActions = createCommonSearchActions(context)

  const instancesActions = useAppInstancesAction(context, instances)
  const projectsActions = useProjectsAction(context, apps)
  const updatesAction = useUpdateInstancesAction(context)

  const isDevMode = get(context.metadata.isDevMode)
  const { devServer } = getSearchParams<{ devServer: string }>()

  return [
    ...(devServer ? [useOpenDevServerAction(devServer)] : []),
    ...Object.values(searchActions),
    ...instancesActions,
    ...(isDevMode ? [/*navigateToBuilderAction,*/ ...projectsActions] : []),
    updatesAction,
    // navigateToDiscoveryAction,
    // navigateToCollectionsAction,
    // navigateToGlobalSearchAction,
  ]
}

export const useBuilderActions = (
  context: CommonActionContext,
  apps: App[],
  instances: Instance[],
  devInstance?: Instance | null
): Action[] => {
  const searchActions = createCommonSearchActions(context)
  const instancesActions = useAppInstancesAction(context, instances)
  const projectsActions = useProjectsAction(context, apps)
  const updatesAction = useUpdateInstancesAction(context)
  const { devServer } = getSearchParams<{ devServer: string }>()

  return [
    ...(devServer ? [useOpenDevServerAction(devServer)] : []),
    devInstance && useOpenDevInstanceAction(devInstance?.url),
    navigateToNewProjectAction,
    ...Object.values(searchActions),
    updatesAction,
    ...instancesActions,
    ...projectsActions,
    navigateToCanvasAction,
    // navigateToDiscoveryAction,
    navigateToCollectionsAction,
  ].filter(v => v)
}

export const useCollectionsActions = (
  context: CommonActionContext
): Action[] => {
  const searchActions = createCommonSearchActions(context)

  const updatesAction = useUpdateInstancesAction(context)
  const { devServer } = getSearchParams<{ devServer: string }>()

  return [
    ...(devServer ? [useOpenDevServerAction(devServer)] : []),
    ...Object.values(searchActions),
    updatesAction,
    navigateToCanvasAction,
    navigateToDiscoveryAction,
    navigateToBuilderAction,
  ].filter(v => v)
}

export const useDiscoveryActions = (context: CommonActionContext): Action[] => {
  const searchActions = createCommonSearchActions(context)
  const updatesAction = useUpdateInstancesAction(context)
  const isDevMode = get(context.metadata.isDevMode)
  const { devServer } = getSearchParams<{ devServer: string }>()

  return [
    ...(devServer ? [useOpenDevServerAction(devServer)] : []),
    ...Object.values(searchActions),
    updatesAction,
    navigateToCanvasAction,
    isDevMode && navigateToBuilderAction,
    navigateToCollectionsAction,
  ].filter(v => v)
}

export * from './actions/projects'
export * from './actions/instances'
